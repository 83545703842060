import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  teamMembers: [],
  isFetching: false,
};

const slice = createSlice({
  name: 'teamMembers',
  initialState,
  reducers: {
    setTeamMembers: (state, { payload }) => ({
      ...state,
      teamMembers: payload,
    }),
    setFetchingTeamMembers: (state, { payload }) => ({
      ...state,
      isFetching: payload,
    }),
  },
});

export const teamMembersSelector = ({ teamMembers: { teamMembers } }) => teamMembers;
export const isFetchingTeamMembersSelector = ({ teamMembers: { isFetching } }) => isFetching;

export const {
  setTeamMembers,
  setFetchingTeamMembers,
} = slice.actions;

export default slice.reducer;
