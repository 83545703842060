import React from 'react';
import {
  withStyles,
  Dialog,
  useMediaQuery,
  IconButton,
  DialogTitle,
  useTheme,
  Container,
  Typography,
  Grid,
  Button,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { applyFormOpenSelector, setApplyFormOpen } from 'slices/careers';
import { Formik, Form, Field } from 'formik';
import { Close } from '@material-ui/icons';
import { TextField } from 'formik-material-ui';
import Recaptcha from 'App/components/Recaptcha';
import FileInput from 'App/components/FileInput';
import { serializeFile } from 'utils';
import { submitJobApplication } from 'App/actions';

const ApplyFormDialog = withStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  container: {
    background: theme.palette.grey[100],
    borderRadius: 10,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontWeight: theme.typography.fontWeightLight,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(6),
    },
  },
  field: {
    '& input,textarea': {
      color: theme.palette.primary.main,
    },
    '& .MuiInput-underline:before': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderColor: theme.palette.primary.main,
    },
  },
  submit: {
    marginTop: theme.spacing(1),
  },
}))(({ classes }) => {
  const dispatch = useDispatch();
  const isApplyFormOpen = useSelector(applyFormOpenSelector);
  const handleClose = () => dispatch(setApplyFormOpen(null));
  const isMobile = useMediaQuery((mqTheme) => mqTheme.breakpoints.down('xs'));
  const theme = useTheme();

  return (
    <Dialog
      onClose={handleClose}
      open={!!isApplyFormOpen}
      fullWidth
      maxWidth="xs"
      style={{ zIndex: theme.zIndex.modal + 1 }}
      fullScreen={isMobile}
    >
      <DialogTitle>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          address: '',
          contact: '',
          email: '',
          location: '',
          company: '',
          file: '',
          coverLetter: '',
          recaptcha: '',
        }}
        onSubmit={({ recaptcha, ...values }, { resetForm }) => serializeFile(values.file)
          .then((fileBase64) => dispatch(submitJobApplication({
            ...values,
            file: fileBase64,
            jobId: isApplyFormOpen,
          })))
          .then(() => resetForm())
          .then(handleClose)}
      >
        {({ isSubmitting }) => (
          <Container>
            <Typography align="center" className={classes.title}>Apply for Job</Typography>
            <Form className={classes.container}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    className={classes.field}
                    name="firstName"
                    label="First Name*"
                    fullWidth
                    validate={(value) => !value && 'Required'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    className={classes.field}
                    name="lastName"
                    label="Last Name*"
                    fullWidth
                    validate={(value) => !value && 'Required'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    className={classes.field}
                    name="address"
                    label="Address*"
                    fullWidth
                    validate={(value) => !value && 'Required'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    className={classes.field}
                    name="contact"
                    label="Contact Number*"
                    fullWidth
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    validate={(value) => (!value && 'Required') || (!value.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) && 'Invalid Contact Number')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    className={classes.field}
                    name="email"
                    label="Email*"
                    fullWidth
                    validate={(value) => (!value && 'Required') || (!value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/) && 'Invalid Email')}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    className={classes.field}
                    name="location"
                    label="Current Location*"
                    fullWidth
                    validate={(value) => !value && 'Required'}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    component={TextField}
                    className={classes.field}
                    name="company"
                    label="Current Company*"
                    fullWidth
                    validate={(value) => !value && 'Required'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="file"
                    component={FileInput}
                    label="Attach Resume*"
                    validate={(value) => (!value && 'Required') || (value.size > 2000000 && 'File should be less than 2 MB')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    className={classes.field}
                    name="coverLetter"
                    label="Cover Letter*"
                    fullWidth
                    multiline
                    validate={(value) => !value && 'Required'}
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="recaptcha"
                    validate={(value) => !value && 'Required'}
                    component={Recaptcha}
                  />
                </Grid>
                <Grid item xs={12}>
                  <center>
                    <Button
                      className={classes.submit}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      disableElevation
                      color="primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Submitting' : 'Submit'}
                    </Button>
                  </center>
                </Grid>
              </Grid>
            </Form>
          </Container>
        )}
      </Formik>
    </Dialog>
  );
});

export default ApplyFormDialog;
