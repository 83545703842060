import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const slice = createSlice({
  name: 'headerMenu',
  initialState,
  reducers: {
    setHeaderMenuOpen: (state, { payload }) => payload,
  },
});

export const headerMenuOpenSelector = ({ headerMenu }) => headerMenu;

export const {
  setHeaderMenuOpen,
} = slice.actions;

export default slice.reducer;
