import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const slice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    toggleContact: (state) => !state,
  },
});

export const contactSelector = ({ contact }) => contact;

export const {
  toggleContact,
} = slice.actions;

export default slice.reducer;
