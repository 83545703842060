import React from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  withStyles,
  Hidden,
} from '@material-ui/core';
import Breadcrumbs from 'App/components/Breadcrumbs';
import MetaTags from 'react-meta-tags';
import { ReactComponent as ConnectIcon } from './icons/si-glyph_connect-2.svg';

const BREADCRUMBS = [{
  label: 'Home',
  to: '/',
}, {
  label: 'Corporate',
}];

const Corporate = withStyles((theme) => ({
  bgImg: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.secondary.light}, ${theme.palette.primary.light})`,
    paddingBottom: theme.spacing(25),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(10),
    },
    backgroundSize: '200% 200%',
    animation: 'gradient 8s ease infinite',
  },
  title: {
    textTransform: 'uppercase',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontSize: 60,
    fontWeight: 100,
    letterSpacing: 2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 40,
    },
  },
  description: {
    color: theme.palette.primary.contrastText,
    fontSize: 28,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
    marginTop: theme.spacing(3),
    maxWidth: 600,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.3,
  },
  particleBg: {
    position: 'absolute',
    width: 440,
    height: 410,
    top: theme.spacing(-8),
    right: 0,
    backgroundImage: `url("${process.env.PUBLIC_URL}/images/shards.png")`,
    backgroundSize: 'cover',
    zIndex: -1,
    [theme.breakpoints.down('xs')]: {
      width: 180,
      height: 167,
    },
  },
  header: {
    fontWeight: 100,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontSize: 72,
    color: theme.palette.primary.light,
    marginRight: 120,
    [theme.breakpoints.down('sm')]: {
      fontSize: 48,
      marginRight: 0,
    },
  },
  subheader: {
    color: theme.palette.text.secondary,
    fontSize: 24,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.2,
    maxWidth: 480,
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  boxContainer: {
    textAlign: 'right',
    overflow: 'hidden',
    width: theme.spacing(35),
    [theme.breakpoints.down('sm')]: {
      width: 200,
    },
  },
  box: {
    height: theme.spacing(0.4),
    width: theme.spacing(9),
    backgroundImage: `linear-gradient(to right, #47a5e3, ${theme.palette.primary.light})`,
    borderRadius: 2,
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      width: 52,
    },
  },
  corporateTitle: {
    color: theme.palette.primary.light,
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      marginRight: 120,
    },
  },
  corporateContent: {
    color: theme.palette.text.secondary,
    lineHeight: 1.4,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  corporateImg: {
    backgroundPosition: 'right',
    backgroundSize: '315px auto',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      right: 0,
      position: 'absolute',
      width: 100,
      height: 150,
    },
  },
  partnerContainer: {
    position: 'relative',
    border: `1px solid ${theme.palette.grey[300]}`,
    height: theme.spacing(18),
  },
  partnerImg: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
  },
  connectContainer: {
    position: 'relative',
    backgroundImage: `linear-gradient(to right, ${theme.palette.secondary.light}, ${theme.palette.primary.light})`,
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  connectIcon: {
    position: 'absolute',
    top: theme.spacing(-10),
    left: theme.spacing(-3),
  },
  connectHeader: {
    fontSize: 36,
    fontWeight: theme.typography.fontWeightLight,
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
    },
  },
  connectSubheader: {
    fontSize: 24,
    lineHeight: 1.2,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  connectContent: {
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.4,
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
}))(({ classes }) => (
  <>
    <MetaTags>
      <meta name="description" content="INVAS believes it is the shared value and relationships that helps any business make a global impact." />
    </MetaTags>
    <div className={classes.bgImg}>
      <Container>
        <Breadcrumbs path={BREADCRUMBS} contrast />
        <Typography className={classes.title}>Corporate</Typography>
        <Typography className={classes.description}>
          INVAS believes it is the shared value and relationships
          that helps any business make a global impact.
          We pride in our relations with a commited team of
          partners and we always look forward to work with
          technology leaders who share our passion for technology
          and are committed to excellence as we are.
        </Typography>
      </Container>
    </div>
    <Box position="relative">
      <Box className={classes.particleBg} />
    </Box>
    <Container maxWidth="md">
      <Grid container>
        <Grid item md="auto" xs={12}>
          <Box className={classes.boxContainer}>
            <Box className={classes.box} data-aos="fade-left" />
          </Box>
          <Typography className={classes.header}>
            Why Us?
          </Typography>
        </Grid>
        <Grid item md xs={12}>
          <Typography className={classes.subheader}>
            With our technology for people first approach,
            we always look forward to partner with technology
            leaders across globe, and build a success story together.
          </Typography>
        </Grid>
      </Grid>
      <Box pl={2} mt={8}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.corporateTitle}>
                  Tech Savvy
                </Typography>
              </Grid>
              <Grid item>
                <Grid container justify="space-between">
                  <Grid item xs={8} md>
                    <Typography className={classes.corporateContent}>
                      Our team is driven to work with the latest technology
                      in the telecom industry. Be it 5G, IP Security or optical
                      testing, our team has time and again proven itself by
                      not only adapting to changes quickly, but also be
                      a key player in driving it further.
                    </Typography>
                  </Grid>
                  <Hidden mdUp>
                    <Grid item xs>
                      <Box
                        className={classes.corporateImg}
                        style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/images/corporate-1.png")` }}
                      />
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item xs>
              <Box
                className={classes.corporateImg}
                style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/images/corporate-1.png")` }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Box>
      <Box pl={2} mt={8}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.corporateTitle}>
                  Excellent After Sales Relations
                </Typography>
              </Grid>
              <Grid item>
                <Grid container justify="space-between">
                  <Grid item xs={8} md>
                    <Typography className={classes.corporateContent}>
                      With long term immersion in the industry, INVAS has
                      developed strong client relationships with its people
                      centric support system. Our dedicated professional
                      services team ensures high touch support and aims to
                      develop an understanding of customer needs. Our
                      presence in all major cities across India has enabled
                      us to be present locally for our customers, wherever
                      they are.
                    </Typography>
                  </Grid>
                  <Hidden mdUp>
                    <Grid item xs>
                      <Box
                        className={classes.corporateImg}
                        style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/images/corporate-2.png")` }}
                      />
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item xs>
              <Box
                className={classes.corporateImg}
                style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/images/corporate-2.png")` }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Box>
      <Box pl={2} mt={8}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.corporateTitle}>
                  PAN India Coverage
                </Typography>
              </Grid>
              <Grid item>
                <Grid container justify="space-between">
                  <Grid item xs={8} md>
                    <Typography className={classes.corporateContent}>
                      Our extensive  professional services teams, sales and
                      support infrastructure spans across India to ensure we
                      are always present locally for our customers whenever
                      they need assistance. We aim to provide a consistent
                      service to our customers regardless of their location.
                    </Typography>
                  </Grid>
                  <Hidden mdUp>
                    <Grid item xs>
                      <Box
                        className={classes.corporateImg}
                        style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/images/corporate-3.png")` }}
                      />
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item xs>
              <Box
                className={classes.corporateImg}
                style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/images/corporate-3.png")` }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Box>
      <Box pl={2} mt={8}>
        <Grid container spacing={2}>
          <Grid item xs>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography className={classes.corporateTitle}>
                  15 Year Experience
                </Typography>
              </Grid>
              <Grid item>
                <Grid container justify="space-between">
                  <Grid item xs={8} md>
                    <Typography className={classes.corporateContent}>
                      With more than a decade of experience in the telecom
                      industry, INVAS has always stayed ahead of technology
                      curve, responded to market change and adapted quickly
                      to them, while remaining true to it’s core values.
                      That is what makes our long term clients and partners
                      depend on us for the most strategic needs.
                    </Typography>
                  </Grid>
                  <Hidden mdUp>
                    <Grid item xs>
                      <Box
                        className={classes.corporateImg}
                        style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/images/corporate-4.png")` }}
                      />
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item xs>
              <Box
                className={classes.corporateImg}
                style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/images/corporate-4.png")` }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Box>
      <Box mt={10} mb={4}>
        <Typography className={classes.corporateContent}>
          INVAS has partnered with some of the leading companines to
          leverage shared expertise that extends our support
          capabilities for our clients.
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <div className={classes.partnerContainer}>
            <img className={classes.partnerImg} src={`${process.env.PUBLIC_URL}/images/Optronix.png`} alt="Optronix" />
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.partnerContainer}>
            <img className={classes.partnerImg} src={`${process.env.PUBLIC_URL}/images/RC.png`} alt="RC" />
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={classes.partnerContainer}>
            <img className={classes.partnerImg} src={`${process.env.PUBLIC_URL}/images/Pelorus.png`} alt="Pelorus" />
          </div>
        </Grid>
      </Grid>
      <Box mt={10} mb={5}>
        <Container maxWidth="xs" className={classes.connectContainer}>
          <ConnectIcon className={classes.connectIcon} />
          <Box pt={8} />
          <Typography className={classes.connectHeader}>
            Connect with us
          </Typography>
          <br />
          <Typography className={classes.connectSubheader}>
            For corporate relations and enquiries, contact our head office at:
          </Typography>
          <br />
          <br />
          <Typography className={classes.connectContent}>
            INVAS Technologies Pvt. Ltd.
            <br />
            Plot no. 108, 4th Floor, Sector-44,
            <br />
            Gurugram: 122002, Haryana, INDIA
            <br />
            <br />
            Phone: +91-124-4200984/985
            <br />
            Email: directors@invas.in
            <br />
            Fax: +91-124-4078258
          </Typography>
          <br />
        </Container>
      </Box>
    </Container>
  </>
));

export default Corporate;
