import React from 'react';
import {
  Grid,
  Typography,
  Box,
  withStyles,
} from '@material-ui/core';
import Link from 'App/components/Link';

const SectionTitle = withStyles((theme) => ({
  title: {
    textTransform: 'uppercase',
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    color: ({ color }) => color || theme.palette.secondary.dark,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
  },
  titleBox: {
    background: ({ secondary }) => (
      secondary ? theme.palette.secondary.main : theme.palette.primary.main
    ),
    width: theme.spacing(1),
    height: theme.spacing(4),
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  link: {
    marginLeft: theme.spacing(3),
    fontStyle: 'italic',
    color: ({ color }) => color || theme.palette.secondary.dark,
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
    },
  },
}))(({
  classes,
  title,
  subtitle,
  to,
  href,
}) => (
  <>
    <Grid container alignItems="center" spacing={1}>
      <Grid item className={classes.overflowHidden}>
        <Box data-aos="fade-up" data-aos-anchor-placement="top-bottom" className={classes.titleBox} />
      </Grid>
      <Grid item className={classes.overflowHidden}>
        <Typography data-aos-delay="500" data-aos="fade-right" data-aos-anchor-placement="top-bottom" className={classes.title}>{title}</Typography>
      </Grid>
    </Grid>
    <div data-aos-delay="800" data-aos-anchor-placement="top-bottom" data-aos="fade-in">
      <Link
        to={to}
        href={href}
        className={classes.link}
      >
        {subtitle}
      </Link>
    </div>
  </>
));

export default SectionTitle;
