import React, { useState } from 'react';
import { arrayOf, string } from 'prop-types';
import { Grid, IconButton, withStyles } from '@material-ui/core';
import { NavigateNext, NavigateBefore } from '@material-ui/icons';
import SwipeableViews from 'react-swipeable-views';

const ImageCarousel = withStyles((theme) => ({
  imgContainer: {
    height: '100%',
  },
  img: {
    maxWidth: '100%',
  },
  imgArrowContainer: {
    minWidth: theme.spacing(6),
  },
}))(({ imageArr, altArr, classes }) => {
  const [index, setIndex] = useState(0);

  const handleNext = () => {
    if (index < imageArr.length - 1) setIndex(index + 1);
  };
  const handleBefore = () => {
    if (index > 0) setIndex(index - 1);
  };

  return (
    <Grid container alignItems="center" justify="center" wrap="nowrap">
      <Grid item className={classes.imgArrowContainer}>
        {index > 0 && (
          <IconButton color="primary" onClick={handleBefore}><NavigateBefore /></IconButton>
        )}
      </Grid>
      <Grid item>
        <SwipeableViews index={index} onChangeIndex={setIndex}>
          {imageArr.map((src, i) => (
            <Grid
              key={src}
              container
              className={classes.imgContainer}
              alignItems="center"
              justify="center"
            >
              <Grid item>
                <img className={classes.img} src={src} alt={altArr && altArr.length ? altArr[i] : 'product'} />
              </Grid>
            </Grid>
          ))}
        </SwipeableViews>
      </Grid>
      <Grid item className={classes.imgArrowContainer}>
        {index < imageArr.length - 1 && (
          <IconButton color="primary" onClick={handleNext}><NavigateNext /></IconButton>
        )}
      </Grid>
    </Grid>
  );
});

ImageCarousel.propTypes = {
  imageArr: arrayOf(string).isRequired,
  altArr: arrayOf(string),
};

export default ImageCarousel;
