import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  CardMedia,
  GridList,
  GridListTile,
  GridListTileBar,
  CircularProgress,
  useMediaQuery,
} from '@material-ui/core';
import { getYtIdFromUrl } from 'utils';
import { useDispatch } from 'react-redux';
import { getFeaturedVideos } from 'App/actions';

const VideoCard = withStyles((theme) => ({
  media: {
    height: 200,
    [theme.breakpoints.up('sm')]: {
      height: 400,
    },
  },
  moreMedia: {
    height: 120,
    [theme.breakpoints.up('sm')]: {
      height: 400,
      paddingLeft: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'nowrap',
      transform: 'translateZ(0)',
      paddingTop: theme.spacing(1),
    },
    '& .MuiGridListTile-root': {
      cursor: 'pointer',
    },
  },
  container: {
    borderRadius: 0,
    background: theme.palette.primary.main,
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
  contentContainer: {
    background: 'transparent',
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    [theme.breakpoints.up('up')]: {
      fontSize: 28,
    },
  },
  description: {
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.common.white,
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
}))(({ classes }) => {
  const dispatch = useDispatch();
  const [currentVideo, setCurrentVideo] = useState();
  const [videos, setVideos] = useState([]);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  useEffect(() => {
    const p1 = dispatch(getFeaturedVideos());
    const p2 = new Promise((resolve) => window.gapi.load('client', () => {
      window.gapi.client.setApiKey(process.env.REACT_APP_GAPI_KEY);
      resolve(window.gapi.client.load('https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest'));
    }));

    Promise.all([p1, p2])
      .then(([videoList]) => window.gapi.client.youtube.videos.list({
        part: ['snippet'],
        id: videoList.map(({ url }) => url).map(getYtIdFromUrl),
      }).then(({ result: { items } }) => {
        setVideos(items);
        setCurrentVideo(items[0].id);
      }));
  }, [dispatch]);

  useEffect(() => {
    setVideos((v) => (v.length ? [
      ...v.filter(({ id }) => id !== currentVideo),
      v.find(({ id }) => id === currentVideo),
    ] : v));
  }, [currentVideo]);

  if (!videos || !videos.length) return <center><CircularProgress /></center>;

  return (
    <Card className={classes.container}>
      <Grid container>
        <Grid item xs={12} sm={9}>
          <CardMedia
            className={classes.media}
            title="Featured Video"
            component="iframe"
            src={`https://www.youtube.com/embed/${currentVideo || videos[0].id}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <GridList
            cellHeight={isMobile ? 108 : 180}
            cols={isMobile ? 1.5 : 1}
            className={classes.moreMedia}
          >
            {videos.map(({
              id,
              snippet: {
                title,
                channelTitle,
                thumbnails: { default: { url: img } },
              },
            }) => (
              <GridListTile
                key={id}
                onClick={() => setCurrentVideo(id)}
              >
                <img src={img} alt={title} />
                <GridListTileBar
                  title={title}
                  subtitle={channelTitle}
                />
              </GridListTile>
            ))}
          </GridList>
        </Grid>
      </Grid>
    </Card>
  );
});

export default VideoCard;
