import React from 'react';
import {
  Breadcrumbs as MuiBreadcrumbs,
  withStyles,
} from '@material-ui/core';
import Link from 'App/components/Link';

const Breadcrumbs = withStyles((theme) => ({
  breadcrumb: {
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    color: ({ contrast }) => (contrast ? theme.palette.common.white : theme.palette.text.secondary),
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
}))(({ path, classes }) => (
  <MuiBreadcrumbs separator=">" aria-label="breadcrumb" className={classes.breadcrumb}>
    {path.map(({ label, to }) => (
      <Link
        to={to}
        key={label}
      >
        {label}
      </Link>
    ))}
  </MuiBreadcrumbs>
));

export default Breadcrumbs;
