import { createMuiTheme } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#0662b0',
      dark: '#2d3595',
      light: '#1972FE',
    },
    secondary: {
      main: '#800000',
      dark: '#0a2440',
      light: '#262DAA',
    },
    text: {
      secondary: grey[700],
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: [
      '"Source Sans Pro"',
      '"Roboto"',
      'sans-serif',
    ].join(','),
  },
});

export default theme;
