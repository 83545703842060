import React from 'react';
import {
  Grid,
  Container,
  Typography,
  withStyles,
} from '@material-ui/core';
import Breadcrumbs from 'App/components/Breadcrumbs';
import { CLIENTS } from 'App/constants';
import MetaTags from 'react-meta-tags';

const BREADCRUMBS = [{
  label: 'Home',
  to: '/',
}, {
  label: 'Clients',
}];

const Clients = withStyles((theme) => ({
  bgImg: {
    backgroundImage: `linear-gradient(to top right, #47a5e3, ${theme.palette.secondary.dark})`,
    paddingBottom: theme.spacing(25),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(10),
    },
    marginBottom: theme.spacing(8),
    backgroundSize: '200% 200%',
    animation: 'gradient 8s ease infinite',
  },
  title: {
    textTransform: 'uppercase',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontSize: 60,
    fontWeight: 100,
    letterSpacing: 2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 40,
    },
  },
  description: {
    color: theme.palette.primary.contrastText,
    fontSize: 28,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
    marginTop: theme.spacing(3),
    maxWidth: 600,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.3,
  },
  container: {
    padding: theme.spacing(5),
  },
  clientLogoContainer: {
    textAlign: 'center',
  },
  clientLogo: {
    maxWidth: '100%',
    maxHeight: 200,
  },
}))(({
  classes,
}) => (
  <>
    <MetaTags>
      <meta name="description" content="With vast expertise across telecom network testing domains, INVAS has been a go to solution partner for some of the most esteemed companies globally." />
    </MetaTags>
    <div className={classes.bgImg}>
      <Container>
        <Breadcrumbs path={BREADCRUMBS} contrast />
        <Typography className={classes.title}>Clients</Typography>
        <Typography className={classes.description}>
          With a vast expertise across technological domains, INVAS has been a go
          to solution partner for some of the most esteemed companies globally.
          From major international service providers to esteemed public sector units
          and highly sophisticated defense organisations. INVAS has time and
          again, proven to deliver value with the wealth of it&apos;s experience
          and the spirit of it&apos;s team.
        </Typography>
      </Container>
    </div>
    <Container maxWidth="md" className={classes.container}>
      <Grid container spacing={10} alignItems="center">
        {CLIENTS.map((client) => (
          <Grid xs={6} sm={4} md={3} key={client} item className={`img-hover-zoom--colorize ${classes.clientLogoContainer}`}>
            <img
              className={classes.clientLogo}
              src={`${process.env.PUBLIC_URL}/images/clients/${client}`}
              alt={client}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  </>
));

export default Clients;
