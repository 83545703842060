import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  list: JSON.parse(localStorage.getItem('shortlist')) || [],
  isEnquireOpen: false,
};

const slice = createSlice({
  name: 'shortlist',
  initialState,
  reducers: {
    openShortlist: (state) => ({ ...state, isOpen: true }),
    openEnquire: (state) => ({ ...state, isEnquireOpen: true }),
    closeShortlist: (state) => ({ ...state, isOpen: false }),
    closeEnquire: (state) => ({ ...state, isEnquireOpen: false }),
    addShortlist: (state, { payload }) => {
      let { list } = state;

      if (Array.isArray(payload)) {
        list = [
          ...state.list,
          ...payload.filter(
            ({ id: newId }) => state.list.findIndex(({ id }) => id === newId) === -1,
          ),
        ];
      } else if (state.list.findIndex(({ id }) => id === payload.id) === -1) {
        list = [...state.list, payload];
      }

      localStorage.setItem('shortlist', JSON.stringify(list));
      return { ...state, list };
    },
    removeShortlist: (state, { payload }) => {
      const list = state.list.filter(({ id }) => id !== payload);
      localStorage.setItem('shortlist', JSON.stringify(list));
      return {
        ...state,
        list,
      };
    },
    clearShortlist: (state) => {
      const list = [];
      localStorage.setItem('shortlist', JSON.stringify(list));
      return { ...state, list };
    },
  },
});

export const shortlistSelector = ({ shortlist }) => shortlist;

export const {
  openShortlist,
  openEnquire,
  closeShortlist,
  closeEnquire,
  addShortlist,
  removeShortlist,
  clearShortlist,
} = slice.actions;

export default slice.reducer;
