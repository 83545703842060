import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Container,
  withStyles,
  IconButton,
  Hidden,
  Card,
  CardMedia,
  CardContent,
  Collapse,
  CircularProgress,
} from '@material-ui/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper';
import MetaTags from 'react-meta-tags';
import {
  ChevronRight,
  ChevronLeft,
  ExpandMore,
  ExpandLess,
} from '@material-ui/icons';
import {
  teamMembersSelector,
  isFetchingTeamMembersSelector,
} from 'slices/teamMembers';
import { getTeamMembers } from 'App/actions';

const ExecutiveTeam = withStyles((theme) => ({
  pageTitle: {
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontWeight: 100,
    fontSize: 54,
    textAlign: 'center',
    color: '#47a5e3',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: 32,
      marginBottom: theme.spacing(8),
    },
  },
  pageTitleUnderline: {
    height: 2,
    width: theme.spacing(10),
    background: `linear-gradient(to left, #47a5e3, ${theme.palette.primary.main})`,
    borderRadius: 2,
    marginLeft: 296,
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 176,
      width: 50,
      marginBottom: theme.spacing(1),
    },
  },
  contentContainer: {
    backgroundImage: `linear-gradient(to top right, ${theme.palette.secondary.dark}, ${theme.palette.primary.main})`,
    color: theme.palette.common.white,
    padding: theme.spacing(4),
    minHeight: 430,
    [theme.breakpoints.down('md')]: {
      minHeight: 540,
    },
  },
  sliderContainer: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  sliderItem: {
    maxWidth: 900,
    [theme.breakpoints.down('md')]: {
      maxWidth: 700,
    },
  },
  name: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  designation: {
    marginBottom: theme.spacing(2),
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  teamImage: {
    height: '100%',
    backgroundSize: 'auto 100%',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
  },
  cardMedia: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain',
  },
  cardContent: {
    backgroundImage: `linear-gradient(to top right, ${theme.palette.secondary.dark}, ${theme.palette.primary.main})`,
    color: theme.palette.common.white,
    position: 'relative',
  },
  expandButton: {
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
}))(({ classes }) => {
  const swiperRef = useRef();
  const dispatch = useDispatch();
  const [expand, setExpand] = useState(null);
  const teamMembers = useSelector(teamMembersSelector);
  const isFetchingTeamMembers = useSelector(isFetchingTeamMembersSelector);

  useEffect(() => { dispatch(getTeamMembers()); }, [dispatch]);

  return (
    <>
      <MetaTags>
        <meta name="description" content="Founder Neeraj Dhawan, CEO Amit Bindroo, Neha Bhatt, Bijay Singh, Rahul Malhotra, Y Malleshwar, Amit Pathak, Rajesh Gupta, Nitin Aggarwal, Yasir Y Khan" />
      </MetaTags>
      <Container>
        <Grid container direction="column" alignItems="center">
          <Grid item style={{ overflow: 'hidden' }}>
            <Box className={classes.pageTitleUnderline} data-aos="fade-left" />
          </Grid>
          <Grid item>
            <Typography className={classes.pageTitle}>Executive Team</Typography>
          </Grid>
        </Grid>
        {
          !teamMembers || isFetchingTeamMembers ? <Box paddingY={20} textAlign="center"><CircularProgress /></Box> : (
            <>
              <Hidden smDown>
                <Swiper
                  modules={[EffectCoverflow]}
                  effect="coverflow"
                  centeredSlides
                  loop
                  slidesPerView="auto"
                  grabCursor
                  slideToClickedSlide
                  onSwiper={(swiper) => { swiperRef.current = swiper; }}
                  className={classes.sliderContainer}
                >
                  {teamMembers.map(({
                    name,
                    designation,
                    content,
                    img,
                    alt,
                  }) => (
                    <SwiperSlide key={name} className={classes.sliderItem}>
                      <Paper elevation={10}>
                        <Grid container alignContent="flex-end">
                          <Grid item xs={5}>
                            <Box
                              role="img"
                              aria-label={alt}
                              className={classes.teamImage}
                              style={{ backgroundImage: `url("${process.env.PUBLIC_URL}/images/team/${img}")` }}
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <Box className={classes.contentContainer}>
                              <Typography className={classes.name}>{name}</Typography>
                              <Typography className={classes.designation}>{designation}</Typography>
                              <Typography className={classes.content}>{content}</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Paper>
                    </SwiperSlide>
                  ))}
                </Swiper>
                <Box textAlign="center">
                  <IconButton onClick={() => swiperRef.current.slidePrev()}>
                    <ChevronLeft />
                  </IconButton>
                  <IconButton onClick={() => swiperRef.current.slideNext()}>
                    <ChevronRight />
                  </IconButton>
                </Box>
              </Hidden>
              <Hidden mdUp>
                <Grid container spacing={3}>
                  {teamMembers.map(({
                    name,
                    designation,
                    content,
                    img,
                    alt,
                  }, i) => (
                    <Grid item key={name} xs={12}>
                      <Card elevation={10}>
                        <CardMedia className={classes.cardMedia} image={`${process.env.PUBLIC_URL}/images/team/${img}`} role="img" aria-label={alt} />
                        <CardContent className={classes.cardContent}>
                          <Typography className={classes.name}>{name}</Typography>
                          <Typography className={classes.designation}>{designation}</Typography>
                          <Collapse in={i === expand}>
                            <Typography className={classes.content}>{content}</Typography>
                          </Collapse>
                          <IconButton
                            color="inherit"
                            className={classes.expandButton}
                            onClick={() => setExpand(expand === i ? null : i)}
                          >
                            {expand === i ? <ExpandLess /> : <ExpandMore />}
                          </IconButton>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
              </Hidden>
            </>
          )
        }
        <Box pb={10} />
      </Container>
    </>
  );
});

export default ExecutiveTeam;
