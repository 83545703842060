import React from 'react';
import {
  bool,
  func,
  arrayOf,
  string,
  shape,
} from 'prop-types';
import {
  SwipeableDrawer,
  Toolbar,
  withStyles,
  Box,
} from '@material-ui/core';
import Translator from 'App/components/Translator';
import MenuList from './MenuList';
import DownloadCatalogue from './DownloadCatalogue';

const RightMenu = withStyles((theme) => ({
  paper: {
    width: '100%',
  },
  toolbar: {
    width: 250,
  },
  translator: {
    position: 'fixed',
    left: theme.spacing(2),
    bottom: 0,
  },
}))(({
  items,
  isOpen,
  handleOpen,
  handleClose,
  toggleContactUs,
  classes,
}) => (
  <SwipeableDrawer variant="persistent" classes={{ paper: classes.paper }} anchor="right" open={isOpen} onOpen={handleOpen} onClose={handleClose}>
    <Toolbar className={classes.toolbar} />
    <Box textAlign="right" p={1}>
      <DownloadCatalogue />
    </Box>
    <MenuList items={items} handleClose={handleClose} toggleContactUs={toggleContactUs} />
    <Box className={classes.translator}><Translator /></Box>
  </SwipeableDrawer>
));

RightMenu.propTypes = {
  items: arrayOf(shape({
    label: string.isRequired,
    to: string,
    children: arrayOf(shape()),
  })).isRequired,
  isOpen: bool.isRequired,
  toggleContactUs: func.isRequired,
  handleOpen: func.isRequired,
  handleClose: func.isRequired,
};

export default RightMenu;
