import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert as MuiAlert } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';
import { alertSelector, dismissAlert } from 'slices/alert';

const Alert = () => {
  const { isOpen, severity, message } = useSelector(alertSelector);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(dismissAlert());

  return (
    <Snackbar open={isOpen} onClose={handleClose}>
      <MuiAlert severity={severity}>{message}</MuiAlert>
    </Snackbar>
  );
};

export default Alert;
