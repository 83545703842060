import React, { useRef, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { shape } from 'prop-types';
import { FormHelperText } from '@material-ui/core';


const Recaptcha = ({
  field: { name, value },
  form: { setFieldValue, getFieldMeta },
}) => {
  const recaptchaRef = useRef();
  const { touched, error } = getFieldMeta(name);

  useEffect(() => { if (!value) recaptchaRef.current.reset(); }, [value]);

  return (
    <>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        onChange={(val) => setFieldValue(name, !!val)}
      />
      <FormHelperText error>{touched && error}</FormHelperText>
    </>
  );
};

Recaptcha.propTypes = {
  field: shape().isRequired,
  form: shape({ errors: shape().isRequired }).isRequired,
};

export default Recaptcha;
