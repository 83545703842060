import React, { Fragment, useState, useEffect } from 'react';
import {
  arrayOf,
  shape,
  string,
  bool,
  number,
  func,
} from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  withStyles,
} from '@material-ui/core';
import {
  ChevronRight,
  ExpandMore,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFilters } from 'App/actions';
import { filterSelector, isFetchingFilterSelector } from 'slices/filter';

const MenuList = withStyles((theme) => ({
  button: {
    backgroundColor: ({ depth }) => `${theme.palette.grey[depth % 2 ? 200 : 0]} !important`,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: ({ depth }) => theme.spacing((depth + 1) * 4),
    },
  },
}))(({
  items,
  isOpen,
  depth,
  handleClose,
  toggleContactUs,
  classes,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(-1);
  const oemFilter = useSelector(filterSelector).find(({ name }) => name === 'OEM');
  const isFetchingFilter = useSelector(isFetchingFilterSelector);

  useEffect(() => { setOpen(-1); }, [isOpen]);
  useEffect(() => { dispatch(getAllFilters()); }, [dispatch]);

  return (
    <List disablePadding>
      {items.map(({
        label, to, isContact, isProducts, children: _children,
      }, i) => {
        let children = _children;

        if (isProducts && !isFetchingFilter) {
          children = Object.keys(oemFilter.options)
            .map((oem) => ({
              label: oem,
              to: `/products?OEM=${oem}`,
            }));
        }

        const handleClick = () => {
          if (isContact) {
            toggleContactUs();
            handleClose();
          } else if (to && !children) {
            history.push(to);
            handleClose();
          } else {
            setOpen(i === open ? -1 : i);
          }
        };

        return (
          <Fragment key={label}>
            <ListItem divider button onClick={handleClick} classes={classes}>
              <ListItemText primary={label} />
              {children && (open === i ? <ExpandMore /> : <ChevronRight />)}
            </ListItem>
            {children && (
            <Collapse in={open === i} timeout="auto" unmountOnExit>
              <MenuList
                items={children}
                isOpen={open === i}
                depth={depth + 1}
                handleClose={handleClose}
              />
            </Collapse>
            )}
          </Fragment>
        );
      })}
    </List>
  );
});

MenuList.propTypes = {
  items: arrayOf(shape({
    label: string.isRequired,
    to: string,
    children: arrayOf(shape()),
  })).isRequired,
  isOpen: bool,
  depth: number,
  toggleContactUs: func,
  handleClose: func.isRequired,
};

MenuList.defaultProps = {
  isOpen: false,
  depth: 0,
};

export default MenuList;
