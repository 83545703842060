import React, { Fragment, useEffect, useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  CircularProgress,
  FormControlLabel,
  Radio,
  Box,
  ExpansionPanel,
  ExpansionPanelSummary,
  Chip,
  Button,
  withStyles,
  useMediaQuery,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { Formik, Form, Field } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import Breadcrumbs from 'App/components/Breadcrumbs';
import ProductCard from 'App/components/ProductCard';
import { useDispatch, useSelector } from 'react-redux';
import { productSelector, isFetchingProductSelector } from 'slices/product';
import { filterSelector, isFetchingFilterSelector } from 'slices/filter';
import { ExpandMore } from '@material-ui/icons';
import { getAllFilters, getAllProducts } from 'App/actions';
import MetaTags from 'react-meta-tags';

const PAGE_SIZE = 18;

const BREADCRUMBS = [{
  label: 'Home',
  to: '/',
}, {
  label: 'Products',
}];

const Products = withStyles((theme) => ({
  bgImg: {
    backgroundImage: `linear-gradient(to top right, ${theme.palette.secondary.main}, ${theme.palette.primary.light})`,
    paddingBottom: theme.spacing(25),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(10),
    },
    marginBottom: theme.spacing(8),
    backgroundSize: '200% 200%',
    animation: 'gradient 8s ease infinite',
  },
  title: {
    textTransform: 'uppercase',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontSize: 60,
    fontWeight: 100,
    letterSpacing: 2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 40,
    },
  },
  description: {
    color: theme.palette.primary.contrastText,
    fontSize: 28,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
    marginTop: theme.spacing(3),
    maxWidth: 600,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.3,
  },
  recentSearches: {
    color: theme.palette.primary.contrastText,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontWeight: theme.typography.fontWeightLight,
    fontStyle: 'italic',
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(1),
  },
  productLoader: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  filterContainer: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(0),
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(0),
    },
  },
  filterHeaderContainer: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    minHeight: 56,
    height: 56,
    '&.Mui-expanded': {
      minHeight: 56,
      height: 56,
    },
  },
  filterHeader: {
    fontWeight: theme.typography.fontWeightBold,
  },
  filterTitle: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 18,
    marginBottom: theme.spacing(1),
  },
  filterItemContainer: {
    paddingLeft: theme.spacing(1),
  },
  filterItemLabel: {
    fontWeight: theme.typography.fontWeightLight,
  },
  productsContainer: {
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingRight: theme.spacing(0),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(4),
    },
  },
}))(({
  classes,
  width,
  location,
  history,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [page, setPage] = useState(1);
  const params = new URLSearchParams(location.search);
  const dispatch = useDispatch();
  const products = useSelector(productSelector);
  const filters = useSelector(filterSelector);
  const isFetchingProduct = useSelector(isFetchingProductSelector);
  const isFetchingFilter = useSelector(isFetchingFilterSelector);
  const initialValues = {};
  filters.forEach(({ name }) => { initialValues[name] = params.get(name); });

  useEffect(() => { dispatch(getAllFilters()); }, [dispatch]);
  useEffect(() => { dispatch(getAllProducts()); }, [dispatch]);

  return (
    <>
      <MetaTags>
        <meta name="description" content="Our Products are EXFO OTDR, Power Meters, Light Sources, Multimeters, FIP, Spectrum Analyzer, Splicing Machines, GNSS receivers, Network Design & Testing." />
      </MetaTags>
      <Formik initialValues={initialValues} enableReinitialize>
        {({ values, setFieldValue, resetForm }) => {
          const clearForm = () => {
            const emptyValues = {};

            filters.forEach(({ name }) => { emptyValues[name] = ''; });
            resetForm({ values: emptyValues });
          };

          useEffect(() => {
            if (filters && Object.keys(filters).length) {
              setPage(1);
              history.replace({
                pathname: '/products',
                search: `?${
                  Object.keys(values)
                    .filter((key) => !!values[key])
                    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(values[key])}`).join('&')
                }`,
              });
            }
          }, [values]);
          let filteredProducts = Object.keys(products);

          Object.entries(values).forEach(([key, value]) => {
            const activeProducts = filters.find(({ name }) => name === key).options[value];

            if (activeProducts) {
              filteredProducts = filteredProducts.filter((id) => activeProducts.indexOf(id) > -1);
            }
          });

          filteredProducts = filteredProducts.map((id) => products[id]);
          const pageCount = Math.ceil(filteredProducts.length / PAGE_SIZE);
          const paginatedFilteredProducts = filteredProducts
            .slice(
              (page - 1) * PAGE_SIZE,
              Math.min(page * PAGE_SIZE, filteredProducts.length),
            );

          let productGrid = (
            <Box width="100%" textAlign="center" my={6}>
              <CircularProgress size={50} className={classes.productLoader} />
            </Box>
          );

          if (!isFetchingProduct) {
            productGrid = paginatedFilteredProducts.length > 0 ? (
              <Grid container spacing={1}>
                {paginatedFilteredProducts.map((product) => (
                  <Grid key={product.id} item xs={12} sm={6} md={4}>
                    <ProductCard data={product} />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box width="100%" textAlign="center" my={6}>
                <Typography variant="h5" color="textSecondary">
                  No Products Found
                </Typography>
              </Box>
            );
          }

          return (
            <>
              <div className={classes.bgImg}>
                <Container>
                  <Breadcrumbs path={BREADCRUMBS} contrast />
                  <Typography className={classes.title}>Products</Typography>
                  <Typography className={classes.description}>
                    INVAS offers a wide range of Telecommunication Test and Measuring products
                    along with customised solutions to suite your application or industry. Our
                    range of products cover wireline, wireless, optical, network security,
                    copper wire, and cellular technologies which are proven to be robust and
                    reliable investments to help you ensure high performance of your network.
                  </Typography>
                </Container>
              </div>
              <Container maxWidth="xl">
                <Grid container>
                  <Grid item sm={3} xs={12}>
                    <Form className={classes.filterContainer}>
                      <ExpansionPanel defaultExpanded={isWidthUp('sm', width)}>
                        <ExpansionPanelSummary
                          className={classes.filterHeaderContainer}
                          expandIcon={<ExpandMore />}
                          IconButtonProps={{ style: { color: 'white' } }}
                        >
                          <Typography className={classes.filterHeader}>
                            Filter Products
                          </Typography>
                        </ExpansionPanelSummary>
                        <Box paddingX={2}>
                          <Box paddingY={2}>
                            <Grid container>
                              <Grid item xs>
                                <Grid container spacing={1}>
                                  {Object.entries(values).map(([key, value]) => value && (
                                  <Grid item key={value} style={{ maxWidth: '100%' }}>
                                    <Chip
                                      label={value}
                                      onDelete={() => setFieldValue(key, '')}
                                      color="secondary"
                                      style={{ maxWidth: '100%' }}
                                    />
                                  </Grid>
                                  ))}
                                </Grid>
                              </Grid>
                              <Grid item>
                                {Object.values(values).filter((o) => o).length > 0 && (
                                <Button color="secondary" onClick={clearForm}>
                                  Clear All
                                </Button>
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                          {isFetchingFilter
                            ? <Box my={4} textAlign="center"><CircularProgress /></Box>
                            : filters.map(({ name, options }) => (
                              <Fragment key={name}>
                                <Typography className={classes.filterTitle}>
                                  {name}
                                </Typography>
                                <Field component={RadioGroup} name={name}>
                                  {Object.keys(options).map((filterOption) => (
                                    <FormControlLabel
                                      key={filterOption}
                                      value={filterOption}
                                      control={<Radio size="small" />}
                                      label={(
                                        <Typography className={classes.filterItemLabel}>
                                          {filterOption}
                                        </Typography>
                                    )}
                                    />
                                  ))}
                                </Field>
                                <br />
                              </Fragment>
                            ))}
                        </Box>
                      </ExpansionPanel>
                    </Form>
                  </Grid>
                  <Grid item sm={9} xs={12}>
                    <div className={classes.productsContainer}>
                      {productGrid}
                    </div>
                    {!isFetchingProduct && pageCount > 1 && (
                    <Box my={3} textAlign="center">
                      <Pagination
                        page={page}
                        count={pageCount}
                        color="primary"
                        size={isMobile ? 'medium' : 'large'}
                        onChange={(e, newPage) => setPage(newPage)}
                        style={{ display: 'inline-flex' }}
                      />
                    </Box>
                    )}
                  </Grid>
                </Grid>
              </Container>
            </>
          );
        }}
      </Formik>
    </>
  );
});

export default withWidth()(Products);
