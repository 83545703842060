import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
};

const slice = createSlice({
  name: 'otdrFinder',
  initialState,
  reducers: {
    toggleOtdrFinder: (state) => ({ ...state, isOpen: !state.isOpen }),
  },
});

export const otdrFinderSelector = ({ otdrFinder }) => otdrFinder;

export const {
  toggleOtdrFinder,
} = slice.actions;

export default slice.reducer;
