import React, { useState } from 'react';
import { node } from 'prop-types';
import { Slide } from '@material-ui/core';
import theme from 'theme';

const ScrollGradient = ({ children }) => {
  const [gradientTop, setGradientTop] = useState(false);
  const [gradientBottom, setGradientBottom] = useState(true);

  const handleScroll = (e) => {
    if (e.target.scrollTop === 0) {
      setGradientTop(false);
    } else {
      setGradientTop(true);
    }

    if (e.target.childNodes[0].clientHeight === e.target.clientHeight + e.target.scrollTop) {
      setGradientBottom(false);
    } else {
      setGradientBottom(true);
    }
  };
  return (
    <>
      <div
        onScroll={handleScroll}
        className="hide-scroll"
        style={{
          overflowY: 'scroll',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        {children}
      </div>
      <Slide timeout={500} in={gradientTop}>
        <div
          style={{
            background: 'linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0))',
            height: theme.spacing(4),
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            pointerEvents: 'none',
          }}
        />
      </Slide>
      <Slide timeout={500} in={gradientBottom} direction="up">
        <div
          style={{
            background: 'linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1))',
            height: theme.spacing(4),
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            pointerEvents: 'none',
          }}
        />
      </Slide>

    </>
  );
};

ScrollGradient.propTypes = {
  children: node.isRequired,
};

export default ScrollGradient;
