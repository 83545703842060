import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFetching: false,
  products: {},
  isFetchingFeaturedProducts: false,
  featuredProducts: [],
  isSearching: false,
  searchProducts: [],
  filterProducts: [],
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProducts: (state, { payload }) => ({
      ...state,
      products: payload.reduce(
        (acc, cur) => ({ ...acc, [cur.id]: cur }),
        state.products,
      ),
    }),
    setFetchingProducts: (state, { payload }) => ({
      ...state,
      isFetching: payload,
    }),
    setFeaturedProducts: (state, { payload }) => ({
      ...state,
      featuredProducts: payload,
    }),
    setFetchingFeaturedProducts: (state, { payload }) => ({
      ...state,
      isFetchingFeaturedProducts: payload,
    }),
    setSearchProduct: (state, { payload }) => ({
      ...state,
      searchProducts: payload,
    }),
    setSearchingProduct: (state, { payload }) => ({
      ...state,
      isSearching: payload,
    }),
    setFilterProduct: (state, { payload }) => ({
      ...state,
      filterProducts: payload,
    }),
    setFilteringProduct: (state, { payload }) => ({
      ...state,
      isFiltering: payload,
    }),
  },
});

export const productSelector = ({ product: { products } }) => products;
export const isFetchingProductSelector = ({ product: { isFetching } }) => isFetching;
export const featuredProductSelector = ({ product: { featuredProducts } }) => featuredProducts;
export const isFetchingFeaturedProductSelector = ({
  product: {
    isFetchingFeaturedProducts,
  },
}) => isFetchingFeaturedProducts;
export const searchProductSelector = ({ product: { searchProducts } }) => searchProducts;
export const isSearchingProductSelector = ({ product: { isSearching } }) => isSearching;
export const filterProductSelector = ({ product: { filterProducts } }) => filterProducts;
export const isFilteringProductSelector = ({ product: { isFiltering } }) => isFiltering;

export const {
  setProducts,
  setFetchingProducts,
  setFeaturedProducts,
  setFetchingFeaturedProducts,
  setSearchProduct,
  setSearchingProduct,
  setFilterProduct,
  setFilteringProduct,
} = slice.actions;

export default slice.reducer;
