import React, { useEffect } from 'react';
import { bool, func } from 'prop-types';
import {
  Grid,
  Box,
  Container,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  withStyles,
  Typography,
  Divider,
  useMediaQuery,
  FormControl,
  InputLabel,
  useTheme,
} from '@material-ui/core';
import { Formik, Field, Form } from 'formik';
import { TextField, Select } from 'formik-material-ui';
import Recaptcha from 'App/components/Recaptcha';
import Link from 'App/components/Link';
import { Close } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getOffices, submitConnect } from 'App/actions';
import { officeSelector } from 'slices/offices';


const ContactUsDialog = withStyles((theme) => ({
  titleContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1),
  },
  logo: {
    width: 150,
  },
  title: {
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.primary.main,
    fontSize: 20,
    textTransform: 'uppercase',
    letterSpacing: 16,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
  },
  secondaryTitle: {
    fontSize: 20,
    lineHeight: 1.2,
    fontWeight: theme.typography.fontWeightLight,
  },
  formContainer: {
    marginTop: theme.spacing(3),
  },
  dividerText: {
    fontSize: 20,
    color: theme.palette.primary.main,
  },
  sectionDivider: {
    background: theme.palette.primary.main,
  },
  contactIcon: {
    width: theme.spacing(4),
  },
  contactText: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 18,
  },
  corpOfcText: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 16,
  },
  contactTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  contactContent: {
    lineHeight: 1.2,
    fontSize: 14,
  },
  link: {
    color: theme.palette.primary.main,
  },
}))(({ open, onClose, classes }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const theme = useTheme();
  const dispatch = useDispatch();
  const offices = useSelector(officeSelector);

  useEffect(() => {
    dispatch(getOffices());
  }, [dispatch]);

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="md" style={{ zIndex: theme.zIndex.modal + 1 }} fullScreen={isMobile}>
      <Formik
        initialValues={{
          name: '',
          company: '',
          email: '',
          contact: '',
          region: 0,
          recaptcha: '',
        }}
        onSubmit={({
          name, company, email, contact,
        }, { resetForm }) => dispatch(submitConnect({
          name, company, email, contact,
        })).then(() => resetForm())}
      >
        {({ values, isSubmitting }) => (
          <>
            <DialogTitle className={classes.titleContainer}>
              <Grid container spacing={2} alignItems="flex-end" justify="center">
                <Grid item>
                  <img style={{ verticalAlign: 'text-bottom' }} alt="logo" className={classes.logo} src={`${process.env.PUBLIC_URL}/images/compact-logo.png`} />
                </Grid>
                <Grid item>
                  <Typography className={classes.title}>Connect</Typography>
                </Grid>
              </Grid>
              <Box position="absolute" right={theme.spacing(2)} top={theme.spacing(2)}>
                <IconButton onClick={onClose}><Close /></IconButton>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Container maxWidth="sm">
                <Typography color="textSecondary" align="center" className={classes.secondaryTitle}>
                  Please leave your contact details and our
                  representative will get in touch with you:
                </Typography>
              </Container>
              <br />
              <Container maxWidth="xs" className={classes.formContainer}>
                <Form>
                  <Grid container direction="column" spacing={1}>
                    <Grid item>
                      <Field
                        name="name"
                        label="Full Name*"
                        component={TextField}
                        fullWidth
                        validate={(value) => !value && 'Required'}
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        name="company"
                        label="Company"
                        component={TextField}
                        fullWidth
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        name="email"
                        label="Email"
                        component={TextField}
                        fullWidth
                        validate={(value) => value && !value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/) && 'Invalid Email'}
                      />
                    </Grid>
                    <Grid item>
                      <Field
                        name="contact"
                        label="Contact Number*"
                        component={TextField}
                        fullWidth
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                        }}
                        validate={(value) => (!value && 'Required') || (!value.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) && 'Invalid Contact Number')}
                      />
                    </Grid>
                    <Grid item>
                      <Box mt={2}>
                        <Field
                          name="recaptcha"
                          component={Recaptcha}
                          validate={(value) => !value && 'Required'}
                        />
                      </Box>
                    </Grid>
                    <Grid item>
                      <center>
                        <br />
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          size="large"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? 'Submitting' : 'Submit'}
                        </Button>
                      </center>
                    </Grid>
                  </Grid>
                </Form>
                <br />
                <br />
                <Grid container alignItems="center" spacing={3} justify="center">
                  <Grid item xs={4}>
                    <Divider className={classes.sectionDivider} />
                  </Grid>
                  <Grid item>
                    <Typography className={classes.dividerText}>OR</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Divider className={classes.sectionDivider} />
                  </Grid>
                </Grid>
                <br />
                <br />
              </Container>
              <Typography color="textSecondary" align="center" className={classes.secondaryTitle}>
                Contact us at:
              </Typography>
              <Box my={2}>
                <Typography color="textSecondary" align="center" className={classes.corpOfcText}>
                  Corporate Office: Plot no. 108, 4th Floor, Sector 44, Gurugram, Haryana - 122002
                </Typography>
              </Box>
              <Grid container justify="space-evenly" spacing={2}>
                <Grid item sm="auto" xs={12}>
                  <Grid container wrap="nowrap" spacing={2} alignItems="center">
                    <Grid item>
                      <img src={`${process.env.PUBLIC_URL}/images/phone.png`} className={classes.contactIcon} alt="phone" />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.contactText} noWrap>
                        <Link href="tel:+91-124-4200984">+91-124-4200984</Link>
                        <br />
                        <Link href="tel:+91-124-4200985">+91-124-4200985</Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item sm="auto" xs={12}>
                  <Grid container wrap="nowrap" spacing={2} alignItems="center">
                    <Grid item>
                      <img src={`${process.env.PUBLIC_URL}/images/email.png`} className={classes.contactIcon} alt="email" style={{ marginTop: 5 }} />
                    </Grid>
                    <Grid item>
                      <Typography className={classes.contactText} noWrap>
                        <Link href="mailto:sales@invas.in">sales@invas.in</Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <br />
              <br />
              {offices.length && (
                <Grid container justify="center">
                  <Grid item>
                    <FormControl variant="outlined">
                      <InputLabel>Location</InputLabel>
                      <Field
                        native
                        label="Location"
                        name="region"
                        inputProps={{ style: { minWidth: 120 } }}
                        component={Select}
                      >
                        {offices.map(({ region }, i) => (
                          <option key={region} value={i}>{region}</option>
                        ))}
                      </Field>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              <br />
              <br />
              {offices[values.region] && (
                <Container maxWidth="xs" disableGutters>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography className={classes.contactTitle}>Address:</Typography>
                      <Typography className={classes.contactContent}>
                        {offices[values.region].name}
                      </Typography>
                      <Typography className={classes.contactContent}>
                        {offices[values.region].line1}
                      </Typography>
                      <Typography className={classes.contactContent}>
                        {offices[values.region].line2}
                      </Typography>
                      <Typography className={classes.contactContent}>
                        {offices[values.region].line3}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.contactTitle}>Email:</Typography>
                      <Link className={classes.link} href={`mailto:sales@invas.in?subject=${offices[values.region].region} Office | Enquiry`}>sales@invas.in</Link>
                    </Grid>
                    {offices[values.region].phone && (
                    <Grid item>
                      <Typography className={classes.contactTitle}>Contact:</Typography>
                      <Typography className={classes.contactContent}>
                        {offices[values.region].phone}
                      </Typography>
                    </Grid>
                    )}
                    {offices[values.region].fax && (
                    <Grid item>
                      <Typography className={classes.contactTitle}>Fax:</Typography>
                      <Typography className={classes.contactContent}>
                        {offices[values.region].fax}
                      </Typography>
                    </Grid>
                    )}
                    {offices[values.region].gstn && (
                    <Grid item>
                      <Typography className={classes.contactTitle}>GST Number:</Typography>
                      <Typography className={classes.contactContent}>
                        {offices[values.region].gstn}
                      </Typography>
                    </Grid>
                    )}
                  </Grid>
                </Container>
              )}
              <br />
            </DialogContent>
          </>
        )}
      </Formik>
    </Dialog>
  );
});

ContactUsDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
};

export default ContactUsDialog;
