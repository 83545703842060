import React from 'react';
import {
  Box,
  Fab,
  Zoom,
  withStyles,
} from '@material-ui/core';
import { WhatsApp as WhatsappIcon } from '@material-ui/icons';
import { WHATSAPP_THEME } from 'App/constants';

const Whatsapp = withStyles((theme) => ({
  toggleButton: {
    color: theme.palette.common.white,
    background: WHATSAPP_THEME.primary,
    '&.MuiFab-root:hover': {
      background: WHATSAPP_THEME.secondary,
    },
  },
  toggleContainer: {
    position: 'fixed',
    right: theme.spacing(3),
    bottom: theme.spacing(3),
    zIndex: theme.zIndex.modal + 1,
  },
}))(({ classes }) => (
  <Zoom in>
    <Box className={classes.toggleContainer}>
      <Fab
        onClick={() => window.open('https://wa.me/919599144113')}
        className={classes.toggleButton}
      >
        <WhatsappIcon />
      </Fab>
    </Box>
  </Zoom>
));

export default Whatsapp;
