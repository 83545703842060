import React, { createRef } from 'react';
import {
  Button,
  Box,
  Chip,
  FormHelperText,
} from '@material-ui/core';
import {
  shape,
  string,
  any,
  func,
} from 'prop-types';
import { AttachFile } from '@material-ui/icons';

const FileInput = ({
  field: { name, value },
  form: { setFieldValue, getFieldMeta },
  label,
}) => {
  const inputRef = createRef();
  const { error } = getFieldMeta(name);

  return (
    <>
      <Button
        startIcon={<AttachFile />}
        variant="contained"
        component="label"
      >
        {label}
        <input
          type="file"
          ref={inputRef}
          style={{ display: 'none' }}
          onChange={(e) => setFieldValue(name, e.target.files[0])}
        />
      </Button>
      {value && (
      <Box ml={2} display="inline-block">
        <Chip
          label={value.name}
          variant="outlined"
          color="secondary"
          onDelete={() => { setFieldValue(name, null); inputRef.current.value = null; }}
        />
      </Box>
      )}
      <FormHelperText error>{error}</FormHelperText>
    </>
  );
};

FileInput.propTypes = {
  field: shape({
    name: string.isRequired,
    value: any,
  }).isRequired,
  form: shape({
    setFieldValue: func.isRequired,
  }).isRequired,
  label: string,
};
FileInput.defaultProps = {
  label: 'Attachment',
};

export default FileInput;
