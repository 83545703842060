/* eslint-disable no-undef */
import { bool } from 'prop-types';
import React, { useEffect } from 'react';

const Translator = ({ inline }) => {
  useEffect(() => {
    const translate = () => {
      google.translate.TranslateElement(
        inline
          ? { pageLanguage: 'en', layout: google.translate.TranslateElement.InlineLayout.SIMPLE }
          : { pageLanguage: 'en' },
        'google_translate_element',
      );
    };

    if (google && google.translate && google.translate.TranslateElement) translate();
    else window.googleTranslateInit = translate;
  }, [inline]);

  return (
    <div id="google_translate_element" />
  );
};

Translator.propTypes = {
  inline: bool,
};

Translator.defaultProps = {
  inline: false,
};

export default Translator;
