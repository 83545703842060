import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import { ReactComponent as FileDownload } from '../icons/file-download.svg';

const DownloadCatalogue = withStyles((theme) => ({
  catalogueRoot: {
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(-1.5),
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    },
  },
  catalogueText: {
    textTransform: 'none',
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1,
    fontSize: 10,
    textAlign: 'left',
    verticalAlign: 'top',
    color: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      color: theme.palette.common.white,
    },
  },
  catalogueIcon: {
    marginRight: 0,
    '&.MuiButton-iconSizeLarge > *:first-child': {
      fontSize: 30,
    },
    fill: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      fill: theme.palette.common.white,
    },
  },
}))(({ classes }) => (
  <Button
    onClick={() => window.open('https://drive.google.com/file/d/1N_iirkbFNd7Cs5uIZ1AxsMU7u3WUoc_l/view?usp=sharing')}
    size="large"
    startIcon={<FileDownload />}
    classes={{
      root: classes.catalogueRoot,
      label: classes.catalogueText,
      iconSizeLarge: classes.catalogueIcon,
    }}
  >
    Catalogue
  </Button>
));

export default DownloadCatalogue;
