/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Grid,
  Box,
  Container,
  Typography,
  IconButton,
  InputAdornment,
  Hidden,
  withStyles,
} from '@material-ui/core';
import {
  Facebook,
  LinkedIn,
  YouTube,
  Instagram,
  Twitter,
  Pinterest,
  Map,
  ChevronRight,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import theme from 'theme';
import { useDispatch } from 'react-redux';
import Link from 'App/components/Link';
import { PARTNER_LOGOS, FOOTER_LINKS } from 'App/constants';
import { Field, Formik, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import { submitNewsletter } from 'App/actions';

const HIDDEN_FOR = [
  '/login',
];

const Footer = withStyles(() => ({
  container: {
    textTransform: 'uppercase',
    background: theme.palette.common.black,
    color: theme.palette.common.white,
    position: 'relative',
    overflow: 'hidden',
  },
  title: {
    fontSize: 16,
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
  linksTitle: {
    fontWeight: 900,
    fontSize: 16,
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
  linksLabel: {
    fontSize: 16,
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
  partnerLogo: {
    maxWidth: theme.spacing(9),
    [theme.breakpoints.up('md')]: {
      maxWidth: 'none',
    },
  },
  socialContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(5),
    },
  },
  socialIcon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(4.5),
      height: theme.spacing(4.5),
    },
  },
  footerImg: {
    position: 'absolute',
    left: 0,
    bottom: -32,
    width: 1000,
    pointerEvents: 'none',
    [theme.breakpoints.down('xs')]: {
      bottom: -115,
    },
  },
  copyright: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 10,
    marginTop: theme.spacing(16),
    float: 'left',
    [theme.breakpoints.down('xs')]: {
      marginTop: 94,
      fontSize: 8,
    },
  },
  footerLogo: {
    outline: 'none',
    width: 170,
    float: 'right',
    marginTop: 76,
    [theme.breakpoints.down('xs')]: {
      width: 116,
      marginTop: 60,
    },
  },
  emailContainer: {
    textTransform: 'none',
    maxWidth: 250,
  },
  emailField: {
    zIndex: 1,
    '& .MuiInput-root': {
      color: theme.palette.common.white,
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid white',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid white',
    },
    '& .MuiInputAdornment-root': {
      color: theme.palette.common.white,
    },
    '& .Mui-focused .MuiInputAdornment-root': {
      color: theme.palette.primary.main,
    },
  },
  map: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4),
    },
  },
}))(({ classes, location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const emailComponent = (
    <Formik
      initialValues={{ email: '' }}
      onSubmit={(input, { resetForm }) => dispatch(submitNewsletter(input)).then(() => resetForm())}
    >
      <Form>
        <Box mt={5} className={classes.emailContainer}>
          <Typography>Subscribe to our newsletter:</Typography>
          <Field
            fullWidth
            name="email"
            component={TextField}
            InputProps={{
              endAdornment: <InputAdornment position="end"><ChevronRight /></InputAdornment>,
            }}
            label="Your Email"
            className={classes.emailField}
            validate={(value) => value && !value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/) && 'Invalid Email'}
          />
        </Box>
      </Form>
    </Formik>
  );


  return (HIDDEN_FOR.indexOf(location.pathname) > -1 ? null : (
    <div className={classes.container}>
      <Container>
        <Box paddingX={0.5} pt={3}>
          <Typography className={classes.title}>Our Principals</Typography>
          <br />
          <Grid container justify="space-between" alignItems="center" spacing={2}>
            {PARTNER_LOGOS.map((logo) => (
              <Grid item key={logo}>
                <img className={classes.partnerLogo} alt="logo" src={`${process.env.PUBLIC_URL}/images/${logo}`} />
              </Grid>
            ))}
          </Grid>
        </Box>
        <br />
        <br />
        <br />
        <br />
        <Box paddingX={0.5}>
          <Grid container spacing={5}>
            {FOOTER_LINKS.map(({ title, links }) => (
              <Grid item md={2} sm={3} xs={6} key={title}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Typography className={classes.linksTitle}>{title}</Typography>
                  </Grid>
                  {links.map(({
                    label,
                    to,
                    dispatchFunc,
                  }) => (
                    <Grid item key={label}>
                      <Link
                        className={classes.linksLabel}
                        onClick={dispatchFunc && (() => dispatch(dispatchFunc()))}
                        to={to}
                      >
                        {label}
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
            <Grid item xs className={classes.socialContainer}>
              <Grid container direction="column">
                <Grid item>
                  <Typography className={classes.linksTitle}>Social</Typography>
                </Grid>
                <Grid item container style={{ marginLeft: theme.spacing(-1.75) }}>
                  <Grid item>
                    <IconButton
                      style={{ color: theme.palette.grey[50] }}
                      href="https://www.linkedin.com/company/invastechnologiespvtltd/"
                      target="_blank"
                    >
                      <LinkedIn className={classes.socialIcon} />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      style={{ color: theme.palette.grey[50] }}
                      href="https://www.youtube.com/channel/UCHvF9Y0eAWOjtYLkOEzF5zA"
                      target="_blank"
                    >
                      <YouTube className={classes.socialIcon} />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      style={{ color: theme.palette.grey[50] }}
                      href="https://www.facebook.com/InvasTech"
                      target="_blank"
                    >
                      <Facebook className={classes.socialIcon} />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      style={{ color: theme.palette.grey[50] }}
                      href="https://www.instagram.com/invas_technologies"
                      target="_blank"
                    >
                      <Instagram className={classes.socialIcon} />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      style={{ color: theme.palette.grey[50] }}
                      href="https://twitter.com/invas_tech"
                      target="_blank"
                    >
                      <Twitter className={classes.socialIcon} />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      style={{ color: theme.palette.grey[50] }}
                      href="https://in.pinterest.com/invastech"
                      target="_blank"
                    >
                      <Pinterest className={classes.socialIcon} />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      style={{ color: theme.palette.grey[50] }}
                      href="https://www.indiamart.com/invas-technologies/"
                      target="_blank"
                    >
                      <img
                        alt="Indiamart"
                        src={`${process.env.PUBLIC_URL}/images/Indiamart.png`}
                        className={classes.socialIcon}
                      />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      style={{ color: theme.palette.grey[50] }}
                      href="https://g.page/r/Cb6B8nuJ5wdhEAE"
                      target="_blank"
                    >
                      <Map className={classes.socialIcon} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Hidden smDown>{emailComponent}</Hidden>
            </Grid>
          </Grid>
          <Hidden mdUp>
            {emailComponent}
            <Box mb={8} />
          </Hidden>
        </Box>
        <Grid container justify="center">
          <Grid item xs={10} sm={8} md={6}>
            <a href="https://g.page/r/Cb6B8nuJ5wdhEAE" target="_blank" rel="noopener noreferrer">
              <img src={`${process.env.PUBLIC_URL}/images/map.png`} alt="map" className={classes.map} />
            </a>
          </Grid>
        </Grid>
        <img src={`${process.env.PUBLIC_URL}/images/footer.png`} alt="footer" className={classes.footerImg} />
        <Link to="/"><input type="image" onClick={() => history.push('/')} src={`${process.env.PUBLIC_URL}/images/logo-black.png`} alt="INVAS" className={classes.footerLogo} /></Link>
        <Typography className={classes.copyright}>
          &copy; INVAS TECHNOLOGIES PVT. LTD. All rights reserved
        </Typography>
      </Container>
    </div>
  ));
});

export default Footer;
