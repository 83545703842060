import React from 'react';
import { useHistory } from 'react-router-dom';
import theme from 'theme';
import {
  Box,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Grid,
  Button,
  Typography,
  withStyles,
} from '@material-ui/core';
import {
  SystemUpdateAlt,
  ShoppingCart,
} from '@material-ui/icons';
import { shape, string, arrayOf } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { shortlistProduct } from 'App/actions';
import { shortlistSelector } from 'slices/shortlist';
import { ReactComponent as ShoppingCartAdd } from 'App/components/Shortlist/icons/cart-add.svg';

const ProductCard = withStyles(() => ({
  container: {
    height: '100%',
    cursor: 'pointer',
    border: ({ elevation }) => (elevation === 0 ? `1px solid ${theme.palette.grey[300]}` : null),
  },
  media: {
    marginTop: ({ minimal }) => (minimal ? 0 : '5%'),
    height: ({ minimal }) => (minimal ? '70%' : 0),
    paddingTop: '56.25%', // 16:9
    backgroundSize: 'contain',
  },
  cardHeader: {
    color: theme.palette.text.primary,
  },
  cardHeaderMinimal: {
    color: theme.palette.text.secondary,
    '& .MuiTypography-h5': {
      [theme.breakpoints.down('sm')]: {
        fontSize: 18,
      },
    },
  },
  cardContentContainer: {
    paddingBottom: theme.spacing(4),
    height: ({ minimal, data: { featured } }) => (minimal ? '100%' : `calc(100% - ${featured ? '128px' : '104px'})`),
  },
  cardActionsContainer: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  featuredLabel: {
    background: theme.palette.secondary.main,
    textTransform: 'uppercase',
    textAlign: 'center',
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: theme.spacing(1),
  },
}))(({
  classes,
  minimal,
  data,
  data: {
    id,
    name,
    shortDesc,
    images,
    alt,
    spec,
    featured,
  },
  onClick,
  elevation = 1,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { list } = useSelector(shortlistSelector);
  const isShortlisted = list.findIndex(({ id: shortlistId }) => id === shortlistId) > -1;

  return (
    <Card className={classes.container} elevation={elevation}>
      {!minimal && featured && (
      <Typography className={classes.featuredLabel}>
        FEATURED
      </Typography>
      )}
      <Box
        className={classes.cardContentContainer}
        onClick={() => {
          if (onClick) {
            onClick();
          }
          history.push(`/products/${encodeURIComponent(id)}`);
        }}
      >
        <CardHeader
          style={{ display: 'block' }}
          title={minimal ? id : name}
          className={minimal && classes.cardHeaderMinimal}
          titleTypographyProps={{ noWrap: true }}
        />
        <CardMedia
          className={classes.media}
          image={images[0]}
          role="img"
          aria-label={alt && alt[0]}
          title="Product"
        />
        {!minimal && (
        <CardContent>
          <Typography color="textSecondary">
            {shortDesc}
          </Typography>
        </CardContent>
        )}
      </Box>
      {!minimal && (
      <Grid container spacing={1} direction="column" className={classes.cardActionsContainer}>
        <Grid item>
          <Button
            color="primary"
            startIcon={isShortlisted
              ? <ShoppingCart />
              : <ShoppingCartAdd fill="currentColor" />}
            onClick={() => dispatch(shortlistProduct(data))}
          >
            {isShortlisted ? 'Added to Cart' : 'Add to Cart'}
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            startIcon={<SystemUpdateAlt />}
            onClick={() => window.open(spec)}
          >
            Spec Sheet
          </Button>
        </Grid>
      </Grid>
      )}
    </Card>
  );
});

ProductCard.propTypes = {
  data: shape({
    id: string.isRequired,
    shortDesc: string.isRequired,
    images: arrayOf(string.isRequired).isRequired,
  }).isRequired,
};

export default ProductCard;
