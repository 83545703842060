/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { arrayOf, node, oneOfType } from 'prop-types';
import ReactMarkdown from 'markdown-to-jsx';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';


const styles = (theme) => ({
  listItem: {
    marginTop: theme.spacing(1),
  },
});

const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h5',
      },
    },
    h2: { component: Typography, props: { gutterBottom: true, variant: 'h6' } },
    h3: { component: Typography, props: { gutterBottom: true, variant: 'subtitle1' } },
    h4: {
      component: Typography,
      props: { gutterBottom: true, variant: 'caption', paragraph: true },
    },
    p: { component: Typography, props: { paragraph: true } },
    a: { component: Link },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.listItem}>
          <Typography component="span" {...props} />
        </li>
      )),
    },
    img: {
      props: {
        style: { maxWidth: '100%' },
      },
    },
  },
};

const Markdown = ({ children, ...otherProps }) => children && (
<ReactMarkdown options={options} {...otherProps}>
  {children}
</ReactMarkdown>
);

Markdown.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]),
};

Markdown.defaultProps = {
  children: null,
};

export default Markdown;
