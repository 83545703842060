import React, { useState, useRef } from 'react';
import {
  SwipeableDrawer,
  Grid,
  Box,
  Fab,
  withStyles,
  IconButton,
  Toolbar,
  Typography,
  Zoom,
  useTheme,
  Dialog,
  Button,
  Container,
  useMediaQuery,
  DialogTitle,
} from '@material-ui/core';
import ProductCard from 'App/components/ProductCard';
import {
  shortlistSelector,
  openShortlist,
  closeShortlist,
  removeShortlist,
  clearShortlist,
  openEnquire,
  closeEnquire,
} from 'slices/shortlist';
import { useSelector, useDispatch } from 'react-redux';
import {
  HighlightOff, ShoppingCart, Close,
} from '@material-ui/icons';
import { getProductUrl } from 'utils';
import { Form, Field, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import Recaptcha from 'App/components/Recaptcha';
import { submitEnquiry } from 'App/actions';
import { ReactComponent as Email } from './icons/mail.svg';
import { ReactComponent as Delete } from './icons/delete.svg';
import { ReactComponent as ShoppingCartCheckout } from './icons/cart-checkout.svg';

const Shortlist = withStyles((theme) => ({
  drawer: {
    '& .MuiDrawer-paperAnchorBottom': {
      maxWidth: theme.breakpoints.width('sm'),
      overflow: 'visible',
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      left: 52,
      [theme.breakpoints.down('xs')]: {
        left: 0,
      },
    },
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    maxHeight: 450,
    overflowY: 'scroll',
  },
  removeButton: {
    position: 'absolute',
    padding: 0,
    right: 0,
    top: 0,
    background: theme.palette.common.white,
  },
  toolbar: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  toggleButton: {
    color: theme.palette.common.white,
    background: theme.palette.primary.light,
    '&.MuiFab-root:hover': {
      background: theme.palette.primary.dark,
    },
  },
  toggleContainer: {
    position: 'fixed',
    left: theme.spacing(3),
    bottom: theme.spacing(3),
    zIndex: theme.zIndex.modal + 1,
  },
  drawerToggleContainer: {
    position: 'absolute',
    left: theme.spacing(-3.5),
    top: theme.spacing(-3.5),
    [theme.breakpoints.down('xs')]: {
      left: theme.spacing(3),
    },
  },
  title: {
    textTransform: 'uppercase',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(13),
    },
  },
  enquireContainer: {
    background: theme.palette.grey[100],
    borderRadius: 10,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  enquireTitle: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontWeight: theme.typography.fontWeightLight,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(6),
    },
  },
  enquireField: {
    '& input,textarea': {
      color: theme.palette.primary.main,
    },
    '& .MuiInput-underline:before': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderColor: theme.palette.primary.main,
    },
  },
  enquireSubmit: {
    marginTop: theme.spacing(1),
  },
}))(({ classes }) => {
  const theme = useTheme();
  const [showFab, setShowFab] = useState(true);
  const [enterAnimation, setEnterAnimation] = useState(false);
  const timeoutRef = useRef();
  const isMobile = useMediaQuery((mqTheme) => mqTheme.breakpoints.down('xs'));
  const dispatch = useDispatch();
  const { isOpen, list, isEnquireOpen } = useSelector(shortlistSelector);
  const handleRemove = (id) => {
    if (list.length > 1) {
      dispatch(removeShortlist(id));
    } else {
      dispatch(closeShortlist());
      setTimeout(() => dispatch(removeShortlist(id)), 180);
    }
  };
  const handleClear = () => {
    dispatch(closeShortlist());
    setTimeout(() => dispatch(clearShortlist()), 180);
  };
  const handleClose = () => dispatch(closeEnquire());

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={isEnquireOpen}
        fullWidth
        maxWidth="xs"
        style={{ zIndex: theme.zIndex.modal + 1 }}
        fullScreen={isMobile}
      >
        <DialogTitle>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>
        <Formik
          initialValues={{
            name: '',
            email: '',
            contact: '',
            company: '',
            requirement: '',
            recaptcha: '',
          }}
          onSubmit={({
            name,
            email,
            contact,
            company,
            requirement,
          }, { resetForm }) => dispatch(submitEnquiry({
            name,
            email,
            contact,
            company,
            requirement,
            products: list.map(({ name: productName }) => productName),
          })).then(() => resetForm()).then(handleClose)}
        >
          {({ isSubmitting }) => (
            <Container>
              <Typography align="center" className={classes.enquireTitle}>Checkout All Products</Typography>
              <Form className={classes.enquireContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      className={classes.enquireField}
                      name="name"
                      label="Name*"
                      fullWidth
                      validate={(value) => !value && 'Required'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      className={classes.enquireField}
                      name="email"
                      label="Email*"
                      fullWidth
                      validate={
                      (value) => (!value && 'Required')
                      || (!value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/) && 'Invalid Email')
                    }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      className={classes.enquireField}
                      name="contact"
                      label="Contact Number*"
                      fullWidth
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                      }}
                      validate={(value) => (!value && 'Required') || (!value.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) && 'Invalid Contact Number')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      className={classes.enquireField}
                      name="company"
                      label="Company"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      className={classes.enquireField}
                      name="requirement"
                      label="Enquiry"
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="recaptcha"
                      validate={(value) => !value && 'Required'}
                      component={Recaptcha}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <center>
                      <Button
                        className={classes.enquireSubmit}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        disableElevation
                        color="primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Submitting' : 'Submit'}
                      </Button>
                    </center>
                  </Grid>
                </Grid>
              </Form>
            </Container>
          )}
        </Formik>
      </Dialog>
      <Zoom
        in={list.length > 0}
        onEnter={() => setEnterAnimation(true)}
        onEntered={() => setEnterAnimation(false)}
      >
        <Box className={classes.toggleContainer} visibility={(isOpen && !enterAnimation) || !showFab ? 'hidden' : 'visible'}>
          <Fab
            onClick={() => dispatch(openShortlist())}
            className={classes.toggleButton}
          >
            <ShoppingCart />
          </Fab>
        </Box>
      </Zoom>
      <SwipeableDrawer
        className={classes.drawer}
        anchor="bottom"
        open={isOpen && !enterAnimation}
        onOpen={() => dispatch(openShortlist())}
        onClose={() => dispatch(closeShortlist())}
        disableSwipeToOpen={!list.length}
        SlideProps={{
          onExiting: () => { timeoutRef.current = setTimeout(() => setShowFab(true), 120); },
          onEnter: () => { clearTimeout(timeoutRef.current); setShowFab(false); },
        }}
      >
        <Toolbar variant="dense" className={classes.toolbar}>
          <Typography align="center" className={classes.title}>Cart</Typography>
          <Box position="absolute" right={0}>
            <Grid container>
              <Grid item>
                <IconButton color="inherit" onClick={() => dispatch(openEnquire())}>
                  <ShoppingCartCheckout fill="currentColor" style={{ height: 20, width: 20 }} />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton color="inherit" onClick={handleClear}>
                  <Delete />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  color="inherit"
                  onClick={() => {
                    const content = list.map(({ id, name }) => `${name}:\n${getProductUrl(id)}`).join('\n\n');
                    window.location = `mailto:?subject=INVAS Products&body=${encodeURIComponent(content)}`;
                  }}
                >
                  <Email />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
        <Box className={classes.drawerToggleContainer} visibility={showFab ? 'hidden' : 'visible'}>
          <Fab
            onClick={() => dispatch(closeShortlist())}
            className={classes.toggleButton}
          >
            <ShoppingCart />
          </Fab>
        </Box>
        <Box className={classes.container}>
          <Grid container spacing={1}>
            {list.map((product) => (
              <Grid key={product.id} item sm={4} xs={6} style={{ position: 'relative' }}>
                <IconButton className={classes.removeButton} color="secondary" onClick={() => handleRemove(product.id)}><HighlightOff /></IconButton>
                <ProductCard
                  minimal
                  data={product}
                  onClick={() => dispatch(closeShortlist())}
                  elevation={0}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </SwipeableDrawer>
    </>
  );
});

export default Shortlist;
