import { configureStore } from '@reduxjs/toolkit';
import drawer from './drawer';
import filter from './filter';
import product from './product';
import contact from './contact';
import otdrFinder from './otdrFinder';
import shortlist from './shortlist';
import headerMenu from './headerMenu';
import news from './news';
import careers from './careers';
import alert from './alert';
import user from './user';
import featuredVideo from './featuredVideo';
import offices from './offices';
import teamMembers from './teamMembers';

const store = configureStore({
  reducer: {
    drawer,
    filter,
    product,
    contact,
    otdrFinder,
    shortlist,
    headerMenu,
    news,
    careers,
    alert,
    user,
    featuredVideo,
    offices,
    teamMembers,
  },
});

export default store;
