import { toggleContact } from 'slices/contact';

export const DRAWER_MENU = [{
  label: 'Home',
  to: '/',
}, {
  label: 'Products',
  to: '/products',
  isProducts: true,
}, {
  label: 'Services',
  to: '/services',
}, {
  label: 'Support',
  to: '/support',
}, {
  label: 'About Us',
  to: '/about/who-we-are',
  children: [{
    label: 'WHO WE ARE',
    to: '/about/who-we-are',
  }, {
    label: 'EXECUTIVE TEAM',
    to: '/about/executive-team',
  }],
}, {
  label: 'Contact Us',
  isContact: true,
}];

export const PARTNER_LOGOS = [
  'EXFO-white.png',
  'Sumitomo-white.png',
  'keysight.png',
  'oscilloquartz.png',
  'iBwave-white.png',
  'infovista.png',
];

export const WHATSAPP_THEME = {
  primary: '#25D366',
  secondary: '#075E54',
};

export const FOOTER_LINKS = [{
  title: 'Customers',
  links: [
    { label: 'Products', to: '/products' },
    { label: 'Support', to: '/support' },
    { label: 'Services', to: '/services' },
    { label: 'Clients', to: '/clients' },
  ],
}, {
  title: 'Contact',
  links: [
    { label: 'Corporate', to: '/corporate' },
    { label: 'Contact Us', dispatchFunc: toggleContact },
    { label: 'Careers', to: '/careers' },
  ],
}, {
  title: 'About',
  to: '/about/who-we-are',
  links: [
    { label: 'Who we are', to: '/about/who-we-are' },
    { label: 'Executive Team', to: '/about/executive-team' },
    { label: 'News', to: '/news' },
  ],
}];

export const CLIENTS = [
  'ACT.png',
  'Airtel.png',
  'alcatel.png',
  'Banglalink.png',
  'BharatElectronics.png',
  'CDOT.png',
  'Dialog.png',
  'Ericsson.png',
  'fibcom.png',
  'GAIL.png',
  'GTL.png',
  'Hathway.png',
  'HFCL.png',
  'Huawei.png',
  'Idea.png',
  'IITMadras.png',
  'IndianDefense.jpg',
  'IndianOil.png',
  'infinera.png',
  'Jio.png',
  'kisspng-bharat-sanchar-nigam-limited-telecommunication-bha-telecommunication-5acd83bf928900.3263688815234180476002.png',
  'motorola.png',
  'MTNL.png',
  'NCell.png',
  'NepalTelecom.png',
  'Nokia.png',
  'NokiaSiemens.png',
  'NTPC.png',
  'PowergridCorporationOfIndia.png',
  'RailTel.png',
  'Siemens.png',
  'Singtel.png',
  'siti.jpg',
  'SrilankaTelecom.png',
  'sterlite.png',
  'Tata Communications.png',
  'TataSky.png',
  'TataTeleservices.png',
  'tcil.png',
  'TejasNetworks.png',
  'VNL.png',
  'vodafone.png',
];
