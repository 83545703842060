/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Container,
  Typography,
  CircularProgress,
  Button,
  Hidden,
  withStyles,
  IconButton,
} from '@material-ui/core';
import {
  Share,
  SystemUpdateAlt,
  ShoppingCart,
} from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { productSelector } from 'slices/product';
import Breadcrumbs from 'App/components/Breadcrumbs';
import { shortlistSelector } from 'slices/shortlist';
import { shortlistProduct, getProductById } from 'App/actions';
import { ReactComponent as ShoppingCartAdd } from 'App/components/Shortlist/icons/cart-add.svg';
import MetaTags from 'react-meta-tags';
import DetailTabs from './components/DetailTabs';
import ImageCarousel from './components/ImageCarousel';
import ImageSwipeable from './components/ImageSwipeable';
import SharePopover from './components/SharePopover';

const ProductDetails = withStyles((theme) => ({
  loaderContainer: {
    position: 'relative',
    height: 'calc(100vh - 125px)',
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 165px)',
    },
  },
  loader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
  },
  title: {
    fontSize: 48,
    [theme.breakpoints.down('xs')]: {
      fontSize: 30,
    },
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontWeight: theme.typography.fontWeightLight,
    marginBottom: theme.spacing(3),
  },
  subTitle: {
    fontSize: 30,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.2,
  },
  link: {
    color: theme.palette.primary.main,
    fontStyle: 'italic',
  },
}))(({ classes, match: { params: { productId: _productId } } }) => {
  const productId = decodeURIComponent(_productId);
  const product = useSelector(productSelector)[productId];
  const { list } = useSelector(shortlistSelector);
  const isShortlisted = list.findIndex(({ id: shortlistId }) => productId === shortlistId) > -1;
  const dispatch = useDispatch();
  const breadcrumbs = [{
    label: 'Home',
    to: '/',
  }, {
    label: 'Products',
    to: '/products',
  }, ...(product ? [{
    label: product.id,
  }] : [])];
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    dispatch(getProductById(productId));
  }, [dispatch, productId]);

  return (
    <>
      <MetaTags>
        <meta name="description" content="Our Products are EXFO OTDR, Power Meters, Light Sources, Multimeters, FIP, Spectrum Analyzer, Splicing Machines, GNSS receivers, Network Design & Testing." />
      </MetaTags>
      <Container>
        <Breadcrumbs path={breadcrumbs} />
        {
        !product ? (
          <div className={classes.loaderContainer}>
            <CircularProgress className={classes.loader} />
          </div>
        ) : (
          <>
            <Grid container>
              <Grid xs={12} sm={4} item>
                <Typography color="primary" className={classes.title}>{product.name}</Typography>
                <Typography className={classes.subTitle} color="textSecondary">
                  {product.shortDesc}
                </Typography>
                <br />
                <br />
                <Hidden xsDown>
                  <Grid container direction="column">
                    <Grid item>
                      <Button
                        color="primary"
                        size="large"
                        startIcon={<Share />}
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                      >
                        Share
                      </Button>
                      <SharePopover
                        anchorEl={anchorEl}
                        handleClose={() => setAnchorEl(null)}
                        product={product}
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        size="large"
                        startIcon={isShortlisted
                          ? <ShoppingCart />
                          : <ShoppingCartAdd style={{ fill: 'currentColor' }} />}
                        onClick={() => dispatch(shortlistProduct(product))}
                      >
                        {isShortlisted ? 'Added to Cart' : 'Add to cart'}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        size="large"
                        startIcon={<SystemUpdateAlt />}
                        onClick={() => window.open(product.spec)}
                      >
                        Spec Sheet
                      </Button>
                    </Grid>
                  </Grid>
                </Hidden>
              </Grid>
              <Grid xs={12} sm={8} item>
                <Hidden xsDown>
                  <ImageCarousel imageArr={product.images} altArr={product.alt} />
                </Hidden>
                <Hidden smUp>
                  <ImageSwipeable imageArr={product.images} altArr={product.alt} />
                </Hidden>
              </Grid>
            </Grid>
            <Hidden smUp>
              <Grid container justify="flex-end">
                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <Share />
                  </IconButton>
                  <SharePopover
                    anchorEl={anchorEl}
                    handleClose={() => setAnchorEl(null)}
                    product={product}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={() => dispatch(shortlistProduct(product))}
                  >
                    {isShortlisted ? <ShoppingCart /> : <ShoppingCartAdd fill="currentColor" />}
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    color="primary"
                    onClick={() => window.open(product.spec)}
                  >
                    <SystemUpdateAlt />
                  </IconButton>
                </Grid>
              </Grid>
            </Hidden>
            <br />
            <br />
            <DetailTabs product={product} />
          </>
        )
      }
        <br />
        <br />
        <br />
      </Container>
    </>
  );
});

export default ProductDetails;
