/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Container,
  Button,
  Box,
  withStyles,
  Collapse,
  Hidden,
} from '@material-ui/core';
import { Form, Formik, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import Recaptcha from 'App/components/Recaptcha';
import Link from 'App/components/Link';
import { submitEnquiry } from 'App/actions';
import { useDispatch } from 'react-redux';
import { base64Decode } from 'utils';
import Markdown from 'App/components/Markdown';

const DetailTabs = withStyles((theme) => ({
  title: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    background: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  feature: {
    fontSize: 20,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  details: {
    '&, & .MuiTypography-root': {
      fontSize: 20,
      lineHeight: 1.6,
      [theme.breakpoints.down('xs')]: {
        fontSize: 16,
      },
    },
  },
  enquireContainer: {
    background: theme.palette.grey[100],
    borderRadius: 10,
    padding: theme.spacing(2),
  },
  enquireTitle: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontWeight: theme.typography.fontWeightLight,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(6),
    },
  },
  enquireField: {
    '& input,textarea': {
      color: theme.palette.primary.main,
    },
    '& .MuiInput-underline:before': {
      borderColor: theme.palette.primary.main,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderColor: theme.palette.primary.main,
    },
  },
  enquireSubmit: {
    marginTop: theme.spacing(1),
  },
  readMore: {
    color: theme.palette.primary.main,
    fontStyle: 'italic',
  },
  readMoreOverlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 60,
    backgroundImage: `linear-gradient(${theme.palette.common.white}0, ${theme.palette.common.white} 80%)`,
  },
}))(({ classes, product }) => {
  const DETAILS_HEIGHT = 200;
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        contact: '',
        company: '',
        requirement: '',
        recaptcha: '',
      }}
      onSubmit={({
        name, email, contact, company, requirement,
      }, { resetForm }) => dispatch(submitEnquiry({
        name, email, contact, company, requirement, products: [product.name],
      })).then(() => resetForm())}
    >
      {({ isSubmitting }) => {
        const featureGridItem = (
          <Grid item sm={6} xs={12}>
            <Typography className={classes.title}>Features</Typography>
            <Box padding={2} paddingTop={1}>
              {product.features.map((feature) => (
                <Typography key={feature} className={classes.feature}>
                  &bull;&nbsp;
                  {feature}
                </Typography>
              ))}
            </Box>
          </Grid>
        );
        const detailsGridItem = (
          <Grid item xs={12}>
            <Box pt={4} />
            <Typography className={classes.title}>Details</Typography>
            <Box padding={2}>
              <Collapse collapsedHeight={DETAILS_HEIGHT} in={showMore} style={{ position: 'relative' }}>
                <Box ref={(ref) => ref && ref.offsetHeight <= DETAILS_HEIGHT && setShowMore(true)}>
                  <Markdown className={classes.details}>{base64Decode(product.longDesc)}</Markdown>
                  {!showMore && <Box className={classes.readMoreOverlay} />}
                </Box>
              </Collapse>
              {!showMore && (
              <Link onClick={() => setShowMore(true)} className={classes.readMore}>
                Read More
              </Link>
              )}
            </Box>
          </Grid>
        );
        const enquireGridItem = (
          <Grid item sm={5} xs={12}>
            <Container disableGutters>
              <Typography align="center" className={classes.enquireTitle}>Enquire About This Product</Typography>
              <Form className={classes.enquireContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      className={classes.enquireField}
                      name="name"
                      label="Name*"
                      fullWidth
                      validate={(value) => !value && 'Required'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      className={classes.enquireField}
                      name="email"
                      label="Email*"
                      fullWidth
                      validate={
                    (value) => (!value && 'Required')
                    || (!value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/) && 'Invalid Email')
                  }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      className={classes.enquireField}
                      name="contact"
                      label="Contact Number*"
                      fullWidth
                      inputProps={{
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                      }}
                      validate={(value) => (!value && 'Required') || (!value.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) && 'Invalid Contact Number')}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      component={TextField}
                      className={classes.enquireField}
                      name="company"
                      label="Company"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      className={classes.enquireField}
                      name="requirement"
                      label="Enquiry"
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="recaptcha"
                      validate={(value) => !value && 'Required'}
                      component={Recaptcha}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <center>
                      <Button
                        className={classes.enquireSubmit}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="outlined"
                        disableElevation
                        color="primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? 'Submitting' : 'Submit'}
                      </Button>
                    </center>
                  </Grid>
                </Grid>
              </Form>
            </Container>
          </Grid>
        );
        return (
          <Grid container justify="space-between">
            <Hidden xsDown>
              {featureGridItem}
              {enquireGridItem}
              {detailsGridItem}
            </Hidden>
            <Hidden smUp>
              {featureGridItem}
              {detailsGridItem}
              {enquireGridItem}
            </Hidden>
          </Grid>
        );
      }}
    </Formik>
  );
});

export default DetailTabs;
