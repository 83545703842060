import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { string, bool } from 'prop-types';
import {
  Grid,
  Box,
  Typography,
  withStyles,
} from '@material-ui/core';
import { numberWithCommas } from 'utils';

const DisplayNumber = withStyles((theme) => ({
  container: {
    overflow: 'hidden',
  },
  box: {
    height: theme.spacing(0.4),
    width: theme.spacing(8),
    backgroundImage: `linear-gradient(to right, #47a5e3, ${theme.palette.primary.light})`,
    borderRadius: 2,
  },
  boxReverse: {
    height: theme.spacing(0.4),
    width: theme.spacing(8),
    backgroundImage: `linear-gradient(to left, #47a5e3, ${theme.palette.primary.light})`,
    borderRadius: 2,
  },
  title: {
    fontSize: 80,
    [theme.breakpoints.up('md')]: {
      fontSize: 110,
    },
    fontWeight: 100,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    color: theme.palette.primary.light,
    marginTop: theme.spacing(-2),
    marginLeft: theme.spacing(6),
    background: `linear-gradient(to left, #47a5e3, ${theme.palette.primary.light})`,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  titleReverse: {
    fontSize: 80,
    [theme.breakpoints.up('md')]: {
      fontSize: 110,
    },
    fontWeight: 100,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    color: theme.palette.primary.light,
    marginTop: theme.spacing(-2),
    marginRight: theme.spacing(6),
    background: `linear-gradient(to left, #47a5e3, ${theme.palette.primary.light})`,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
  },
  description: {
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    marginTop: theme.spacing(-3),
    marginLeft: theme.spacing(6),
    lineHeight: 1,
    fontWeight: theme.typography.fontWeightLight,
    width: theme.spacing(20),
    color: theme.palette.text.secondary,
  },
  descriptionReverse: {
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    marginRight: theme.spacing(7),
    marginTop: theme.spacing(1),
    lineHeight: 1,
    fontWeight: theme.typography.fontWeightLight,
    width: theme.spacing(25),
    color: theme.palette.text.secondary,
  },
}))(({
  title,
  description,
  postfix,
  middle,
  reverse,
  classes,
}) => {
  const id = title;
  const isVisible = useRef(false);
  const countDuration = 1000;
  const finalNumber = parseInt(title, 10);
  const startTime = useRef();
  const timer = useRef();
  const [number, setNumber] = useState(0);
  const isShowing = useRef(false);
  const resetTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
    }
  };
  const startTimer = useCallback(() => {
    isShowing.current = !isShowing.current;
    resetTimer();
    startTime.current = new Date();
    timer.current = setInterval(() => {
      let newNumber = parseInt(
        ((new Date() - startTime.current) / countDuration) * finalNumber,
        10,
      );

      if (newNumber >= finalNumber) {
        newNumber = finalNumber;
        resetTimer();
      }
      setNumber(isShowing.current ? newNumber : finalNumber - newNumber);
    });
  }, [finalNumber]);

  useEffect(() => {
    const handleScroll = () => {
      const newIsVisible = document.querySelector(`*[data-aos-id="${id}"]`).getBoundingClientRect().top <= window.innerHeight;

      if (isVisible.current !== newIsVisible) {
        isVisible.current = newIsVisible;
        startTimer();
      }
    };
    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, [id, startTimer]);

  return !reverse ? (
    <>
      <Grid container wrap="nowrap" direction="column" alignItems={middle ? 'flex-end' : 'flex-start'} className={classes.container}>
        <Grid item data-aos={middle ? 'fade-left' : 'fade-right'}>
          <Box className={classes.box} />
        </Grid>
        <Grid item data-aos="zoom-in" data-aos-id={id}>
          <Typography className={classes.title}>
            {numberWithCommas(number)}
            {postfix}
          </Typography>
        </Grid>
      </Grid>
      <Typography data-aos="fade-right" className={classes.description}>{description}</Typography>
    </>
  ) : (
    <Grid container direction="column" alignItems="flex-end" className={classes.container}>
      <Grid item>
        <Box className={classes.boxReverse} data-aos="fade-left" />
      </Grid>
      <Grid item>
        <Typography className={classes.titleReverse} data-aos="zoom-in" data-aos-id={id}>
          {numberWithCommas(number)}
          {postfix}
        </Typography>
      </Grid>
      <Grid item style={{ marginTop: -32 }}>
        <Typography data-aos="fade-left" className={classes.descriptionReverse} align="right">{description}</Typography>
      </Grid>
    </Grid>
  );
});

DisplayNumber.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
  reverse: bool,
};

DisplayNumber.defaultProps = {
  reverse: false,
};

export default DisplayNumber;
