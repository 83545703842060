import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Grid,
  Button,
  Paper,
  Collapse,
  withStyles,
  Divider,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { setHeaderMenuOpen, headerMenuOpenSelector } from 'slices/headerMenu';
import { getAllFilters } from 'App/actions';
import { isFetchingFilterSelector, filterSelector } from 'slices/filter';

const HeaderMenu = withStyles((theme) => ({
  item: {
    color: theme.palette.text.secondary,
    fontSize: 16,
  },
  primaryItem: {
    color: theme.palette.common.white,
    fontSize: 16,
  },
  menuContainer: {
    position: 'absolute',
    top: theme.spacing(8),
    '& .MuiPaper-root': {
      borderRadius: 0,
    },
  },
  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
  },
  subMenuContainer: {
    background: theme.palette.grey[200],
    width: 300,
  },
  subMenuItemContainer: {
    '&.MuiListItem-button:hover': {
      background: theme.palette.common.white,
      '& .MuiListItemText-primary': {
        color: theme.palette.primary.main,
      },
    },
  },
}))(({ items, toggleContactUs, classes }) => {
  const history = useHistory();
  const anchorEl = useRef(null);
  const oemFilter = useSelector(filterSelector).find(({ name }) => name === 'OEM');
  const isFetchingFilter = useSelector(isFetchingFilterSelector);
  const dispatch = useDispatch();
  const headerMenuOpen = useSelector(headerMenuOpenSelector);
  const handleClick = ({ to, isContact }) => {
    dispatch(setHeaderMenuOpen(null));
    if (isContact) {
      toggleContactUs();
    } else if (to) {
      history.push(to);
    }
  };
  const handleFilterClick = (oem) => {
    history.push({
      pathname: '/products',
      search: `?OEM=${encodeURIComponent(oem)}`,
    });
    dispatch(setHeaderMenuOpen(null));
  };

  useEffect(() => { dispatch(getAllFilters()); }, [dispatch]);

  return (
    <Grid container spacing={3} justify="flex-end" ref={anchorEl} style={{ marginTop: 0 }}>
      {items.map((item) => {
        let renderItem;

        if (item.isContact) {
          renderItem = (
            <Button
              className={classes.primaryItem}
              color="primary"
              variant="contained"
              disableElevation
              onClick={() => handleClick(item)}
              size="large"
            >
              {item.label}
            </Button>
          );
        } else if (item.isProducts) {
          renderItem = (
            <Box
              onMouseEnter={() => dispatch(setHeaderMenuOpen(item.label))}
              onMouseLeave={() => dispatch(setHeaderMenuOpen(null))}
            >
              <Button
                className={classes.item}
                onClick={() => handleClick(item)}
                size="large"
              >
                {item.label}
              </Button>
              <Collapse
                in={headerMenuOpen === item.label}
                className={classes.menuContainer}
              >
                <Box pt={4} />
                <Paper>
                  <Divider />
                  <Box minHeight={260} position="relative" className={classes.subMenuContainer}>
                    {isFetchingFilter
                      ? <CircularProgress className={classes.loader} />
                      : (
                        <List>
                          {Object.keys(oemFilter.options).map((oem) => (
                            <ListItem
                              button
                              onClick={() => handleFilterClick(oem)}
                              key={oem}
                              className={classes.subMenuItemContainer}
                            >
                              <ListItemText primary={oem} />
                            </ListItem>
                          ))}
                        </List>
                      )}
                  </Box>
                </Paper>
              </Collapse>
            </Box>
          );
        } else if (item.children) {
          renderItem = (
            <Box
              onMouseEnter={() => dispatch(setHeaderMenuOpen(item.label))}
              onMouseLeave={() => dispatch(setHeaderMenuOpen(null))}
            >
              <Button
                className={classes.item}
                onClick={() => handleClick(item)}
                size="large"
              >
                {item.label}
              </Button>
              <Collapse
                in={headerMenuOpen === item.label}
                className={classes.menuContainer}
              >
                <Box pt={4} />
                <Paper>
                  <Divider />
                  <Box className={classes.subMenuContainer}>
                    <List>
                      {item.children.map((childItem) => (
                        <ListItem
                          button
                          onClick={() => handleClick(childItem)}
                          key={childItem.label}
                          className={classes.subMenuItemContainer}
                        >
                          <ListItemText primary={childItem.label} />
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Paper>
              </Collapse>
            </Box>
          );
        } else {
          renderItem = (
            <Button
              className={classes.item}
              onClick={() => handleClick(item)}
              size="large"
            >
              {item.label}
            </Button>
          );
        }

        return (
          <Grid item key={item.label}>
            {renderItem}
          </Grid>
        );
      })}
    </Grid>
  );
});

export default HeaderMenu;
