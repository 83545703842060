/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useEffect,
  Fragment,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Container,
  Typography,
  Hidden,
  Button,
  withStyles,
  Divider,
  Paper,
  CircularProgress,
  isWidthDown,
  withWidth,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { toggleOtdrFinder } from 'slices/otdrFinder';
import { getFeaturedProducts, getFeaturedNews } from 'App/actions';
import SectionTitle from 'App/components/SectionTitle';
import ProductCard from 'App/components/ProductCard';
import Link from 'App/components/Link';
import { isFetchingFeaturedProductSelector, featuredProductSelector } from 'slices/product';
import { isFetchingFeaturedNewsSelector, featuredNewsSelector } from 'slices/news';
import MetaTags from 'react-meta-tags';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectCube } from 'swiper';
import DisplayNumber from './components/DisplayNumber';
import VideoCard from './components/VideoCard';
import NewsCard from './components/NewsCard';
import { ReactComponent as MaintenanceIcon } from './icons/wpf_maintenance.svg';
import { ReactComponent as NotepadIcon } from './icons/bx_bxs-notepad.svg';
import { ReactComponent as NetworkIcon } from './icons/el_network.svg';
import { ReactComponent as TowerIcon } from './icons/maki_communications-tower-15.svg';
import { ReactComponent as OtdrFinderIcon } from './icons/otdr-finder.svg';

const Home = withStyles((theme) => ({
  container: {
    overflow: 'hidden',
  },
  title: {
    color: theme.palette.grey[500],
    fontWeight: theme.typography.fontWeightBold,
    lineHeight: 1.2,
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(4),
      fontSize: 30,
    },
  },
  mainImg: {
    position: 'relative',
    backgroundColor: theme.palette.secondary.dark,
    '& video': {
      objectFit: 'cover',
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      height: 'calc(100vh - 144px)',
      '& video': {
        height: 'calc(100vh - 144px)',
      },
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: 500,
      height: 'calc(100vh - 56px)',
      '& video': {
        maxHeight: 500,
        height: 'calc(100vh - 56px)',
      },
    },
  },
  goldCertifiedImgSumitomo: {
    width: 180,
    [theme.breakpoints.down('sm')]: {
      width: 142,
    },
  },
  goldCertifiedImgExfo: {
    width: 148,
    [theme.breakpoints.up('md')]: {
      width: 230,
      marginTop: theme.spacing(-1),
    },
  },
  shardsOverlay: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    opacity: 0.5,
    width: '100vw',
    [theme.breakpoints.down('sm')]: {
      width: '150vw',
    },
  },
  xShardsOverlay: {
    position: 'absolute',
    right: 0,
    top: '100%',
    width: '100vw',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      width: '150vw',
    },
  },
  mainTextContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    color: theme.palette.common.white,
    paddingTop: 'calc((100vh - 368px - 144px) / 2)',
    paddingLeft: theme.spacing(4),
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(12),
      paddingLeft: theme.spacing(2),
    },
  },
  mainText: {
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontWeight: theme.typography.fontWeightBold,
    fontStyle: 'italic',
    fontSize: 90,
    lineHeight: 1,
    [theme.breakpoints.down('md')]: {
      fontSize: 74,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 40,
    },
  },
  knowMore: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
    },
  },
  blueSection: {
    background: theme.palette.secondary.dark,
    position: 'relative',
    paddingTop: 116,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 70,
    },
  },
  shardsBg: {
    position: 'absolute',
    top: -800,
    left: 0,
    right: 0,
    height: 800,
    backgroundImage: `url("${process.env.PUBLIC_URL}/images/shardsBg.png")`,
    backgroundSize: 'auto 464px',
    backgroundPosition: 'right top',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      height: 250,
      top: -250,
      backgroundSize: 'auto 250px',
    },
  },
  shardsBgOverlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundImage: `linear-gradient(${theme.palette.secondary.dark}00 19%, ${theme.palette.secondary.dark}40 35%, ${theme.palette.secondary.dark} 464px)`,
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `linear-gradient(${theme.palette.secondary.dark}00 19%, ${theme.palette.secondary.dark}40 35%, ${theme.palette.secondary.dark} 250px)`,
    },
  },
  blackSection: {
    background: theme.palette.common.black,
  },
  servicesHeader: {
    fontSize: 90,
    fontWeight: 100,
    lineHeight: 1.4,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    color: theme.palette.primary.light,
    background: `linear-gradient(to right, #47a5e3, ${theme.palette.primary.light})`,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down('md')]: {
      fontSize: 70,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 50,
      marginLeft: theme.spacing(3),
    },
  },
  servicesSubheader: {
    color: theme.palette.common.white,
    fontSize: 24,
    fontWeight: theme.typography.fontWeightLight,
    marginTop: theme.spacing(2.5),
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  servicesHeaderUnderline: {
    height: 3,
    width: theme.spacing(13),
    background: `linear-gradient(to right, #47a5e3, ${theme.palette.primary.light})`,
    marginRight: 370,
    borderRadius: 2,
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(7),
    },
  },
  servicesContainer: {
    paddingBottom: theme.spacing(20),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(10),
    },
  },
  servicesIcon: {
    width: 70,
    height: 70,
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      height: 44,
      width: 44,
      marginRight: 12,
      marginBottom: 8,
    },
  },
  servicesTitle: {
    fontSize: 28,
    color: theme.palette.common.white,
    lineHeight: 1.3,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      maxWidth: '70%',
    },
  },
  servicesDescription: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.3,
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
  },
  serviceItemContainer: {
    paddingTop: 64,
    [theme.breakpoints.up('md')]: {
      paddingRight: 100,
      paddingTop: 100,
    },
  },
  relatedContentHeader: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightLight,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontSize: 16,
    color: theme.palette.common.white,
    letterSpacing: theme.spacing(1.4),
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
      letterSpacing: theme.spacing(3),
    },
    marginBottom: theme.spacing(5),
  },
  sectionDivider: {
    marginTop: theme.spacing(2),
  },
  shortcutLink: {
    fontWeight: theme.typography.fontWeightBold,
    fontStyle: 'italic',
    color: theme.palette.primary.main,
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
  fpSwiper: {
    paddingRight: theme.spacing(3),
    marginRight: -theme.spacing(3),
    height: '100%',
  },
  fpSwiperSlide: {
    boxSizing: 'border-box',
  },
  otdrFinder: {
    zIndex: 1,
    cursor: 'pointer',
    position: 'relative',
    background: 'linear-gradient(33.24deg, #1A6DFF 0%, #69B2E6 107.35%)',
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      maxWidth: 240,
    },
  },
  otdrFinderContent: {
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontWeight: 200,
    lineHeight: 1,
    letterSpacing: 0.15,
    color: '#0F345E',
    maxWidth: 200,
    [theme.breakpoints.down('sm')]: {
      fontSize: 28,
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 35,
      marginBottom: theme.spacing(12),
    },
  },
  otdrFinderIcon: {
    position: 'absolute',
    bottom: -1,
    height: theme.spacing(10),
    right: 0,
    [theme.breakpoints.down('xs')]: {
      height: theme.spacing(8),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      height: 70,
    },
    [theme.breakpoints.up('md')]: {
      height: 76,
    },
  },
}))(({ classes, width }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const videoRef = useRef();
  const swiperRef = useRef();
  const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'));
  const featuredNews = useSelector(featuredNewsSelector);
  const featuredProducts = useSelector(featuredProductSelector);
  const isFetchingFeaturedNews = useSelector(isFetchingFeaturedNewsSelector);
  const isFetchingFeaturedProducts = useSelector(isFetchingFeaturedProductSelector);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => { dispatch(getFeaturedProducts()); }, [dispatch]);
  useEffect(() => { dispatch(getFeaturedNews()); }, [dispatch]);
  useEffect(() => {
    videoRef.current.onplay = () => setIsVideoPlaying(true);
  }, []);

  return (
    <>
      <MetaTags>
        <meta name="description" content="Since 2006, we deal with Optical Time Domain Reflectometer (OTDR), splicing machines, PON products, optical transceivers, Data Centers, and FTTx in India." />
      </MetaTags>
      <div className={classes.mainImg}>
        {isVideoPlaying && (
          <div className={classes.mainTextContainer}>
            <Container disableGutters>
              <Box data-aos="fade-right">
                <Typography className={classes.mainText}>Complete</Typography>
              </Box>
              <Box data-aos="fade-right" data-aos-delay="250">
                <Typography className={classes.mainText}>Telecom</Typography>
              </Box>
              <Box data-aos="fade-right" data-aos-delay="500">
                <Typography className={classes.mainText}>Solution</Typography>
              </Box>
              <Box data-aos="fade-in" data-aos-delay="600" mt={2}>
                <Button
                  size={isWidthDown('sm', width) ? 'medium' : 'large'}
                  variant="outlined"
                  color="inherit"
                  className={classes.knowMore}
                  component={RouterLink}
                  to="/about/who-we-are"
                >
                  Know More
                </Button>
              </Box>
            </Container>
          </div>
        )}
        <img src={`${process.env.PUBLIC_URL}/images/shardsBg.png`} className={classes.shardsOverlay} alt="Shards" />
        <img src={`${process.env.PUBLIC_URL}/images/xShardsBg.png`} className={classes.xShardsOverlay} alt="Shards" />
        <video autoPlay muted loop playsInline ref={videoRef}>
          <Hidden xsDown>
            <source src={`${process.env.PUBLIC_URL}/videos/invas.mp4`} type="video/mp4" />
            <source src={`${process.env.PUBLIC_URL}/videos/invas.webm`} type="video/webm" />
            <source src={`${process.env.PUBLIC_URL}/videos/invas.ogv`} type="video/ogg" />
          </Hidden>
          <Hidden smUp>
            <source src={`${process.env.PUBLIC_URL}/videos/invas-mobile.mp4`} type="video/mp4" />
            <source src={`${process.env.PUBLIC_URL}/videos/invas.webm`} type="video/webm" />
            <source src={`${process.env.PUBLIC_URL}/videos/invas.ogv`} type="video/ogg" />
          </Hidden>
        </video>
      </div>
      <Container disableGutters>
        <Typography className={classes.title} variant="h6" align="center">
          Transforming Telecommunication Infrastructure through Innovation and Expertise.
        </Typography>
      </Container>
      <Container disableGutters className={classes.container} maxWidth="md">
        <Hidden mdUp>
          <DisplayNumber title="35" postfix="+" description="Type Approvals Received" />
          <br />
          <br />
          <br />
          <DisplayNumber title="2500" postfix="+" description="Customers Served" reverse />
          <br />
          <br />
          <br />
          <DisplayNumber title="20000" postfix="+" description="TMIs Delivered" />
        </Hidden>
        <Hidden smDown>
          <Grid container justify="space-between">
            <Grid item xs={6}>
              <DisplayNumber title="35" postfix="+" description="Type Approvals Received" />
            </Grid>
            <Grid item xs>
              <Box position="relative" height={365}>
                <Box position="absolute" top={200} right={-100}>
                  <DisplayNumber title="2500" postfix="+" description="Customers Served" middle />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <DisplayNumber title="20000" postfix="+" description="TMIs Delivered" reverse />
            </Grid>
          </Grid>
        </Hidden>
        <br />
        <br />
        <br />
        <br />
      </Container>
      <Container style={{ position: 'relative', zIndex: 1 }}>
        <div style={{ padding: 24 }}>
          <Grid container justify="center" alignItems="flex-end" spacing={10}>
            <Grid item>
              <img
                alt="Gold partner Sumitomo"
                src={`${process.env.PUBLIC_URL}/images/gold-sumitomo.png`}
                className={classes.goldCertifiedImgSumitomo}
              />
            </Grid>
            <Grid item>
              <img
                alt="Gold partner EXFO"
                src={`${process.env.PUBLIC_URL}/images/gold-exfo.png`}
                className={classes.goldCertifiedImgExfo}
              />
            </Grid>
          </Grid>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <SectionTitle
          title="Featured Products"
          subtitle="View All >"
          to="/products"
        />
        <br />
        <br />
        <br />
        {isFetchingFeaturedProducts
          ? (
            <Box textAlign="center">
              <CircularProgress size={60} color="secondary" />
            </Box>
          ) : (
            <Grid container justify="center" spacing={2} style={{ overflow: 'hidden' }}>
              <Grid item xs={8} sm={7} md={9}>
                <Swiper
                  loop
                  slidesPerView={isDesktop ? 3 : 1}
                  modules={[Autoplay, EffectCube]}
                  autoplay={{ delay: 2000 }}
                  className={classes.fpSwiper}
                  spaceBetween={theme.spacing(2)}
                  effect={isDesktop ? 'slide' : 'cube'}
                  cubeEffect={{ shadow: false }}
                  onSwiper={(swiper) => { swiperRef.current = swiper; }}
                >
                  {featuredProducts.map((featuredProduct) => (
                    <SwiperSlide
                      key={`${featuredProduct.id}-1`}
                      className={classes.fpSwiperSlide}
                    >
                      <ProductCard
                        minimal
                        elevation={4}
                        data={featuredProduct}
                      />
                    </SwiperSlide>
                  ))}
                  {featuredProducts.map((featuredProduct) => (
                    <SwiperSlide
                      key={`${featuredProduct.id}-2`}
                      className={classes.fpSwiperSlide}
                    >
                      <ProductCard
                        minimal
                        elevation={4}
                        data={featuredProduct}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Grid>
              <Grid item xs={8} sm={7} md={3}>
                <Paper
                  elevation={4}
                  className={classes.otdrFinder}
                  onClick={() => dispatch(toggleOtdrFinder())}
                >
                  <Typography className={classes.otdrFinderContent}>
                    Find the perfect OTDR for your application &gt;
                  </Typography>
                  <OtdrFinderIcon className={classes.otdrFinderIcon} />
                </Paper>
              </Grid>
            </Grid>
          )}
        <br />
        <br />
        <br />
        <br />
      </Container>
      <div className={classes.blueSection}>
        <Box className={classes.shardsBg}>
          <Box className={classes.shardsBgOverlay} />
        </Box>
        <Container style={{ position: 'relative' }}>
          <Grid container alignItems="center">
            <Grid item md={6} sm={12} style={{ overflow: 'hidden' }}>
              <Box className={classes.servicesHeaderUnderline} data-aos="fade-right" />
              <Typography className={classes.servicesHeader}>
                Our Services
              </Typography>
            </Grid>
            <Grid item md={6} sm={12}>
              <Typography className={classes.servicesSubheader}>
                Accelerate Growth & Ensure your network
                performs Flawlessly with our INVAS Services.
              </Typography>
            </Grid>
          </Grid>
          <Grid container className={classes.servicesContainer}>
            <Grid item md={6} xs={12} className={classes.serviceItemContainer}>
              <Grid container>
                <Grid item>
                  <MaintenanceIcon className={classes.servicesIcon} />
                </Grid>
                <Grid item xs>
                  <Typography variant="h6" className={classes.servicesTitle}>
                    Product Engineering & Maintenance
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="body2" className={classes.servicesDescription}>
                In this age, reliability of Communication Network is one of the most
                important investment that can be made. INVAS offers a range of services
                to ensure your network is compliant, reliable and has a quick
                turnaround time.
              </Typography>
            </Grid>
            <Grid item md={6} xs={12} className={classes.serviceItemContainer}>
              <Grid container>
                <Grid item>
                  <NotepadIcon className={classes.servicesIcon} />
                </Grid>
                <Grid item xs>
                  <Typography variant="h6" className={classes.servicesTitle}>
                    Telecom Testing & Automation
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="body2" className={classes.servicesDescription}>
                Complete solution for Telecom Networks Testing. Test elements in isolation,
                to End-to-End. Control & User plane tests, Real World Traffic Simulation,
                Intra-LTE & inter-RAT tests. Conformance, Regression, Load testing & test
                automation to reduce burden.
              </Typography>
            </Grid>
            <Grid item md={6} xs={12} className={classes.serviceItemContainer}>
              <Grid container>
                <Grid item>
                  <NetworkIcon className={classes.servicesIcon} />
                </Grid>
                <Grid item xs>
                  <Typography variant="h6" className={classes.servicesTitle}>
                    Networking & IPSec
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="body2" className={classes.servicesDescription}>
                Proficient in developing security protocol stacks like IPSec, SRTP, EAP,
                and SSL/TLS etc. using variety of open source libraries and hardware
                accelerators. Integrating crypto libraries and accelerators available
                with OpenSSL, Strongswan, Xpressent.
              </Typography>
            </Grid>
            <Grid item md={6} xs={12} className={classes.serviceItemContainer}>
              <Grid container>
                <Grid item>
                  <TowerIcon className={classes.servicesIcon} />
                </Grid>
                <Grid item xs>
                  <Typography variant="h6" className={classes.servicesTitle}>
                    WebRTC for Telecoms
                  </Typography>
                </Grid>
              </Grid>
              <Typography variant="body2" className={classes.servicesDescription}>
                WebRTC GW, Media GW, Application server and Media Server bridges telecom
                VoIP and IMS networks with an open ecosystem of internet networks to
                deliver interoperable rich multimedia communication services to a
                single or multiple users.
              </Typography>
            </Grid>
          </Grid>
          <center>
            <Link to="/services" className={classes.shortcutLink}>{'View All Services >'}</Link>
          </center>
          <br />
          <br />
        </Container>
      </div>
      <div className={classes.blackSection}>
        <Container>
          <br />
          <br />
          <Typography variant="h5" align="center" className={classes.relatedContentHeader}>
            Related Content
          </Typography>
          <br />
          <br />
          <br />
          <SectionTitle
            title="Featured Video"
            subtitle="See All Videos >"
            href="https://www.youtube.com/channel/UCHvF9Y0eAWOjtYLkOEzF5zA"
            color="white"
          />
          <br />
          <br />
          <Container maxWidth="md">
            <VideoCard />
          </Container>
          <br />
          <br />
          <br />
          <br />
        </Container>
      </div>
      <Container>
        <br />
        <br />
        <SectionTitle
          secondary
          color="black"
          title="Featured News"
          subtitle="View All >"
          to="/news"
        />
        <Hidden mdUp>
          <Divider className={classes.sectionDivider} />
        </Hidden>
        <br />
        {
          isFetchingFeaturedNews
            ? <Box textAlign="center"><CircularProgress /></Box>
            : (
              <>
                <Hidden smDown>
                  <br />
                  <Grid container spacing={1}>
                    {featuredNews.slice(0, 3).map(({
                      id,
                      title,
                      abstract,
                      media,
                    }) => (
                      <Grid key={id} item xs={4}>
                        <NewsCard
                          id={id}
                          title={title}
                          content={abstract}
                          media={media}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Hidden>
                <Hidden mdUp>
                  {featuredNews.slice(0, 3).map(({
                    id,
                    title,
                    abstract,
                    media,
                  }) => (
                    <Fragment key={id}>
                      <NewsCard
                        id={id}
                        title={title}
                        content={abstract}
                        media={media}
                      />
                      <br />
                    </Fragment>
                  ))}
                </Hidden>
              </>
            )
          }
        <br />
        <br />
        <br />
      </Container>
    </>
  );
});
export default withWidth()(Home);
