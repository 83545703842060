import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  withStyles,
  Collapse,
  Divider,
  Link,
  isWidthDown,
  withWidth,
} from '@material-ui/core';
import { base64Decode, getDate } from 'utils';
import Markdown from 'App/components/Markdown';
import { useDispatch } from 'react-redux';
import { setApplyFormOpen } from 'slices/careers';


const JobItem = withStyles((theme) => ({
  jobContainer: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2, 2, 1, 2),
  },
  jobTitle: {
    color: theme.palette.primary.main,
    fontSize: 22,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  jobSubtitle: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 18,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  jobID: {
    fontSize: 22,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}))(({
  classes,
  width,
  job: {
    id,
    title,
    location,
    date,
    description,
    type,
  },
}) => {
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();

  return (
    <Box className={classes.jobContainer}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography className={classes.jobTitle}>{title}</Typography>
          <Typography className={classes.jobSubtitle}>
            Posted on:
            {' '}
            {getDate(parseInt(date, 10))}
            {' '}
            |
            {' '}
            {location}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.jobID}>
            Job ID:
            {id}
          </Typography>
        </Grid>
      </Grid>
      <Box pt={3} />
      <Grid container justify="space-between">
        <Grid item>
          <Typography className={classes.jobSubtitle}>
            Job Type:
            {' '}
            {type}
          </Typography>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>

              <Button
                component={Link}
                color="primary"
                size={isWidthDown('xs', width) ? 'small' : 'large'}
                onClick={() => dispatch(setApplyFormOpen(id))}
                style={{ textDecoration: 'none' }}
              >
                Apply
              </Button>
              <Button color="primary" size={isWidthDown('xs', width) ? 'small' : 'large'} onClick={() => setOpen(!isOpen)}>Description</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={isOpen}>
        <Box py={2}>
          <Divider />
        </Box>
        <Markdown>{base64Decode(description)}</Markdown>
      </Collapse>
    </Box>
  );
});

export default withWidth()(JobItem);
