import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import ReactGA from 'react-ga';
import { login } from './actions';
import PrivateRoute from './components/PrivateRoute';
import Header from './components/Header';
import Footer from './components/Footer';
import Login from './components/Login';
import Home from './components/Home';
import Products from './components/Products';
import Services from './components/Services';
import Support from './components/Support';
import ProductDetails from './components/ProductDetails';
import Dashboard from './components/Dashboard';
import Corporate from './components/Corporate';
import News from './components/News';
import About from './components/About';
import Careers from './components/Careers';
import Clients from './components/Clients';
import OtdrFinder from './components/OtdrFinder';
import Shortlist from './components/Shortlist';
import Whatsapp from './components/Whatsapp';
import ScrollToTop from './components/ScrollToTop';
import Alert from './components/Alert';

import 'swiper/swiper.scss';
import 'swiper/modules/autoplay/autoplay.scss';
import 'swiper/modules/effect-cube/effect-cube.scss';
import 'swiper/modules/effect-flip/effect-flip.scss';
import 'swiper/modules/effect-coverflow/effect-coverflow.scss';

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => { dispatch(login()); }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }, 1000);
  }, []);

  return (
    <Router basename={process.env.PUBLIC_URL}>
      <ScrollToTop />
      <Alert />
      <Route component={Header} />
      <Route component={OtdrFinder} />
      <Route component={Shortlist} />
      <Route component={Whatsapp} />
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/products/:productId" component={ProductDetails} />
        <Route path="/products" component={Products} />
        <Route path="/services" component={Services} />
        <Route path="/support" component={Support} />
        <Route path="/corporate" component={Corporate} />
        <Route path="/news/:id" component={News} />
        <Route path="/news" component={News} />
        <Route path="/about" component={About} />
        <Route path="/careers" component={Careers} />
        <Route path="/clients" component={Clients} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <Route exact path="/" component={Home} />
        <Redirect to="/" />
      </Switch>
      <Route component={Footer} />
    </Router>
  );
};

export default App;
