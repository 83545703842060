import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  videoList: [],
};

const slice = createSlice({
  name: 'featuredVideo',
  initialState,
  reducers: {
    setFeaturedVideo: (state, { payload }) => ({
      ...state,
      videoList: payload,
    }),
  },
});

export const featuredVideoSelector = ({ featuredVideo: { videoList } }) => videoList;

export const {
  setFeaturedVideo,
} = slice.actions;

export default slice.reducer;
