import React, { useEffect } from 'react';
import { shape, string } from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
// import GitHubIcon from '@material-ui/icons/GitHub';
// import FacebookIcon from '@material-ui/icons/Facebook';
// import TwitterIcon from '@material-ui/icons/Twitter';
import { useSelector, useDispatch } from 'react-redux';
import {
  newsSelector,
  isFetchingNewsSelector,
  featuredNewsSelector,
  isFetchingFeaturedNewsSelector,
} from 'slices/news';
import { getNewsById, getFeaturedNews } from 'App/actions';
import { CircularProgress, Box } from '@material-ui/core';
import MetaTags from 'react-meta-tags';
import MainFeaturedPost from './components/MainFeaturedPost';
import FeaturedPost from './components/FeaturedPost';
import Main from './components/Main';
// import Sidebar from './components/Sidebar';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginBottom: theme.spacing(3),
  },
}));

// const sidebar = {
//   title: 'About',
//   description:
// eslint-disable-next-line max-len
//     'Etiam porta sem malesuada magna mollis euismod. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur.',
//   archives: [
//     { title: 'March 2020', url: '#' },
//     { title: 'February 2020', url: '#' },
//     { title: 'January 2020', url: '#' },
//     { title: 'November 1999', url: '#' },
//     { title: 'October 1999', url: '#' },
//     { title: 'September 1999', url: '#' },
//     { title: 'August 1999', url: '#' },
//     { title: 'July 1999', url: '#' },
//     { title: 'June 1999', url: '#' },
//     { title: 'May 1999', url: '#' },
//     { title: 'April 1999', url: '#' },
//   ],
//   social: [
//     { name: 'GitHub', icon: GitHubIcon },
//     { name: 'Twitter', icon: TwitterIcon },
//     { name: 'Facebook', icon: FacebookIcon },
//   ],
// };

const News = ({ match: { params: { id: newsId } } }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const featuredNews = useSelector(featuredNewsSelector);
  const isFetchingFeaturedNews = useSelector(isFetchingFeaturedNewsSelector);
  const news = useSelector(newsSelector);
  const isFetchiingNews = useSelector(isFetchingNewsSelector);

  useEffect(() => { dispatch(getFeaturedNews()); }, [dispatch]);
  useEffect(() => { if (newsId) dispatch(getNewsById(newsId)); }, [newsId, dispatch]);

  if (isFetchingFeaturedNews || isFetchiingNews) {
    return (
      <Box height={600} position="relative">
        <Box
          width={70}
          height={70}
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          margin="auto"
        >
          <CircularProgress size={70} />
        </Box>
      </Box>
    );
  }

  const mainFeaturedNews = featuredNews[0];
  const otherNews = newsId ? featuredNews.slice(1, 3) : featuredNews.slice(1);

  return (
    <>
      <MetaTags>
        <meta name="description" content="INVAS Technologies provides you with trending information on the telecom industry & discounted price of Splicing Machines & EXFO OTDR, Power meters etc." />
      </MetaTags>
      <Container>
        <main>
          {newsId && (
          <Grid container spacing={4} className={classes.mainGrid}>
            <Main post={news} />
            {/* <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
              social={sidebar.social}
            /> */}
          </Grid>
          )}
          {
          featuredNews.length && (
            <Box mb={3}>
              <MainFeaturedPost post={mainFeaturedNews} />
              <Grid container spacing={4}>
                {otherNews.map((post) => (
                  <FeaturedPost key={post.title} post={post} />
                ))}
              </Grid>
            </Box>
          )
        }
        </main>
      </Container>
    </>
  );
};

News.propTypes = {
  match: shape({
    params: shape({
      id: string,
    }).isRequired,
  }).isRequired,
};

export default News;
