/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from 'slices/user';
import {
  Backdrop, withStyles, CircularProgress, Typography,
} from '@material-ui/core';
import theme from 'theme';

const PrivateRoute = withStyles(() => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    background: theme.palette.common.white,
  },
}))(({ component: Component, classes, ...rest }) => {
  const { isLoggingIn, token } = useSelector(userSelector);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggingIn && !token) {
          const { location } = props;
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location },
              }}
            />
          );
        }

        return (
          <>
            <Backdrop
              open={isLoggingIn}
              className={classes.backdrop}
              transitionDuration={{ exit: theme.transitions.duration.leavingScreen }}
            >
              <center>
                <CircularProgress />
                <Typography>
                  Logging in...
                </Typography>
              </center>
            </Backdrop>
            {token && <Component {...props} />}
          </>
        );
      }}
    />
  );
});

export default PrivateRoute;
