import React from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  Typography,
  Container,
  withStyles,
  Button,
  isWidthDown,
  withWidth,
} from '@material-ui/core';
import Breadcrumbs from 'App/components/Breadcrumbs';
import WhoWeAre from './components/WhoWeAre';
import ExecutiveTeam from './components/ExecutiveTeam';
import { ReactComponent as WhoWeAreIcon } from './icons/who-we-are.svg';
import { ReactComponent as TeamIcon } from './icons/executive-team.svg';

const BREADCRUMBS = [{
  label: 'Home',
  to: '/',
}, {
  label: 'About Us',
}];

const About = withStyles((theme) => ({
  bgImg: {
    position: 'relative',
    backgroundImage: `linear-gradient(to top right, ${theme.palette.secondary.dark}, #47a5e3)`,
    paddingBottom: theme.spacing(25),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(15),
    },
    backgroundSize: '200% 200%',
    animation: 'gradient 8s ease infinite',
  },
  title: {
    textTransform: 'uppercase',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontSize: 60,
    fontWeight: 100,
    letterSpacing: 2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 40,
    },
  },
  description: {
    color: theme.palette.primary.contrastText,
    fontSize: 28,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
    marginTop: theme.spacing(3),
    maxWidth: 600,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.3,
  },
  tabsContainer: {
    color: 'white',
    position: 'absolute',
    bottom: -1,
    left: 0,
    right: 0,
    '& .MuiButton-root': {
      borderRadius: 0,
      width: 200,
      [theme.breakpoints.down('xs')]: {
        width: '50%',
      },
    },
  },
}))(({
  classes,
  width,
  match: { path },
  history: {
    push,
    location: { pathname },
  },
}) => (
  <>
    <div className={classes.bgImg}>
      <Container>
        <Breadcrumbs path={BREADCRUMBS} contrast />
        <Typography className={classes.title}>About Us</Typography>
        <Typography className={classes.description}>
          Throughout its existence, INVAS has been recognised by the customers,
          principals and partners as a trusted and preferred organisation to work with.
          The company’s strong foundation of vision and values, combined with the
          spirit of it’s people make this possible. That is what gives us a sense of
          pride and drives us to excel in whatever we do.
        </Typography>
      </Container>
      <Container className={classes.tabsContainer}>
        <Button
          startIcon={<WhoWeAreIcon />}
          variant="outlined"
          color="inherit"
          size={isWidthDown('xs', width) ? 'medium' : 'large'}
          onClick={() => push('/about/who-we-are')}
          style={pathname === '/about/who-we-are' ? { background: 'rgba(255,255,255,0.4)' } : null}
        >
          Who We Are
        </Button>
        <Button
          startIcon={<TeamIcon style={{ height: 22 }} />}
          variant="outlined"
          color="inherit"
          size={isWidthDown('xs', width) ? 'medium' : 'large'}
          onClick={() => push('/about/executive-team')}
          style={pathname === '/about/executive-team' ? { background: 'rgba(255,255,255,0.4)' } : null}
        >
          Executive Team
        </Button>
      </Container>
    </div>
    <Switch>
      <Route path={`${path}/who-we-are`} component={WhoWeAre} />
      <Route path={`${path}/executive-team`} component={ExecutiveTeam} />
    </Switch>
  </>
));

export default withWidth()(About);
