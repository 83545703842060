import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFetching: false,
  filters: [],
};

const slice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilters: (state, { payload }) => ({
      ...state,
      filters: payload,
    }),
    setFetchingFilters: (state, { payload }) => ({
      ...state,
      isFetching: payload,
    }),
  },
});

export const filterSelector = ({ filter: { filters } }) => filters;
export const isFetchingFilterSelector = ({ filter: { isFetching } }) => isFetching;

export const {
  setFilters,
  setFetchingFilters,
} = slice.actions;

export default slice.reducer;
