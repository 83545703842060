import React, { useState } from 'react';
import { arrayOf, string } from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import ScrollGradient from './ScrollGradient';

const ImageCarousel = withStyles((theme) => ({
  imgContainer: {
    width: 60,
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  img: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  imgLast: {
    width: '100%',
  },
}))(({ imageArr, altArr, classes }) => {
  const [index, setIndex] = useState(0);

  return (
    <Grid container justify="flex-end" spacing={2}>
      <Grid item xs={2} />
      <Grid item xs>
        <img className={classes.img} alt={altArr && altArr.length ? altArr[index] : 'product'} src={imageArr[index]} />
      </Grid>
      <Grid item>
        <div className={classes.imgContainer}>
          {imageArr.length > 1 && (
          <ScrollGradient>
            <Grid container direction="column">
              {imageArr.map((src, i) => (
                <Grid item key={src}>
                  <input type="image" src={src} alt={altArr && altArr.length ? altArr[i] : 'Product'} className={i === imageArr.length - 1 ? classes.imgLast : classes.img} onClick={() => setIndex(i)} />
                </Grid>
              ))}
            </Grid>
          </ScrollGradient>
          )}
        </div>
      </Grid>
    </Grid>
  );
});

ImageCarousel.propTypes = {
  imageArr: arrayOf(string).isRequired,
  altArr: arrayOf(string),
};

export default ImageCarousel;
