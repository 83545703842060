import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  jobs: [],
  isFetchingJobs: false,
  jobTypes: [],
  isFetchingJobTypes: false,
  isApplyFormOpen: false,
};

const slice = createSlice({
  name: 'careers',
  initialState,
  reducers: {
    setJobs: (state, { payload }) => ({
      ...state,
      jobs: payload,
    }),
    setFetchingJobs: (state, { payload }) => ({
      ...state,
      isFetchingJobs: payload,
    }),
    setJobTypes: (state, { payload }) => ({
      ...state,
      jobTypes: payload,
    }),
    setFetchingJobTypes: (state, { payload }) => ({
      ...state,
      isFetchingJobTypes: payload,
    }),
    setApplyFormOpen: (state, { payload }) => ({
      ...state,
      isApplyFormOpen: payload,
    }),
  },
});

export const jobsSelector = ({ careers: { jobs } }) => jobs;
export const fetchingJobsSelector = ({ careers: { isFetchingJobs } }) => isFetchingJobs;
export const jobTypesSelector = ({ careers: { jobTypes } }) => jobTypes;
export const fetchingJobTypesSelector = ({ careers: { isFetchingJobTypes } }) => isFetchingJobTypes;
export const applyFormOpenSelector = ({ careers: { isApplyFormOpen } }) => isApplyFormOpen;

export const {
  setJobs,
  setFetchingJobs,
  setJobTypes,
  setFetchingJobTypes,
  setApplyFormOpen,
} = slice.actions;

export default slice.reducer;
