import {
  fetchAPI,
  debounce,
  convertToGQLObject,
} from 'utils';
import {
  setLoggingIn,
  setLoginData,
  resetLoginData,
} from 'slices/user';
import {
  productSelector,
  setProducts,
  setFetchingProducts,
  setFeaturedProducts,
  setFetchingFeaturedProducts,
  setSearchProduct,
  setSearchingProduct,
  setFilterProduct,
  setFilteringProduct,
} from 'slices/product';
import {
  setFilters,
  setFetchingFilters,
  filterSelector,
  isFetchingFilterSelector,
} from 'slices/filter';
import {
  addShortlist,
  openShortlist,
} from 'slices/shortlist';
import {
  setFetchingNews,
  setNews,
  newsSelector,
  setFeaturedNews,
  setFetchingFeaturedNews,
  featuredNewsSelector,
} from 'slices/news';
import { triggerAlert } from 'slices/alert';
import {
  jobTypesSelector,
  setFetchingJobTypes,
  setJobTypes,
  jobsSelector,
  setFetchingJobs,
  setJobs,
} from 'slices/careers';
import { featuredVideoSelector, setFeaturedVideo } from 'slices/featuredVideo';
import { officeSelector, setOffices } from 'slices/offices';
import { setFetchingTeamMembers, setTeamMembers, teamMembersSelector } from 'slices/teamMembers';

export const logout = () => (dispatch) => {
  dispatch(resetLoginData());
  dispatch(setLoggingIn(false));
  localStorage.removeItem('token');
};

export const login = (credentials) => (dispatch) => {
  const token = localStorage.getItem('token');

  if (!credentials && !token) {
    dispatch(setLoggingIn(false));
    return null;
  }

  const queryName = credentials ? 'login' : 'verifyToken';
  const queryConditions = credentials ? {
    email: credentials.email,
    password: credentials.password,
  } : { token };
  const query = `query {
    ${queryName} (${Object.entries(queryConditions).map(([key, value]) => `${key}: "${value}"`).join(', ')}) {
      _id
      email
      token
    }
  }`;

  dispatch(setLoggingIn(true));

  return fetchAPI({ query })
    .then(({ [queryName]: userData }) => {
      dispatch(setLoginData(userData));
      dispatch(setLoggingIn(false));

      if (credentials) {
        if (credentials.remember) localStorage.setItem('token', userData.token);
        else localStorage.removeItem('token');
      }

      return userData;
    })
    .catch((err) => {
      dispatch(logout());
      throw err;
    });
};

export const handleLoginSubmit = (values, { setStatus }, location, history) => (dispatch) => {
  setStatus();

  return dispatch(login(values))
    .then(() => {
      if (location.state && location.state.from) {
        history.replace(location.state.from);
      } else {
        history.replace('/dashboard');
      }
    })
    .catch((err) => {
      setStatus(err.errors[0].message);
      throw err;
    });
};

export const searchProduct = (text) => (dispatch) => debounce(() => {
  if (!text || text.length < 3) {
    return dispatch(setSearchProduct([]));
  }

  const query = `query {
    searchProduct(text: "${text}") {
      id
      name
      images
      alt
    }
  }`;

  dispatch(setSearchingProduct(true));
  return fetchAPI({ query })
    .then((data) => dispatch(setSearchProduct(data.searchProduct)))
    .then(() => dispatch(setSearchingProduct(false)));
}, 500)();

export const getAllFilters = () => (dispatch, getState) => {
  const filters = filterSelector(getState());
  const isFetchingFilters = isFetchingFilterSelector(getState());

  if (filters.length || isFetchingFilters) {
    return null;
  }

  const query = `query {
    filter {
      _id
      name
      options
    }
  }`;
  dispatch(setFetchingFilters(true));
  return fetchAPI({ query })
    .then((data) => dispatch(setFilters(data.filter
      .map(({ options, ...filter }) => ({ options: JSON.parse(options), ...filter })))))
    .then(() => dispatch(setFetchingFilters(false)));
};

export const getAllProducts = () => (dispatch) => {
  const query = `query {
    product {
      id
      name
      shortDesc
      longDesc
      spec
      images
      alt
      features
      featured
    }
  }`;

  dispatch(setFetchingProducts(true));
  return fetchAPI({ query })
    .then((data) => dispatch(setProducts(data.product)))
    .then(() => dispatch(setFetchingProducts(false)));
};

export const getProductById = (id) => (dispatch, getState) => {
  if (productSelector(getState())[id]) {
    return Promise.resolve();
  }

  const query = `query {
    product(id: "${id}") {
      id
      name
      shortDesc
      longDesc
      spec
      images
      alt
      features
    }
  }`;

  return fetchAPI({ query })
    .then((data) => dispatch(setProducts(data.product)));
};

export const getFeaturedProducts = () => (dispatch) => {
  const query = `query {
    product(featured: true) {
      id
      name
      shortDesc
      longDesc
      spec
      images
      alt
      features
      featured
    }
  }`;

  dispatch(setFetchingFeaturedProducts(true));
  return fetchAPI({ query })
    .then((data) => dispatch(setFeaturedProducts(data.product)))
    .then(() => dispatch(setFetchingFeaturedProducts(false)));
};

export const filterProduct = (filterMap) => (dispatch) => {
  const query = `query {
    filterProduct(filters: "${JSON.stringify(filterMap).replace(/"/g, '\\"')}") {
      id
      name
      shortDesc
      longDesc
      spec
      images
      alt
      features
      featured
    }
  }`;

  dispatch(setFilteringProduct(true));
  return fetchAPI({ query })
    .then((data) => dispatch(setFilterProduct(data.filterProduct)))
    .then(() => dispatch(setFilteringProduct(false)));
};

export const shortlistProduct = (product) => (dispatch) => {
  dispatch(openShortlist());
  dispatch(addShortlist(product));
};

export const getFeaturedNews = () => (dispatch, getState) => {
  if (featuredNewsSelector(getState()).length) {
    return null;
  }

  const query = `query {
    getFeaturedNews {
      id
      title
      abstract
      media
      content
      featured
    }
  }`;

  dispatch(setFetchingFeaturedNews(true));
  return fetchAPI({ query })
    .then((data) => dispatch(setFeaturedNews(data.getFeaturedNews)))
    .then(() => dispatch(setFetchingFeaturedNews(false)));
};

export const getNewsById = (newsId) => (dispatch, getState) => {
  if (newsSelector(getState()) && newsSelector(getState()).id === newsId) {
    return null;
  }

  const query = `query {
  getNewsById(id:"${newsId}") {
      id
      title
      abstract
      media
      content
      featured
    }
  }`;

  dispatch(setFetchingNews(true));
  return fetchAPI({ query })
    .then((data) => dispatch(setNews(data.getNewsById)))
    .then(() => dispatch(setFetchingNews(false)));
};

export const submitNewsletter = (input) => (dispatch) => {
  const query = `mutation {
    submitNewsletter(input: ${convertToGQLObject(input)}) { id }
  }`;

  return fetchAPI({ query })
    .then(() => dispatch(triggerAlert({ severity: 'success', message: 'Thanks for subscribing' })))
    .catch((error) => {
      dispatch(triggerAlert({ severity: 'error', message: 'Some error occurred. Please try again' }));
      throw error;
    });
};

export const submitConnect = (input) => (dispatch) => {
  const query = `mutation {
    submitConnect(input: ${convertToGQLObject(input)}) { id }
  }`;

  return fetchAPI({ query })
    .then(() => dispatch(triggerAlert({ severity: 'success', message: 'Thanks for connecting with us. We will get back shortly.' })))
    .catch((error) => {
      dispatch(triggerAlert({ severity: 'error', message: 'Some error occurred. Please try again' }));
      throw error;
    });
};

export const submitEnquiry = (input) => (dispatch) => {
  const query = `mutation {
    submitEnquiry(input: ${convertToGQLObject(input)}) { id }
  }`;

  return fetchAPI({ query })
    .then(() => dispatch(triggerAlert({ severity: 'success', message: 'Thanks. We have recevied your enquiry. We will get back shortly.' })))
    .catch((error) => {
      dispatch(triggerAlert({ severity: 'error', message: 'Some error occurred. Please try again' }));
      throw error;
    });
};

export const submitSupport = (input) => (dispatch) => {
  const query = `mutation {
    submitSupport(input: ${convertToGQLObject(input)}) { id }
  }`;

  return fetchAPI({ query })
    .then(({ submitSupport: { id } }) => dispatch(triggerAlert({ severity: 'success', message: `Support Request Submitted. Ticket Number: ${id}` })))
    .catch((error) => {
      dispatch(triggerAlert({ severity: 'error', message: 'Some error occurred. Please try again' }));
      throw error;
    });
};

export const getJobTypes = () => (dispatch, getState) => {
  if (jobTypesSelector(getState()).length) {
    return null;
  }

  const query = `query {
      getJobTypes {
        name
        jobs
    }
  }`;

  dispatch(setFetchingJobTypes(true));
  return fetchAPI({ query })
    .then((data) => dispatch(setJobTypes(data.getJobTypes)))
    .then(() => dispatch(setFetchingJobTypes(false)));
};

export const getJobs = () => (dispatch, getState) => {
  if (jobsSelector(getState()).length) {
    return null;
  }

  const query = `query {
    getJobs {
      id
      title
      location
      description
      date
    }
  }`;

  dispatch(setFetchingJobs(true));
  return fetchAPI({ query })
    .then((data) => dispatch(setJobs(data.getJobs)))
    .then(() => dispatch(setFetchingJobs(false)));
};

export const submitJobApplication = ({ jobId, ...input }) => (dispatch, getState) => {
  const {
    title: jobTitle,
    location: jobLocation,
  } = jobsSelector(getState()).find(({ id }) => id === jobId);
  const query = `mutation {
    submitJobApplication(input: ${convertToGQLObject({
    ...input,
    jobId,
    jobTitle,
    jobLocation,
  })}) { id }
  }`;

  return fetchAPI({ query })
    .then(({ submitJobApplication: { id } }) => dispatch(triggerAlert({ severity: 'success', message: `Thanks for applying. Your Application Number is ${id}` })))
    .catch((error) => {
      dispatch(triggerAlert({ severity: 'error', message: 'Some error occurred. Please try again' }));
      throw error;
    });
};

export const getFeaturedVideos = () => (dispatch, getState) => {
  const videoList = featuredVideoSelector(getState());
  if (videoList.length) return videoList;

  const query = `query {
    featuredVideo {
      url
    }
  }`;

  return fetchAPI({ query })
    .then((data) => dispatch(setFeaturedVideo(data.featuredVideo)))
    .then(() => featuredVideoSelector(getState()));
};

export const getOffices = () => (dispatch, getState) => {
  const officesList = officeSelector(getState());
  if (officesList.length) return officesList;

  const query = `query {
    offices {
      region
      name
      line1
      line2
      line3
      phone
    }
  }`;

  return fetchAPI({ query })
    .then((data) => dispatch(setOffices(data.offices)))
    .then(() => officeSelector(getState()));
};

export const getTeamMembers = () => (dispatch, getState) => {
  const teamMembers = teamMembersSelector(getState());
  if (teamMembers.length) return teamMembers;

  const query = `query {
    teamMembers {
      id
      name
      designation
      content
      img
      alt
    }
  }`;

  dispatch(setFetchingTeamMembers(true));
  return fetchAPI({ query })
    .then((data) => dispatch(setTeamMembers(data.teamMembers)))
    .then(() => dispatch(setFetchingTeamMembers(false)));
};
