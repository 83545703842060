import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggingIn: true,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoggingIn: (state, { payload }) => ({
      ...state,
      isLoggingIn: payload,
    }),
    setLoginData: (state, { payload: { token, _id, email } }) => ({
      ...state,
      _id,
      email,
      token,
    }),
    resetLoginData: () => initialState,
  },
});

export const userSelector = ({ user }) => user;

export const {
  setLoggingIn,
  setLoginData,
  resetLoginData,
} = slice.actions;

export default slice.reducer;
