import React, { useEffect } from 'react';
import { Formik, Field } from 'formik';
import {
  Container,
  Typography,
  withStyles,
  Button,
  Link,
  isWidthDown,
  withWidth,
  Grid,
  InputAdornment,
  Box,
  Paper,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Select, TextField } from 'formik-material-ui';
import Breadcrumbs from 'App/components/Breadcrumbs';
import { useDispatch, useSelector } from 'react-redux';
import { getJobTypes, getJobs } from 'App/actions';
import {
  jobsSelector,
  fetchingJobsSelector,
  jobTypesSelector,
  fetchingJobTypesSelector,
} from 'slices/careers';
import MetaTags from 'react-meta-tags';
import { ReactComponent as Resume } from './icons/resume.svg';
import JobItem from './components/JobItem';
import ApplyFormDialog from './components/ApplyFormDialog';

const BREADCRUMBS = [{
  label: 'Home',
  to: '/',
}, {
  label: 'Careers',
}];

const Careers = withStyles((theme) => ({
  bgImg: {
    position: 'relative',
    backgroundImage: `linear-gradient(to top right, #47a5e3, ${theme.palette.secondary.dark})`,
    paddingBottom: theme.spacing(25),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(10),
    },
    backgroundSize: '200% 200%',
    animation: 'gradient 8s ease infinite',
  },
  title: {
    textTransform: 'uppercase',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontSize: 60,
    fontWeight: 100,
    letterSpacing: 2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 40,
    },
  },
  description: {
    color: theme.palette.primary.contrastText,
    fontSize: 28,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
    marginTop: theme.spacing(3),
    maxWidth: 600,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.3,
  },
  tabsContainer: {
    color: 'white',
    position: 'absolute',
    bottom: theme.spacing(4),
    left: 0,
    right: 0,
    '& .MuiButton-root': {
      borderRadius: 0,
      width: 200,
    },
    '& a': {
      textDecoration: 'none !important',
    },
    '& svg': {
      height: 26,
    },
    [theme.breakpoints.down('xs')]: {
      bottom: theme.spacing(2),
      '& .MuiButton-root': {
        width: 'auto',
      },
      '& svg': {
        height: 22,
      },
    },
  },
  vacancies: {
    fontSize: 28,
    fontWeight: theme.typography.fontWeightLight,
  },
  jobField: {
    '&.MuiInput-underline::before,&.MuiInput-underline::after,& .MuiInput-underline::before,& .MuiInput-underline::after': {
      display: 'none',
    },
    '& .MuiInputBase-input': {
      padding: theme.spacing(2),
    },
  },
}))(({ classes, width }) => {
  const dispatch = useDispatch();
  const jobs = useSelector(jobsSelector);
  const isFetchingJobs = useSelector(fetchingJobsSelector);
  const jobTypes = useSelector(jobTypesSelector);
  const isFetchingJobTypes = useSelector(fetchingJobTypesSelector);
  const jobsWithTypes = jobs.map((job) => {
    const type = jobTypes.find(({ jobs: _jobs }) => _jobs.indexOf(parseInt(job.id, 10)) > -1);

    if (!type) return job;

    return { ...job, type: type.name };
  });

  useEffect(() => { dispatch(getJobTypes()); }, [dispatch]);
  useEffect(() => { dispatch(getJobs()); }, [dispatch]);

  return (
    <>
      <MetaTags>
        <meta name="description" content="With a work culture that provides multideciplinary exposure and growth opportunities, INVAS has nurtured careers spanning more than a decade. Email Resume." />
      </MetaTags>
      <ApplyFormDialog />
      <Formik
        initialValues={{
          jobType: '',
          jobId: '',
        }}
      >
        {({ values: { jobType, jobId } }) => {
          const filteredJobs = jobsWithTypes.filter(({ id, type }) => {
            if (jobId) {
              return id.indexOf(jobId) > -1;
            }

            const selectedJobType = jobTypes.find(({ name }) => name === jobType);

            return !selectedJobType || selectedJobType.name === type;
          });

          return (
            <>
              <div className={classes.bgImg}>
                <Container>
                  <Breadcrumbs path={BREADCRUMBS} contrast />
                  <Typography className={classes.title}>Careers</Typography>
                  <Typography className={classes.description}>
                    Be a part of an organisation that prides it’s people. Since it’s incorporation,
                    INVAS has been second home to a many reputed individuals from various sectors of
                    IT and Telecom. With a work culture that provides multideciplinary exposure and
                    growth opportunities, INVAS has nurtured careers spanning more than a decade.
                  </Typography>
                </Container>
                <Container className={classes.tabsContainer}>
                  <Button
                    startIcon={<Resume />}
                    component={Link}
                    variant="outlined"
                    color="inherit"
                    size={isWidthDown('xs', width) ? 'medium' : 'large'}
                    href="mailto:hr@invas.in?subject=Careers | Resume"
                  >
                    Email Resume
                  </Button>
                </Container>
              </div>
              {
              isFetchingJobTypes || isFetchingJobs
                ? (
                  <Box height={400} position="relative">
                    <Box
                      width={70}
                      height={70}
                      position="absolute"
                      top={0}
                      bottom={0}
                      left={0}
                      right={0}
                      margin="auto"
                    >
                      <CircularProgress size={70} />
                    </Box>
                  </Box>
                ) : (
                  <Container>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Paper>
                          <Field
                            component={Select}
                            name="jobType"
                            fullWidth
                            className={classes.jobField}
                            displayEmpty
                            renderValue={(value) => (
                              <Typography
                                color={jobTypes.findIndex(({ name }) => name === value) > -1 ? 'textPrimary' : 'textSecondary'}
                                style={{ lineHeight: 1 }}
                              >
                                {value || 'Select Job Type'}
                              </Typography>
                            )}
                          >
                            {jobTypes.map(({ name }) => (
                              <MenuItem key={name} value={name}>{name}</MenuItem>
                            ))}
                          </Field>
                        </Paper>
                      </Grid>
                      <Grid item xs={6}>
                        <Paper>
                          <Field
                            name="jobId"
                            component={TextField}
                            fullWidth
                            placeholder="Search Job ID"
                            className={classes.jobField}
                            InputProps={{
                              endAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
                            }}
                          />
                        </Paper>
                      </Grid>
                    </Grid>
                    <Box my={4}>
                      <Typography className={classes.vacancies}>
                        {filteredJobs.length === 1 ? '1 Vacancy' : `${filteredJobs.length} Vacancies`}
                      </Typography>
                    </Box>
                    <Grid container spacing={2}>
                      {filteredJobs.map((job) => (
                        <Grid item key={job.id} xs={12}>
                          <JobItem job={job} />
                        </Grid>
                      ))}
                    </Grid>
                  </Container>
                )
              }
              <Box mt={4} />
            </>
          );
        }}
      </Formik>
    </>
  );
});

export default withWidth()(Careers);
