export const fetchAPI = (statement) => fetch(`${process.env.REACT_APP_API_URL}/api`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  body: JSON.stringify(statement),
})
  .then((r) => r.json())
  .then((response) => {
    if (response.errors) throw response;

    return response.data;
  });

export const debounce = (() => {
  let timeout = null;

  return (func, wait) => (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
})();

export const getProductUrl = (id) => `${window.location.origin}/products/${encodeURIComponent(id)}`;

export const numberWithCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const getDate = (date) => new Date(date).toLocaleDateString(
  'en-IN',
  { day: 'numeric', month: 'short', year: 'numeric' },
);

export const getTimestamp = (objectId) => getDate(new Date(
  parseInt(objectId.toString().substring(0, 8), 16) * 1000,
));

export const convertToGQLObject = (data) => `{${Object.entries(data)
  .reduce((acc, [key, value]) => (value
    ? [...acc, `${key}: ${typeof value === 'object' ? JSON.stringify(value)
      : `"""${value}"""`}`] : acc), []).join(', ')}}`;

export const serializeFile = (file) => new Promise((resolve, reject) => {
  if (!file) {
    resolve();
    return;
  }

  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});

export const getYtIdFromUrl = (url) => url.split('v=')[1].split('&')[0];

export const base64Decode = (str) => {
  try {
    return decodeURIComponent(escape(window.atob(str)));
  } catch (e) {
    return str;
  }
};
