import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  news: null,
  isFetching: false,
  featuredNews: [],
  isFetchingFeatured: false,
};

const slice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setNews: (state, { payload }) => ({
      ...state,
      news: payload,
    }),
    setFetchingNews: (state, { payload }) => ({
      ...state,
      isFetching: payload,
    }),
    setFeaturedNews: (state, { payload }) => ({
      ...state,
      featuredNews: payload,
    }),
    setFetchingFeaturedNews: (state, { payload }) => ({
      ...state,
      isFetchingFeatured: payload,
    }),
  },
});

export const newsSelector = ({ news: { news } }) => news;
export const isFetchingNewsSelector = ({ news: { isFetching } }) => isFetching;
export const featuredNewsSelector = ({ news: { featuredNews } }) => featuredNews;
export const isFetchingFeaturedNewsSelector = ({
  news: { isFetchingFeatured },
}) => isFetchingFeatured;

export const {
  setNews,
  setFetchingNews,
  setFeaturedNews,
  setFetchingFeaturedNews,
} = slice.actions;

export default slice.reducer;
