import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

const slice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    toggleDrawer: (state) => !state,
    setDrawer: (state, { payload }) => payload,
  },
});

export const drawerSelector = ({ drawer }) => drawer;

export const {
  toggleDrawer,
  setDrawer,
} = slice.actions;

export default slice.reducer;
