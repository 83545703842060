import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  severity: 'info',
  message: null,
};

const slice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    triggerAlert: (state, { payload: { severity = 'info', message } }) => ({
      ...state,
      isOpen: true,
      severity,
      message,
    }),
    dismissAlert: (state) => ({
      ...state,
      isOpen: false,
    }),
  },
});

export const alertSelector = ({ alert }) => alert;

export const {
  triggerAlert,
  dismissAlert,
} = slice.actions;

export default slice.reducer;
