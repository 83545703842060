import React from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  withStyles,
  Button,
} from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import Breadcrumbs from 'App/components/Breadcrumbs';
import Recaptcha from 'App/components/Recaptcha';
import { submitEnquiry } from 'App/actions';
import { useDispatch } from 'react-redux';
import MetaTags from 'react-meta-tags';
import { ReactComponent as Maintenance } from './icons/maintenance.svg';
import { ReactComponent as Automation } from './icons/automation.svg';
import { ReactComponent as IPSec } from './icons/ipsec.svg';
import { ReactComponent as Networking } from './icons/networking.svg';
import { ReactComponent as SDN } from './icons/sdn.svg';
import { ReactComponent as WebRTC } from './icons/webrtc.svg';

const BREADCRUMBS = [{
  label: 'Home',
  to: '/',
}, {
  label: 'Services',
}];

const Services = withStyles((theme) => ({
  bgImg: {
    backgroundImage: `linear-gradient(to top right, #47a5e3, ${theme.palette.secondary.dark})`,
    paddingBottom: theme.spacing(25),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(10),
    },
    backgroundSize: '200% 200%',
    animation: 'gradient 8s ease infinite',
  },
  title: {
    textTransform: 'uppercase',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontSize: 60,
    fontWeight: 100,
    letterSpacing: 2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 40,
    },
  },
  description: {
    color: theme.palette.primary.contrastText,
    fontSize: 28,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
    marginTop: theme.spacing(3),
    maxWidth: 600,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.3,
  },
  pageTitle: {
    fontSize: 60,
    color: '#47a5e3',
    fontWeight: theme.typography.fontWeightLight,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    lineHeight: 1.2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 40,
    },
  },
  pageSubtitle: {
    fontSize: 28,
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.2,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 22,
    },
  },
  pageHighlight: {
    height: '100%',
    width: theme.spacing(3),
    backgroundImage: `linear-gradient(to top, #47a5e3, ${theme.palette.primary.main})`,
    marginRight: theme.spacing(10),
    position: 'absolute',
    [theme.breakpoints.down('md')]: {
      width: theme.spacing(1),
    },
  },
  bodyText: {
    fontSize: 22,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(4),
    maxWidth: 650,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  particleBg: {
    position: 'absolute',
    width: 440,
    height: 410,
    top: theme.spacing(-8),
    right: 0,
    backgroundImage: `url("${process.env.PUBLIC_URL}/images/shards.png")`,
    backgroundSize: 'cover',
    zIndex: -1,
    [theme.breakpoints.down('xs')]: {
      width: 180,
      height: 167,
    },
  },
  lteImg: {
    backgroundImage: `url("${process.env.PUBLIC_URL}/images/lte.png")`,
  },
  testingImg: {
    backgroundImage: `url("${process.env.PUBLIC_URL}/images/testing.png")`,
  },
  networkImg: {
    backgroundImage: `url("${process.env.PUBLIC_URL}/images/network.png")`,
  },
  servicesImg: {
    display: 'inline-block',
    borderRadius: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: 400,
    height: 400,
    [theme.breakpoints.down('md')]: {
      width: 200,
      height: 200,
    },
    [theme.breakpoints.down('xs')]: {
      width: 100,
      height: 100,
    },
  },
  serviceTitle: {
    color: theme.palette.primary.main,
    fontSize: 30,
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
    lineHeight: 1.2,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
      marginLeft: 0,
      marginRight: 0,
    },
  },
  serviceContent: {
    fontSize: 22,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.2,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
      marginLeft: 0,
      marginRight: 0,
    },
  },
  blueSection: {
    backgroundColor: theme.palette.secondary.dark,
    position: 'relative',
  },
  shardsBg: {
    position: 'absolute',
    top: -464,
    left: 0,
    right: 0,
    height: 464,
    backgroundImage: `url("${process.env.PUBLIC_URL}/images/shardsBg.png")`,
    backgroundSize: 'auto 464px',
    backgroundPosition: 'right',
    [theme.breakpoints.down('sm')]: {
      height: 250,
      top: -250,
      backgroundSize: 'auto 250px',
    },
  },
  shardsBgOverlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundImage: `linear-gradient(${theme.palette.secondary.dark}00 19%, ${theme.palette.secondary.dark}40 35%, ${theme.palette.secondary.dark} 464px)`,
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `linear-gradient(${theme.palette.secondary.dark}00 19%, ${theme.palette.secondary.dark}40 35%, ${theme.palette.secondary.dark} 250px)`,
    },
  },
  servicesHeaderUnderline: {
    height: 2,
    width: theme.spacing(15),
    background: `linear-gradient(to right, #47a5e3, ${theme.palette.primary.light})`,
    borderRadius: 2,
    marginLeft: 350,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 200,
      width: theme.spacing(10),
    },
  },
  servicesHeader: {
    fontSize: 90,
    fontWeight: 100,
    lineHeight: 1.2,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    color: theme.palette.primary.light,
    marginBottom: theme.spacing(6),
    background: `linear-gradient(to right, #47a5e3, ${theme.palette.primary.light})`,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    [theme.breakpoints.down('xs')]: {
      fontSize: 60,
      marginBottom: 0,
    },
  },
  servicesTitle: {
    color: theme.palette.common.white,
    fontSize: 28,
    maxWidth: 380,
    display: 'inline-block',
    minHeight: 110,
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      minHeight: 90,
    },
  },
  servicesContainer: {
    marginTop: theme.spacing(10),
    position: 'relative',
  },
  servicesContent: {
    color: theme.palette.common.white,
    textAlign: 'left',
    display: 'inline-block',
    maxWidth: 380,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 18,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      paddingBottom: theme.spacing(10),
    },
  },
  servicesIcon: {
    width: 78,
    height: 78,
    [theme.breakpoints.down('sm')]: {
      width: 60,
      height: 60,
    },
  },
  blackSection: {
    background: theme.palette.common.black,
  },
  solutionsContainer: {
    paddingTop: theme.spacing(16),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(10),
    },
  },
  solutionsHeader: {
    fontSize: 90,
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    background: `linear-gradient(130deg, #B95CDA, ${theme.palette.primary.main} 55%)`,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    lineHeight: 0.9,
    letterSpacing: -2.4,
    [theme.breakpoints.down('sm')]: {
      fontSize: 72,
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 56,
    },
  },
  solutionsSubheader: {
    color: theme.palette.common.white,
    fontSize: 30,
    lineHeight: 1.2,
    fontWeight: 100,
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(6),
    },
  },
  solutionsContentContainer: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    maxWidth: '80%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(8),
    },
  },
  solutionsImgLabel: {
    textAlign: 'center',
    marginBottom: theme.spacing(15),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(6),
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: 0,
    },
  },
  solutionsContent: {
    color: theme.palette.common.white,
    fontSize: 24,
    lineHeight: 1.4,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  helpContainer: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(8),
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(4),
    },
  },
  helpSubmitContainer: {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(8),
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
      marginRight: 0,
    },
  },
  helpSubmit: {
    [theme.breakpoints.up('sm')]: {
      width: 160,
      height: 50,
    },
  },
}))(({ classes, width }) => {
  const dispatch = useDispatch();

  return (
    <>
      <MetaTags>
        <meta name="description" content="INVAS Technologies is the sales & service center of EXFO, Sumitomo Electric. INVAS offers many testing solution like SIP, Core Network, Diameter, LTE EPC." />
      </MetaTags>
      <Formik
        initialValues={{
          name: '',
          company: '',
          email: '',
          requirement: '',
          recaptcha: '',
        }}
        onSubmit={({
          name, company, email, requirement,
        }, { resetForm }) => dispatch(submitEnquiry({
          name, company, email, requirement,
        })).then(() => resetForm())}
      >
        {({ isSubmitting }) => (
          <>
            <div className={classes.bgImg}>
              <Container>
                <Breadcrumbs path={BREADCRUMBS} contrast />
                <Typography className={classes.title}>Services</Typography>
                <Typography className={classes.description}>
                  As the world shifts towards convergence of Telecom Networks and
                  Cloud based services, reliability of Communication Network takes
                  the charge to make organisation stand on top. To ensure your
                  network is reliable and compliant to industry standards, INVAS offers top
                  notch Test Solutions from Network Security, SIP Protocol Testing, LTE Product
                  Development and Testing. INVAS also has the expertise required
                  to tackle the challenges of Telecom Service Providers for Product
                  Engineering, Research & Development.
                </Typography>
              </Container>
            </div>
            <Box position="relative">
              <Box className={classes.particleBg} />
              <Box className={classes.pageHighlight} />
              <Container>
                <Box maxWidth={492}>
                  <Typography className={classes.pageTitle}>
                    Expertise in the Future of Telecom
                  </Typography>
                </Box>
                <Typography className={classes.pageSubtitle}>
                  Reliable and efficient services to help operators achieve goals faster & cheaper.
                </Typography>
              </Container>
            </Box>
            <Container>
              <Typography className={classes.bodyText}>
                With a culture of remaining ahead of the technology curve,
                INVAS has developed an expertise in Protocol Testing, Product
                Engineering and Development to help operators deal with the
                challenges of explosive growth in Mobile Data and Network
                Security. By leveraging our industry leading Product Development
                and Testing Solutions, operators can seamlessly expand their
                technological offering while remaining profitable.
              </Typography>
            </Container>
            <Box mt={10} />
            <Container maxWidth="xl">
              <Grid container justify="center">
                <Grid item xs={4} style={{ textAlign: 'center', zIndex: 1 }}>
                  <Box className={[classes.servicesImg, classes.lteImg].join(' ')} />
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center', zIndex: 1 }}>
                  <Box className={[classes.servicesImg, classes.testingImg].join(' ')} />
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center', zIndex: 1 }}>
                  <Box className={[classes.servicesImg, classes.networkImg].join(' ')} />
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item xs={4} style={{ textAlign: 'center', zIndex: 1 }}>
                  <Typography className={classes.serviceTitle}>LTE PRODUCT DEVELOPMENT</Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center', zIndex: 1 }}>
                  <Typography className={classes.serviceTitle}>
                    TELECOM TESTING & AUTOMATION
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center', zIndex: 1 }}>
                  <Typography className={classes.serviceTitle}>NETWORK TESTING & IPSec</Typography>
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item xs={4} style={{ textAlign: 'center', zIndex: 1 }}>
                  <Typography className={classes.serviceContent}>
                    More than a decade of experience. Since the technology arrived.
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center', zIndex: 1 }}>
                  <Typography className={classes.serviceContent}>
                    End-to-End Regression, Conformance,
                    Load Testing for LTE, 3G, IMS & Interworking.
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'center', zIndex: 1 }}>
                  <Typography className={classes.serviceContent}>
                    Expertise on IP Security and networking, application layer / user simulation.
                  </Typography>
                </Grid>
              </Grid>
            </Container>
            <Box mt={30} />
            <Box className={classes.blueSection}>
              <Box className={classes.shardsBg}>
                <Box className={classes.shardsBgOverlay} />
              </Box>
              <Box pt={4} />
              <Grid container direction="column" alignItems="center">
                <Grid item style={{ overflow: 'hidden' }}>
                  <Box className={classes.servicesHeaderUnderline} data-aos="fade-left" />
                </Grid>
                <Grid item>
                  <Typography className={classes.servicesHeader}>
                    Capabilities
                  </Typography>
                </Grid>
              </Grid>
              <Box className={classes.servicesContainer}>
                <Grid container>
                  <Grid item xs={6} sm={4}>
                    <Grid container direction="column" style={{ textAlign: 'center' }}>
                      <Grid item>
                        <Typography className={classes.servicesTitle}>
                          Product Engineering & Maintenance
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Maintenance className={classes.servicesIcon} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.servicesContent}>
                          Core applications & scripting package development,
                          EAST, ixLoad, dsTest proprietary tools. We engineer
                          applications for LTE interfaces / protocols, 3G / IMS.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Grid container direction="column" style={{ textAlign: 'center' }}>
                      <Grid item>
                        <Typography className={classes.servicesTitle}>
                          Telecom Testing & Automation
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Automation className={classes.servicesIcon} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.servicesContent}>
                          Control & User plane tests, Real World Traffic
                          Simulation, Intra-LTE & inter-RAT tests. Conformance,
                          Regression, Load testing & test automation to reduce burden.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Grid container direction="column" style={{ textAlign: 'center' }}>
                      <Grid item>
                        <Typography className={classes.servicesTitle}>
                          WebRTC for Telecoms
                        </Typography>
                      </Grid>
                      <Grid item>
                        <WebRTC className={classes.servicesIcon} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.servicesContent}>
                          WebRTC GW, Media GW, Application server and Media
                          Server bridges telecom VoIP and IMS networks with
                          an open ecosystem to deliver interoperable rich
                          multiuser multimedia communication services.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Grid container direction="column" style={{ textAlign: 'center' }}>
                      <Grid item>
                        <Typography className={classes.servicesTitle}>
                          Networking & IPSec
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IPSec className={classes.servicesIcon} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.servicesContent}>
                          Security protocol stack development like IPSec, SRTP, EAP,
                          and SSL/TLS using open source libraries and hardware
                          accelerators. Integrating crypto libraries and accelerators
                          available with OpenSSL, Strongswan, Xpressent.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Grid container direction="column" style={{ textAlign: 'center' }}>
                      <Grid item>
                        <Typography className={classes.servicesTitle}>
                          Network Function Virtualization & SDN
                        </Typography>
                      </Grid>
                      <Grid item>
                        <SDN className={classes.servicesIcon} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.servicesContent}>
                          Using hypervisor, cloud stack, open flow, Intel DPDK
                          and SR-IOV to achieve high performance Virtual Network
                          Functions (VNF) for deployed on virtual IMS, EPC
                          and backhaul networks.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Grid container direction="column" style={{ textAlign: 'center' }}>
                      <Grid item>
                        <Typography className={classes.servicesTitle}>
                          Enhancing Latest Networking / Cellular Technologies
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Networking className={classes.servicesIcon} />
                      </Grid>
                      <Grid item>
                        <Typography className={classes.servicesContent}>
                          Deep domain expertise in range of in-house POC solutions
                          to cover all requirements of LTE Silicon Vendors,
                          OEMs, Network Equipment Manufacturer & operators
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box className={classes.blackSection}>
              <Container className={classes.solutionsContainer}>
                <Grid container>
                  <Grid item xs>
                    <Typography className={classes.solutionsHeader}>
                      Advanced Testing Solutions
                    </Typography>
                  </Grid>
                  <Grid item xs>
                    <Typography className={classes.solutionsSubheader}>
                      Whether you need engineering expertise or are looking
                      to optimize your test investment, we can help you
                      achieve it efficiently.
                    </Typography>
                  </Grid>
                </Grid>
                <Typography className={[classes.solutionsContentContainer, classes.solutionsContent].join(' ')}>
                  We support interfaces needed to test mobility: S10, S3, Gn,
                  S4, S12, S101, S102, S103, Sv, IuPS, Gb, Gn, IuCS, A, A1,
                  S2a, STa, Gxa  and the entire gamut of LTE interfaces. We
                  also support features needed to effectively test mobility:
                  NACC, SRVCC, bearer continuity verification, response
                  time measurements etc.
                </Typography>
                <Box px={1}>
                  <Grid container spacing={isWidthDown('xs', width) ? 2 : 5}>
                    <Grid item xs={4}>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <img style={{ width: '100%' }} src={`${process.env.PUBLIC_URL}/images/lte-epc.png`} alt="lte-epc" />
                        </Grid>
                        <Grid item>
                          <Typography className={[classes.solutionsImgLabel, classes.solutionsContent].join(' ')}>
                            LTE EPC Testing
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <img style={{ width: '100%' }} src={`${process.env.PUBLIC_URL}/images/corporate-3.png`} alt="ims" />
                        </Grid>
                        <Grid item>
                          <Typography className={[classes.solutionsImgLabel, classes.solutionsContent].join(' ')}>
                            IMS & VoLTE Testing
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <img style={{ width: '100%' }} src={`${process.env.PUBLIC_URL}/images/core.png`} alt="core" />
                        </Grid>
                        <Grid item>
                          <Typography className={[classes.solutionsImgLabel, classes.solutionsContent].join(' ')}>
                            Core Network Testing
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <img style={{ width: '100%' }} src={`${process.env.PUBLIC_URL}/images/5g.png`} alt="5g" />
                        </Grid>
                        <Grid item>
                          <Typography className={[classes.solutionsImgLabel, classes.solutionsContent].join(' ')}>
                            5G Technology Solutions
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <img style={{ width: '100%' }} src={`${process.env.PUBLIC_URL}/images/nfv.png`} alt="nfv" />
                        </Grid>
                        <Grid item>
                          <Typography className={[classes.solutionsImgLabel, classes.solutionsContent].join(' ')}>
                            NFV & SDN Testing
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <img style={{ width: '100%' }} src={`${process.env.PUBLIC_URL}/images/network.png`} alt="network" />
                        </Grid>
                        <Grid item>
                          <Typography className={[classes.solutionsImgLabel, classes.solutionsContent].join(' ')}>
                            SIP Protocol Testing
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <img style={{ width: '100%' }} src={`${process.env.PUBLIC_URL}/images/telecom.png`} alt="telecom" />
                        </Grid>
                        <Grid item>
                          <Typography className={[classes.solutionsImgLabel, classes.solutionsContent].join(' ')}>
                            Telecom Test Automation
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <img style={{ width: '100%' }} src={`${process.env.PUBLIC_URL}/images/iups.png`} alt="iups" />
                        </Grid>
                        <Grid item>
                          <Typography className={[classes.solutionsImgLabel, classes.solutionsContent].join(' ')}>
                            IUPC & IUCS Testing
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container direction="column" alignItems="center">
                        <Grid item>
                          <img style={{ width: '100%' }} src={`${process.env.PUBLIC_URL}/images/diameter.png`} alt="diameter" />
                        </Grid>
                        <Grid item>
                          <Typography className={[classes.solutionsImgLabel, classes.solutionsContent].join(' ')}>
                            Diameter Testing
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
                {isWidthDown('sm', width) ? <Box pt={12} /> : <Box pt={20} />}
              </Container>
            </Box>
            <Container maxWidth="md">
              <Box className={classes.helpContainer}>
                <Box maxWidth={400}>
                  <Typography className={classes.pageTitle}>
                    How Can We
                    <br />
                    Help?
                  </Typography>
                </Box>
                <Box maxWidth={600}>
                  <Typography className={classes.pageSubtitle}>
                    With deep and broad domain knowledge and a commitment to success,
                    INVAS can provide fast and efficient solution to your requirements.
                    Let us know your requirement and we will reach out.
                  </Typography>
                </Box>
              </Box>
              <Form>
                <Grid container spacing={isWidthDown('xs', width) ? 1 : 4}>
                  <Grid item xs={6}>
                    <Field
                      name="name"
                      component={TextField}
                      label="Name*"
                      variant="outlined"
                      fullWidth
                      InputProps={{ style: { borderRadius: 0 } }}
                      validate={(value) => !value && 'Required'}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="company"
                      component={TextField}
                      label="Company"
                      variant="outlined"
                      fullWidth
                      InputProps={{ style: { borderRadius: 0 } }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="email"
                      component={TextField}
                      label="Email*"
                      variant="outlined"
                      fullWidth
                      InputProps={{ style: { borderRadius: 0 } }}
                      validate={
                  (value) => (!value && 'Required')
                  || (!value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/) && 'Invalid Email')
                }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="requirement"
                      component={TextField}
                      label="Requirement"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={6}
                      InputProps={{ style: { borderRadius: 0 } }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container justify="space-between" alignItems="flex-end">
                      <Grid item>
                        <Field
                          name="recaptcha"
                          component={Recaptcha}
                          validate={(value) => !value && 'Required'}
                        />
                      </Grid>
                      <Grid item xs={12} sm="auto">
                        <Box className={classes.helpSubmitContainer}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                            className={classes.helpSubmit}
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? 'Submitting' : 'Submit'}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            </Container>
            <Box mt={6} />
          </>
        )}
      </Formik>
    </>
  );
});

export default withWidth()(Services);
