import React, { useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Box,
  Button,
  withStyles,
  isWidthDown,
  withWidth,
  Hidden,
} from '@material-ui/core';
import Link from 'App/components/Link';
import { Formik, Field, Form } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import Breadcrumbs from 'App/components/Breadcrumbs';
import FileInput from 'App/components/FileInput';
import Recaptcha from 'App/components/Recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { getOffices, submitSupport } from 'App/actions';
import { serializeFile } from 'utils';
import { officeSelector } from 'slices/offices';
import MetaTags from 'react-meta-tags';
import { ReactComponent as SupportIcon } from './icons/si-glyph_customer-support.svg';
import { ReactComponent as RequestIcon } from './icons/mdi_file-send.svg';

const BREADCRUMBS = [{
  label: 'Home',
  to: '/',
}, {
  label: 'Support',
}];

const Support = withStyles((theme) => ({
  bgImg: {
    backgroundColor: theme.palette.secondary.light,
    paddingBottom: theme.spacing(25),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(10),
    },
  },
  title: {
    textTransform: 'uppercase',
    color: theme.palette.primary.contrastText,
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontSize: 60,
    fontWeight: 100,
    letterSpacing: 2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 40,
    },
  },
  description: {
    color: theme.palette.primary.contrastText,
    fontSize: 28,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
    marginTop: theme.spacing(3),
    maxWidth: 600,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.3,
  },
  particleBg: {
    position: 'absolute',
    width: 440,
    height: 410,
    top: theme.spacing(-8),
    right: 0,
    backgroundImage: `url("${process.env.PUBLIC_URL}/images/shards.png")`,
    backgroundSize: 'cover',
    zIndex: -1,
    [theme.breakpoints.down('xs')]: {
      width: 180,
      height: 167,
    },
  },
  goldCertifiedImgSumitomo: {
    width: 180,
    [theme.breakpoints.down('sm')]: {
      width: 142,
    },
  },
  goldCertifiedImgExfo: {
    width: 148,
    [theme.breakpoints.up('md')]: {
      width: 230,
      marginTop: theme.spacing(-1),
    },
  },
  supportTitle: {
    textTransform: 'uppercase',
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    color: theme.palette.primary.dark,
    letterSpacing: theme.spacing(0.3),
    marginTop: theme.spacing(1),
  },
  supportSubtitle: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 20,
    lineHeight: 1.2,
  },
  separator: {
    background: theme.palette.primary.dark,
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      width: 1,
      top: theme.spacing(5),
      bottom: theme.spacing(5),
    },
    [theme.breakpoints.down('sm')]: {
      height: 1,
      width: '100%',
      margin: `${theme.spacing(10)}px ${theme.spacing(5)}px`,
    },
  },
  contactTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  contactContent: {
    lineHeight: 1.2,
    fontSize: 14,
  },
  link: {
    color: theme.palette.primary.main,
  },
}))(({ classes, width }) => {
  const dispatch = useDispatch();
  const offices = useSelector(officeSelector);

  useEffect(() => {
    dispatch(getOffices());
  }, [dispatch]);

  return (
    <>
      <MetaTags>
        <meta name="description" content="INVAS enters into 17th year of delivering impeccable After Sales Support to our customers with the dedicated team of experts & well equipped infrastructure." />
      </MetaTags>
      <Formik
        initialValues={{
          name: '',
          company: '',
          email: '',
          contact: '',
          productType: '',
          serialNo: '',
          description: '',
          recaptcha: '',
          file: null,
          region: 0,
        }}
        onSubmit={({
          name, company, email, contact, productType, serialNo, description, file,
        }, { resetForm }) => serializeFile(file).then((fileBase64) => dispatch(submitSupport({
          name, company, email, contact, productType, serialNo, description, file: fileBase64,
        }))).then(() => resetForm())}
      >
        {({ values, isSubmitting }) => (
          <>
            <div className={classes.bgImg}>
              <Container>
                <Breadcrumbs path={BREADCRUMBS} contrast />
                <Typography className={classes.title}>Support</Typography>
                <Typography className={classes.description}>
                  INVAS enters into 17
                  <sup>th</sup>
                  {' '}
                  year of delivering impeccable After Sales Support to our customers with the
                  dedicated team of experts & well equipped infrastructure. Our award-winning
                  service centre is recognised by our OEM partners time and again. Feel free
                  to reach out to us using your preferred method of contact and our
                  professional services team will be happy to assist you further.
                </Typography>
              </Container>
            </div>
            <Box position="relative">
              <Box className={classes.particleBg} />
            </Box>
            <Container>
              <Box px={3}>
                <Grid container justify="center" alignItems="flex-end" spacing={isWidthDown('sm', width) ? 6 : 10}>
                  <Grid item>
                    <img
                      alt="Gold partner Sumitomo"
                      src={`${process.env.PUBLIC_URL}/images/gold-sumitomo.png`}
                      className={classes.goldCertifiedImgSumitomo}
                    />
                  </Grid>
                  <Grid item>
                    <img
                      alt="Gold partner EXFO"
                      src={`${process.env.PUBLIC_URL}/images/gold-exfo.png`}
                      className={classes.goldCertifiedImgExfo}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={15} />
              <Grid container justify="space-between">
                <Grid item md={5} xs={12}>
                  <center>
                    <RequestIcon />
                    <Typography variant="h5" align="center" className={classes.supportTitle}>
                      Submit Support Request
                    </Typography>
                    <br />
                    <Typography color="textSecondary" className={classes.supportSubtitle}>
                      Submit a support request and our experts will reach out to you.
                    </Typography>
                  </center>
                  <Box mt={2} />
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          name="name"
                          label="Name*"
                          variant="outlined"
                          fullWidth
                          component={TextField}
                          validate={(value) => !value && 'Required'}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="company"
                          label="Company"
                          variant="outlined"
                          fullWidth
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="contact"
                          label="Contact Number*"
                          variant="outlined"
                          fullWidth
                          component={TextField}
                          inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                          }}
                          validate={(value) => (!value && 'Required') || (!value.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) && 'Invalid Contact Number')}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="email"
                          label="Email"
                          variant="outlined"
                          fullWidth
                          component={TextField}
                          validate={(value) => value && !value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/) && 'Invalid Email'}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="productType"
                          label="Product Type"
                          variant="outlined"
                          fullWidth
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          name="serialNo"
                          label="Serial No.*"
                          variant="outlined"
                          fullWidth
                          component={TextField}
                          validate={(value) => !value && 'Required'}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="description"
                          label="Problem Description"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={4}
                          component={TextField}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="file"
                          component={FileInput}
                          validate={(value) => value && value.size > 2000000 && 'File should be less than 2 MB'}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          name="recaptcha"
                          component={Recaptcha}
                          validate={(value) => !value && 'Required'}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box textAlign="center">
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? 'Submitting' : 'Submit'}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Form>
                </Grid>
                <Hidden smDown>
                  <Box position="relative">
                    <Box className={classes.separator} />
                  </Box>
                </Hidden>
                <Hidden mdUp>
                  <Box className={classes.separator} />
                </Hidden>
                <Grid item md={5} xs={12}>
                  <center>
                    <SupportIcon />
                    <Typography variant="h5" align="center" className={classes.supportTitle}>
                      Contact Support
                    </Typography>
                    <br />
                    <Typography color="textSecondary" className={classes.supportSubtitle}>
                      Still have a question? Contact our technical experts for further assistance
                    </Typography>
                  </center>
                  <br />
                  {offices.length && (
                  <Grid container justify="center">
                    <Grid item>
                      <FormControl variant="outlined">
                        <InputLabel>Location</InputLabel>
                        <Field
                          native
                          label="Location"
                          name="region"
                          inputProps={{ style: { minWidth: 120 } }}
                          component={Select}
                        >
                          {offices.map(({ region }, i) => (
                            <option key={region} value={i}>{region}</option>
                          ))}
                        </Field>
                      </FormControl>
                    </Grid>
                  </Grid>
                  )}
                  <br />
                  <br />
                  {offices[values.region] && (
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography className={classes.contactTitle}>Address:</Typography>
                      <Typography className={classes.contactContent}>
                        {offices[values.region].name}
                      </Typography>
                      <Typography className={classes.contactContent}>
                        {offices[values.region].line1}
                      </Typography>
                      <Typography className={classes.contactContent}>
                        {offices[values.region].line2}
                      </Typography>
                      <Typography className={classes.contactContent}>
                        {offices[values.region].line3}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography className={classes.contactTitle}>Email:</Typography>
                      <Link className={classes.link} href={`mailto:support@invas.in?subject=${offices[values.region].region} Office | Enquiry`}>support@invas.in</Link>
                    </Grid>
                    {offices[values.region].phone && (
                    <Grid item>
                      <Typography className={classes.contactTitle}>Contact:</Typography>
                      <Typography className={classes.contactContent}>
                        {offices[values.region].phone}
                      </Typography>
                    </Grid>
                    )}
                    {offices[values.region].fax && (
                    <Grid item>
                      <Typography className={classes.contactTitle}>Fax:</Typography>
                      <Typography className={classes.contactContent}>
                        {offices[values.region].fax}
                      </Typography>
                    </Grid>
                    )}
                    {offices[values.region].gstn && (
                    <Grid item>
                      <Typography className={classes.contactTitle}>GST Number:</Typography>
                      <Typography className={classes.contactContent}>
                        {offices[values.region].gstn}
                      </Typography>
                    </Grid>
                    )}
                  </Grid>
                  )}
                </Grid>
              </Grid>
              <br />
              <br />
            </Container>
          </>
        )}
      </Formik>
    </>
  );
});

export default withWidth()(Support);
