import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { getAllFilters, filterProduct } from 'App/actions';
import { otdrFinderSelector, toggleOtdrFinder } from 'slices/otdrFinder';
import { isFilteringProductSelector, filterProductSelector } from 'slices/product';
import { filterSelector } from 'slices/filter';
import { Formik } from 'formik';
import PageContent from './components/PageContent';

const PAGE_CONTENT = [{
  isStart: true,
  title: 'Click start and choose the most relevant option.',
  subtitle: 'Let us find the perfect OTDR for your requirement.',
}, {
  id: 'criteria',
  title: 'Select by Technology / Application or Sector / Industry',
  subtitle: 'Select the options that are most relevant to your requirement and click next.',
  options: [
    'Sector / Industry',
    'Application / Technology',
  ],
  next: {
    'Sector / Industry': 1,
    'Application / Technology': 2,
  },
  required: true,
}, {
  id: '5f2a59966c99300359d52b33',
  title: 'Choose your Industry or Sector',
  subtitle: 'Select your industry and click next.',
  next: 2,
  required: true,
}, {
  id: '5f2a597b6c99300359d52b31',
  title: 'Choose your Technology or Application',
  subtitle: 'Select the application that is most relevant to your requirement and click next.',
  required: true,
}, {
  subtitle: 'Based on your selection, here are all the recommended products',
  isFinish: true,
}];

const INITIAL_STATE = {
  page: 0,
  isShowMore: false,
  historyPages: [],
  isFinished: false,
  selections: {},
};

const OtdrFinder = () => {
  const [page, setPage] = useState(INITIAL_STATE.page);
  const [isShowMore, setIsShowMore] = useState(INITIAL_STATE.isShowMore);
  const [historyPages, setHistoryPages] = useState(INITIAL_STATE.historyPages);
  const [isFinished, setFinished] = useState(INITIAL_STATE.isFinished);
  const [selections, setSelections] = useState(INITIAL_STATE.selections);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const theme = useTheme();
  const { isOpen } = useSelector(otdrFinderSelector);
  const dispatch = useDispatch();
  const filters = useSelector(filterSelector);
  const isFilteringProducts = useSelector(isFilteringProductSelector);
  const filteredProducts = useSelector(filterProductSelector);
  const getActiveSelections = (path) => path.map(
    (pageNum) => [PAGE_CONTENT[pageNum].id, selections[PAGE_CONTENT[pageNum].id]],
  ).filter(([, value]) => value);
  const resetState = () => {
    setPage(INITIAL_STATE.page);
    setHistoryPages(INITIAL_STATE.historyPages);
    setIsShowMore(INITIAL_STATE.isShowMore);
    setFinished(INITIAL_STATE.isFinished);
    setSelections(INITIAL_STATE.selections);
  };
  const handleNext = () => {
    if (PAGE_CONTENT[page].isStart) {
      resetState();
    }

    if (PAGE_CONTENT[page].isFinish) {
      dispatch(toggleOtdrFinder());
      return;
    }

    let nextPage = page + 1;

    if (typeof PAGE_CONTENT[page].next === 'number') {
      nextPage = page + PAGE_CONTENT[page].next;
    } else if (PAGE_CONTENT[page].next) {
      nextPage = page + PAGE_CONTENT[page].next[selections[PAGE_CONTENT[page].id]];
    }

    if (PAGE_CONTENT[nextPage].isFinish) {
      setFinished(true);
      setIsShowMore(false);
      dispatch(filterProduct(getActiveSelections(
        [...historyPages, page],
      ).reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {
        '5f2a598a6c99300359d52b32': 'OTDR',
      })));
      setTimeout(() => {
        setPage(nextPage);
        setHistoryPages([...historyPages, page]);
      }, 450);
    } else {
      setPage(nextPage);
      setHistoryPages([...historyPages, page]);
    }
  };
  const handleBack = () => {
    if (PAGE_CONTENT[page].isFinish) {
      resetState();
      return;
    }
    const prevPage = historyPages[historyPages.length - 1];
    setHistoryPages([...historyPages.slice(0, -1)]);
    setFinished(false);
    setPage(prevPage);
  };
  const gotoFilter = (filterId) => {
    const gotoIndex = PAGE_CONTENT.findIndex(({ id }) => id === filterId);
    const historyIndex = historyPages.indexOf(gotoIndex);

    setHistoryPages(historyPages.slice(0, historyIndex));
    setFinished(false);
    setPage(gotoIndex);
  };

  useEffect(() => { dispatch(getAllFilters()); }, [dispatch]);

  return (
    <Dialog
      onClose={() => dispatch(toggleOtdrFinder())}
      open={isOpen}
      fullWidth
      maxWidth="md"
      style={{ zIndex: theme.zIndex.modal + 1 }}
      PaperProps={{ style: { height: '100%' } }}
      fullScreen={isMobile}
    >
      <Formik
        initialValues={{}}
        onSubmit={() => {}}
      >
        <>
          <PageContent
            handleClose={() => dispatch(toggleOtdrFinder())}
            handleNext={handleNext}
            handleBack={handleBack}
            value={selections[PAGE_CONTENT[page].id]}
            onSelected={(value) => setSelections({ ...selections, [PAGE_CONTENT[page].id]: value })}
            content={{
              ...PAGE_CONTENT[page],
              options: PAGE_CONTENT[page].options
              || (Object.keys(
                (filters.find(({ _id }) => _id === PAGE_CONTENT[page].id) || {}).options || {},
              )),
              nextSubtitles: (
                typeof PAGE_CONTENT[page].next === 'number'
                  ? [PAGE_CONTENT[page + PAGE_CONTENT[page].next]]
                  : Object.values(PAGE_CONTENT[page].next || {})
                    .map((jump) => (PAGE_CONTENT[page + jump] || {}))
              )
                .filter(({ isFinish }) => !isFinish)
                .map(({ title }) => title),
              prevSubtitles: !PAGE_CONTENT[page].isFinish && historyPages.length
                && !PAGE_CONTENT[historyPages[historyPages.length - 1]].isStart
                && PAGE_CONTENT[historyPages[historyPages.length - 1]].title,
              progress: isFinished ? 100 : ((page - 1) / (PAGE_CONTENT.length - 2)) * 100,
            }}
            selections={getActiveSelections(historyPages)
              .map(([key, value]) => ({ label: value, handleClick: () => gotoFilter(key) }))}
            isFilteringProducts={isFilteringProducts}
            filterProducts={filteredProducts}
            isShowMore={isShowMore}
            handleIsShowMoreClick={() => setIsShowMore(true)}
          />
        </>
      </Formik>
    </Dialog>
  );
};

export default OtdrFinder;
