import React from 'react';
import { shape } from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Markdown from 'App/components/Markdown';
import { base64Decode } from 'utils';

const useStyles = makeStyles((theme) => ({
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
}));

export default function Main(props) {
  const classes = useStyles();
  const { post } = props;

  return (
    <Grid item xs={12} md={8}>
      <Markdown className={classes.markdown}>{post && base64Decode(post.content)}</Markdown>
      <Divider />
    </Grid>
  );
}

Main.propTypes = {
  post: shape(),
};
Main.defaultProps = {
  post: null,
};
