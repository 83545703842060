import React from 'react';
import { node, string, func } from 'prop-types';
import { Link as MuiLink, withStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const Link = withStyles(() => ({
  root: {
    color: 'inherit',
    cursor: 'pointer',
  },
}))(({
  to,
  href,
  onClick,
  classes,
  className,
  children,
}) => (
  <MuiLink
    href={href}
    to={to}
    component={to ? RouterLink : 'a'}
    className={className}
    onClick={onClick}
    classes={classes}
  >
    {children}
  </MuiLink>
));

Link.propTypes = {
  to: string,
  href: string,
  onClick: func,
  className: string,
  children: node.isRequired,
};

Link.defaultProps = {
  to: null,
  href: null,
  onClick: null,
  className: '',
};

export default Link;
