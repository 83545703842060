/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Container,
  Avatar,
  Button,
  Grid,
  Box,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { LockOutlined } from '@material-ui/icons';
import Link from 'App/components/Link';
import { Formik, Form, Field } from 'formik';
import { TextField, CheckboxWithLabel } from 'formik-material-ui';
import { useHistory, useLocation } from 'react-router-dom';
import { handleLoginSubmit } from 'App/actions';

const Login = withStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    color: theme.palette.primary.main,
  },
}))(({ classes }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const onSubmit = (values, formikBag) => dispatch(
    handleLoginSubmit(values, formikBag, location, history),
  );

  return (
    <Formik
      initialValues={{ email: '', password: '', remember: false }}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting }) => (
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Form className={classes.form}>
              <Field
                component={TextField}
                variant="outlined"
                margin="normal"
                fullWidth
                label="Email Address"
                name="email"
                validate={(value) => {
                  if (!value) return 'Required';
                  if (!value.match(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/)) return 'Invalid email';

                  return null;
                }}
                autoFocus
              />
              <Field
                component={TextField}
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type="password"
                validate={(value) => !value && 'Required'}
              />
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="remember"
                color="primary"
                Label={{ label: 'Remember me' }}
              />
              {status && <Alert severity="error">{status}</Alert>}
              <Button
                disabled={isSubmitting}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link className={classes.link}>
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Form>
          </div>
          <Box mt={8}>
            <Typography variant="body2" color="textSecondary" align="center">
              &copy; INVAS TECHNOLOGIES PVT. LTD.
            </Typography>
          </Box>
        </Container>
      )}
    </Formik>
  );
});

export default Login;
