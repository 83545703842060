import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const slice = createSlice({
  name: 'offices',
  initialState,
  reducers: {
    setOffices: (state, { payload }) => payload,
  },
});

export const officeSelector = ({ offices }) => offices;

export const {
  setOffices,
} = slice.actions;

export default slice.reducer;
