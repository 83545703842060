/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
  Container,
  Typography,
  Grid,
  Box,
  Button,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  LinearProgress,
  withStyles,
  Chip,
  CircularProgress,
  Hidden,
} from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import ProductCard from 'App/components/ProductCard';
import Link from 'App/components/Link';
import {
  Close,
  ChevronLeft,
  ChevronRight,
  Replay,
  ExitToApp,
} from '@material-ui/icons';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { useDispatch } from 'react-redux';
import { shortlistProduct } from 'App/actions';
import { ReactComponent as ShoppingCartAdd } from 'App/components/Shortlist/icons/cart-add.svg';

const PageContent = withStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  logo: {
    height: 50,
    [theme.breakpoints.down('xs')]: {
      height: 32,
    },
  },
  otdrText: {
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 50,
    lineHeight: 1,
    color: theme.palette.secondary.dark,
    letterSpacing: -4,
    [theme.breakpoints.down('xs')]: {
      letterSpacing: -2,
      fontSize: 32,
    },
  },
  finderText: {
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 50,
    lineHeight: 1.09,
    background: `linear-gradient(to right, ${theme.palette.secondary.dark} 10%, ${theme.palette.primary.light} 90%)`,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    letterSpacing: -1,
    [theme.breakpoints.down('xs')]: {
      fontSize: 32,
    },
  },
  container: {
    marginTop: theme.spacing(14),
    marginBottom: theme.spacing(5),
  },
  contentContainer: {
    marginTop: theme.spacing(15),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
    },
  },
  startContainer: {
    textAlign: 'center',
    marginTop: theme.spacing(15),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(25),
    },
  },
  title: {
    fontSize: 28,
    lineHeight: 1.1,
    color: theme.palette.secondary.dark,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: 24,
    },
  },
  subtitle: {
    fontSize: 20,
    lineHeight: 1.1,
    color: theme.palette.secondary.dark,
    fontWeight: theme.typography.fontWeightLight,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  startButton: {
    width: 220,
    fontSize: 24,
    color: theme.palette.secondary.dark,
    border: `1px solid ${theme.palette.secondary.dark}`,
  },
  optionsContainer: {
    width: '100%',
    minHeight: 130,
  },
  option: {
    height: '100%',
    width: '100%',
    color: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.light}`,
    '&.MuiToggleButton-root.Mui-selected': {
      color: theme.palette.primary.light,
      background: `${theme.palette.primary.light}40`,
    },
  },
  progressBar: {
    height: theme.spacing(1),
    borderRadius: theme.spacing(1),
    '& .MuiLinearProgress-bar': {
      background: `linear-gradient(to right, ${theme.palette.primary.main}, #47a5e3)`,
      borderRadius: theme.spacing(1),
    },
  },
  actionsContainer: {
    minHeight: ({ content: { isFinish } }) => (!isFinish ? 84 : 0),
    [theme.breakpoints.down('xs')]: {
      minHeight: ({ content: { isFinish } }) => (!isFinish ? 54 : 0),
    },
  },
  chip: {
    background: `linear-gradient(to right, ${theme.palette.primary.main}, #47a5e3)`,
  },
  loadMore: {
    fontSize: 16,
    fontStyle: 'italic',
    color: theme.palette.primary.main,
  },
}))(({
  classes,
  handleClose,
  content: {
    title,
    subtitle,
    options,
    nextSubtitles,
    prevSubtitles,
    progress,
    isStart,
    isFinish,
    required,
  },
  handleNext,
  handleBack,
  value,
  onSelected,
  selections,
  isFilteringProducts,
  filterProducts,
  isShowMore,
  handleIsShowMoreClick,
  width,
}) => {
  let nextLabel = isFinish ? 'Exit' : 'Next';
  const dispatch = useDispatch();
  const displayProducts = filterProducts.slice(...(isShowMore ? [0] : [0, 3]));

  if (!isFinish && !required && !value) {
    nextLabel = 'Skip';
  }

  return (
    <>
      <DialogTitle>
        <IconButton className={classes.closeButton} onClick={handleClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Box m={2}>
        <Container>
          <Grid container justify="space-between">
            <Grid item>
              <Grid container wrap="nowrap">
                <Grid item>
                  <Typography className={classes.otdrText}>OTDR</Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.finderText}>Finder</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <img className={classes.logo} src={`${process.env.PUBLIC_URL}/images/compact-logo.png`} alt="INVAS" />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <DialogContent>
        <Container disableGutters={isWidthDown('xs', width)}>
          <Box className={classes.contentContainer}>
            <Container maxWidth="sm">
              <Typography className={classes.title}>
                {title}
              </Typography>
              <Typography className={classes.subtitle}>
                {subtitle}
              </Typography>

              {isFinish && (
                <Box mt={2}>
                  <Grid container spacing={1}>
                    {selections
                      .map(({ label, handleClick }) => (
                        <Grid item key={label}>
                          <Chip className={classes.chip} color="primary" label={label} onClick={handleClick} />
                        </Grid>
                      ))}
                  </Grid>
                </Box>
              )}
            </Container>
          </Box>
          {isStart && (
            <Box className={classes.startContainer}>
              <Button size="large" variant="outlined" onClick={handleNext} className={classes.startButton}>Start</Button>
            </Box>
          )}
          {isFinish && (
            <Box mt={2}>
              {isFilteringProducts ? <Box textAlign="center" mt={12}><CircularProgress /></Box> : (
                <>
                  {filterProducts.length > 0 ? (
                    <>
                      <Grid container spacing={1} justify="center">
                        {displayProducts.map((product) => (
                          <Grid key={product.id} item xs={10} sm={6} md={4}>
                            <ProductCard data={product} onClick={handleNext} />
                          </Grid>
                        ))}
                      </Grid>
                      {!isShowMore
                      && filterProducts.length > 3
                      && (
                      <Box mt={4} textAlign="center">
                        <Link onClick={handleIsShowMoreClick} className={classes.loadMore}>
                          Load More
                        </Link>
                      </Box>
                      )}
                    </>
                  ) : <Box width="100%" textAlign="center" mt={12}><Typography variant="h5" color="textSecondary">No Products Found</Typography></Box>}
                </>
              )}
            </Box>
          )}
          {!isStart && !isFinish && (
            <Box mt={10}>
              <Grid container spacing={2} justify="center" className={classes.optionsContainer}>
                {options.map((option) => (
                  <Grid item key={option} md={3} xs={6}>
                    <ToggleButton
                      value={option}
                      className={classes.option}
                      selected={option === value}
                      onChange={(e, newSelection) => onSelected(newSelection)}
                    >
                      {option}
                    </ToggleButton>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Container>
      </DialogContent>
      {!isStart && (
      <DialogActions>
        <Container>
          <Grid container justify="space-between" className={classes.actionsContainer}>
            <Grid item xs>
              <Button
                size={isWidthDown('xs', width) ? 'medium' : 'large'}
                color="primary"
                onClick={handleBack}
                startIcon={isFinish ? <Replay /> : <ChevronLeft />}
              >
                {isFinish ? 'Start Again' : 'Back'}
              </Button>
              <Hidden xsDown>
                <Typography variant="subtitle2" color="textSecondary">{prevSubtitles}</Typography>
              </Hidden>
            </Grid>
            {isFinish && (
              <Grid item xs style={{ textAlign: 'center' }}>
                <Button
                  size={isWidthDown('xs', width) ? 'medium' : 'large'}
                  color="primary"
                  onClick={() => { dispatch(shortlistProduct(displayProducts)); handleClose(); }}
                  startIcon={<ShoppingCartAdd fill="currentColor" />}
                  disabled={!filterProducts.length}
                >
                  Add all to Cart
                </Button>
              </Grid>
            )}
            <Grid item xs={isWidthDown('xs', width) && isFinish ? 2 : true} style={{ textAlign: 'right' }}>
              {!(isWidthDown('xs', width) && isFinish) && (
                <Button
                  size={isWidthDown('xs', width) ? 'medium' : 'large'}
                  color="primary"
                  onClick={handleNext}
                  endIcon={isFinish ? <ExitToApp /> : <ChevronRight />}
                  disabled={!isFinish && required && !value}
                >
                  {nextLabel}
                </Button>
              )}
              <Hidden xsDown>
                {nextSubtitles.map((nextSubtitle) => <Typography key={nextSubtitle} variant="subtitle2" color="textSecondary">{nextSubtitle}</Typography>)}
              </Hidden>
            </Grid>
          </Grid>
          {!isFinish && (
          <Box mt={4} mb={2}>
            <Grid container>
              <Grid item xs={2} />
              <Grid item xs={8}>
                <LinearProgress variant="determinate" value={progress} className={classes.progressBar} />
              </Grid>
              <Grid item xs={2} />
            </Grid>
          </Box>
          )}
        </Container>
      </DialogActions>
      )}
    </>
  );
});

export default withWidth()(PageContent);
