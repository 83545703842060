import React from 'react';
import { bool } from 'prop-types';
import { withStyles } from '@material-ui/core';

const MenuIcon = withStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
  },
}))(({ isOpen, classes }) => (
  <span className={`animated-icon${isOpen ? ' anim' : ''}`}>
    <span className={`ani ${classes.root}`} />
  </span>
));

MenuIcon.propTypes = {
  isOpen: bool.isRequired,
};

export default MenuIcon;
