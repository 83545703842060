import React from 'react';
import {
  Grid,
  Typography,
  Divider,
  Hidden,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActionArea,
  CardActions,
  Button,
  withStyles,
  Box,
} from '@material-ui/core';
import { Share } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { getTimestamp } from 'utils';

const NewsCard = withStyles((theme) => ({
  container: {
    textAlign: 'left',
  },
  title: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
  },
  content: {
    fontSize: 14,
    lineHeight: 1,
    fontWeight: theme.typography.fontWeightLight,
  },
  date: {
    fontSize: 14,
    color: theme.palette.secondary.main,
  },
  media: {
    height: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: ({ media }) => `url(${media})`,
    [theme.breakpoints.up('md')]: {
      height: 0,
      paddingTop: '56.25%', // 16:9
      backgroundSize: 'cover',
      backgroundPosition: 'top',
    },
  },
}))(({
  id,
  title,
  content,
  media,
  classes,
}) => {
  const history = useHistory();
  const handleClick = () => history.push(`/news/${id}`);
  const date = getTimestamp(id);

  return (
    <>
      <Hidden mdUp>
        <Grid container spacing={1} onClick={handleClick}>
          <Grid item xs={12}>
            <Typography className={classes.title}>{title}</Typography>
          </Grid>
          <Grid item container xs={6} spacing={2}>
            <Grid item>
              <Typography className={classes.content}>
                {content}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.date}>
                {date}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.media} />
          </Grid>
        </Grid>
        <br />
        <Divider />
      </Hidden>
      <Hidden smDown>
        <Card style={{ height: '100%', position: 'relative', paddingBottom: 52 }}>
          <CardActionArea onClick={handleClick}>
            <CardHeader
              title={title}
              subheader={date}
              titleTypographyProps={{
                style: {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                },
              }}
              subheaderTypographyProps={{ color: 'secondary' }}
            />
            <CardMedia
              className={classes.media}
              image={media}
              title="News"
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary">
                {content}
              </Typography>
            </CardContent>
          </CardActionArea>
          <Box position="absolute" bottom={0}>
            <CardActions disableSpacing>
              <Button
                color="primary"
                startIcon={<Share />}
              >
                Share
              </Button>
            </CardActions>
          </Box>
        </Card>
      </Hidden>
    </>
  );
});

export default NewsCard;
