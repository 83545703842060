import React from 'react';
import { useDispatch } from 'react-redux';
import {
  Container,
  Typography,
  Button,
} from '@material-ui/core';
import { logout } from 'App/actions';

const Dashboard = () => {
  const dispatch = useDispatch();
  return (
    <Container style={{ height: '100vh' }}>
      <br />
      <br />
      <br />
      <Typography variant="h5">
        This is your secure dashboard.
      </Typography>
      <br />
      <Button variant="contained" color="secondary" onClick={() => dispatch(logout())}>Logout</Button>
    </Container>
  );
};

export default Dashboard;
