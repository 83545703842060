import React from 'react';
import {
  Popover,
  IconButton,
} from '@material-ui/core';
import {
  Facebook,
  LinkedIn,
  Email,
  WhatsApp,
} from '@material-ui/icons';
import { func, shape, string } from 'prop-types';
import { getProductUrl } from 'utils';

const SharePopover = ({ anchorEl, handleClose, product: { id, name } }) => (
  <Popover
    open={!!anchorEl}
    anchorEl={anchorEl}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
  >
    <IconButton color="primary" onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${getProductUrl(id)}`)}><Facebook /></IconButton>
    <IconButton color="primary" onClick={() => window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${getProductUrl(id)}`)}><LinkedIn /></IconButton>
    <IconButton color="primary" onClick={() => window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(getProductUrl(id))}`)}><WhatsApp /></IconButton>
    <IconButton
      color="primary"
      onClick={() => {
        window.location.href = `mailto:?subject=INVAS | ${name}&body=${encodeURIComponent(getProductUrl(id))}`;
      }}
    >
      <Email />
    </IconButton>
  </Popover>
);

SharePopover.propTypes = {
  anchorEl: shape(),
  handleClose: func.isRequired,
  product: shape({
    id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
};

SharePopover.defaultProps = {
  anchorEl: null,
};

export default SharePopover;
