import React from 'react';
import {
  Container,
  Box,
  Grid,
  Typography,
  withStyles,
  Paper,
  isWidthDown,
  withWidth,
} from '@material-ui/core';
import MetaTags from 'react-meta-tags';
import { ReactComponent as QuoteIcon } from '../icons/quote.svg';
import { ReactComponent as RelationshipIcon } from '../icons/relationship.svg';
import { ReactComponent as InnovationIcon } from '../icons/innovation.svg';
import { ReactComponent as WorldIcon } from '../icons/world.svg';
import { ReactComponent as ImprovementIcon } from '../icons/improvement.svg';
import { ReactComponent as HomeIcon } from '../icons/home.svg';
import { ReactComponent as InvestIcon } from '../icons/invest.svg';

const WhoWeAre = withStyles((theme) => ({
  xShardsOverlay: {
    position: 'absolute',
    right: 0,
    top: theme.spacing(-20),
    width: '100vw',
    zIndex: -1,
    opacity: 0.4,
    [theme.breakpoints.down('sm')]: {
      width: '150vw',
    },
  },
  title: {
    color: '#47a5e3',
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontWeight: 100,
    fontSize: 72,
    lineHeight: 0.9,
    [theme.breakpoints.down('sm')]: {
      fontSize: 46,
      marginBottom: theme.spacing(6),
    },
  },
  titleUnderline: {
    height: 2,
    width: theme.spacing(12),
    background: `linear-gradient(to right, #47a5e3, ${theme.palette.primary.main})`,
    borderRadius: 2,
    float: 'right',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 176,
      width: 60,
      marginBottom: theme.spacing(2),
    },
  },
  content: {
    fontSize: 24,
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: 1.4,
    color: theme.palette.text.secondary,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  visionContainer: {
    position: 'relative',
    backgroundImage: `linear-gradient(to top right, #47a5e3, ${theme.palette.secondary.dark})`,
    width: '70%',
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightLight,
    paddingLeft: 'calc((100vw - 1234px) / 2)',
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    borderRadius: 0,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingRight: '15%',
    [theme.breakpoints.down('sm')]: {
      width: '95%',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
  },
  visionTitle: {
    fontSize: 62,
    fontWeight: theme.typography.fontWeightLight,
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      fontSize: 34,
    },
  },
  visionContent: {
    fontFamily: 'Helvetica Neue,Arial,sans-serif',
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 26,
    lineHeight: 1.3,
    letterSpacing: '0.15px',
    marginTop: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  quoteIcon: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(4),
    height: 80,
    width: 106,
    [theme.breakpoints.down('sm')]: {
      height: 50,
      width: 62,
    },
  },
  shardsOverlayContainer: {
    position: 'absolute',
    bottom: -40,
    width: '100%',
    overflow: 'hidden',
    zIndex: -1,
  },
  shardsOverlay: {
    width: '100vw',
    opacity: 0.4,
    transform: 'scaleX(-1)',
    [theme.breakpoints.down('sm')]: {
      width: '150vw',
    },
  },
  blueSection: {
    background: theme.palette.secondary.dark,
    color: theme.palette.common.white,
    paddingTop: theme.spacing(5),
  },
  valuesTitle: {
    textTransform: 'uppercase',
    fontSize: 26,
    letterSpacing: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  valuesContainer: {
    padding: `${theme.spacing(15)}px ${theme.spacing(3)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: `${theme.spacing(8)}px ${theme.spacing(3)}px`,
    },
  },
  valuesContent: {
    fontSize: 26,
    fontWeight: theme.typography.fontWeightLight,
    textAlign: 'center',
    display: 'inline-block',
    maxWidth: theme.spacing(50),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
}))(({ classes, width }) => (
  <>
    <MetaTags>
      <meta name="description" content="INVAS Technologies is certified partner of EXFO, Sumitomo Electric, Oscilloquartz, Keysight Technologies, iBwave & Infovista & deals in fiber optics tools." />
    </MetaTags>
    <Box position="relative">
      <img src={`${process.env.PUBLIC_URL}/images/xShardsBg.png`} className={classes.xShardsOverlay} alt="Shards" />
    </Box>
    <Container>
      <Grid container>
        <Grid item md={5} xs="auto">
          <Grid container direction="column" style={{ maxWidth: 334 }}>
            <Grid item style={{ overflow: 'hidden' }}>
              <Box className={classes.titleUnderline} data-aos="fade-left" />
            </Grid>
            <Grid item>
              <Typography className={classes.title}>
                About The
                <br />
                Company
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md xs={12}>
          <Typography className={classes.content}>
            INVAS Technologies (P) Ltd, incorporated in the year 2006 with the main
            objective of carrying-out the business of providing Telecom Test Solutions
            and value-added services in India, Nepal, Bangladesh and Sri Lanka.
            <br />
            <br />
            We are a team of technical experts with a long and successful track
            record in the technologies used for fixed and mobile networks.
            We have provided our customers with advanced telecommunications products
            and services that are highly reliable, value for the money and supported
            during and beyond the warranty period. Our customers count on our solutions
            to accelerate digital transformation to meet the growing market challenges.
            The supplied products are engineered for customers to meet the environmental
            conditions and comply with the international standards.
          </Typography>
        </Grid>
      </Grid>
    </Container>
    <Paper elevation={10} className={classes.visionContainer}>
      <Box pl={isWidthDown('sm', width) ? 4 : 10}>
        <Typography className={classes.visionTitle}>Our Vision</Typography>
        <Typography className={classes.visionContent}>
          To be an important contributor in the  advanced telecommunication test
          solutions domain that has proven customer success stories and be the
          preferred strategic partner for the System Integrators, Service providers and NEMs
        </Typography>
      </Box>
      <QuoteIcon className={classes.quoteIcon} />
    </Paper>
    <Container>
      <Grid container>
        <Grid item xs={5} />
        <Grid item md={7} xs={12}>
          <Typography className={classes.content}>
            INVAS establishes Telecommunication Solutions that not only carry information
            but also the business of its clients. With a proven track record of customer
            satisfaction, we have now established Regional Support Offices in various parts
            of India. The Headquarter at New Delhi, India is housed with its highly talented
            and skilled workforce including Managers, Support Staff, Engineering Expertise,
            Administrative and Support Services that provides as a focal point for all Sales
            and Support activities for all its projects in India. INVAS also has regional
            offices across India to provide local assistance to it’s clients.
          </Typography>
        </Grid>
      </Grid>
      <Box pb={4} />
    </Container>
    <Box position="relative">
      <Box className={classes.shardsOverlayContainer}>
        <img src={`${process.env.PUBLIC_URL}/images/shardsBg.png`} className={classes.shardsOverlay} alt="Shards" />
      </Box>
    </Box>
    <Box className={classes.blueSection}>
      <Container>
        <Typography align="center" className={classes.valuesTitle}>Our Core Values</Typography>
        <Box className={classes.valuesContainer}>
          <Grid container spacing={10}>
            <Grid item sm={6} xs={12}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <RelationshipIcon />
                </Grid>
                <Grid item>
                  <Typography className={classes.valuesContent}>
                    Nurture long term relationship with our customers
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <InnovationIcon />
                </Grid>
                <Grid item>
                  <Typography className={classes.valuesContent}>
                    Deliver innovative services and products that
                    will contribute to our customers success
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <WorldIcon />
                </Grid>
                <Grid item>
                  <Typography className={classes.valuesContent}>
                    World class Service Culture being predominantly a service based organization
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <ImprovementIcon />
                </Grid>
                <Grid item>
                  <Typography className={classes.valuesContent}>
                    Standardized Quality Process and strive for continuous process improvement
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <HomeIcon />
                </Grid>
                <Grid item>
                  <Typography className={classes.valuesContent}>
                    Foster a culture of creativity, learning, caring,
                    belonging and will be a fun place to work
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <InvestIcon />
                </Grid>
                <Grid item>
                  <Typography className={classes.valuesContent}>
                    Invest in our people encourage growth and will
                    be an Equal Opportunity Employer (EOE)
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  </>
));

export default withWidth()(WhoWeAre);
