/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Grid,
  Input,
  withStyles,
  CircularProgress,
  Typography,
  Box,
  useMediaQuery,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import { Search } from '@material-ui/icons';
import { fieldToInputBase } from 'formik-material-ui';
import { Autocomplete } from '@material-ui/lab';
import { useSelector, useDispatch } from 'react-redux';
import { isSearchingProductSelector, searchProductSelector } from 'slices/product';
import { searchProduct } from 'App/actions';

const SearchBar = withStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  searchIcon: {
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 2),
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiSvgIcon-root': {
        height: '0.8em',
        width: '0.8em',
      },
    },
  },
  inputBase: {
    color: 'inherit',
    '&.MuiInput-underline::before, &.MuiInput-underline::after': {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    '& .MuiAutocomplete-endAdornment .MuiIconButton-root': {
      color: 'inherit',
    },
  },
  input: {
    padding: theme.spacing(1, 1, 1, 0),
    width: '100%',
    paddingLeft: `calc(1em + ${theme.spacing(1.8)}px)`,
    fontSize: 13,
    [theme.breakpoints.up('md')]: {
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      fontSize: 16,
    },
  },
  optionLabel: {
    lineHeight: 1.2,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  optionImg: {
    width: 40,
    verticalAlign: 'middle',
  },
}))(({
  classes,
  handleSelect,
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(isSearchingProductSelector);
  const options = useSelector(searchProductSelector);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Autocomplete
      freeSolo
      clearOnEscape
      autoHighlight
      loading={loading}
      options={loading ? [] : options}
      filterOptions={(o) => o}
      loadingText="Searching..."
      getOptionLabel={({ name }) => name || ''}
      onInputChange={(e, value) => dispatch(searchProduct(value))}
      onChange={(e, value, reason) => reason === 'select-option' && handleSelect(value)}
      renderOption={({ name, images }) => (
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <img alt={name} src={images[0]} className={classes.optionImg} />
          </Grid>
          <Grid item xs><Typography className={classes.optionLabel}>{name}</Typography></Grid>
        </Grid>
      )}
      renderInput={(params) => (
        <Box className={classes.search}>
          <Box className={classes.searchIcon}>
            <Search />
          </Box>
          <Input
            ref={params.InputProps.ref}
            fullWidth
            placeholder="Search Products"
            className={classes.inputBase}
            inputProps={{
              ...params.inputProps,
              className: classes.input,
            }}
            endAdornment={
              loading
                ? <Box mt={0.5} mr={0.5} position="absolute" right={0}><CircularProgress color="inherit" size={isMobile ? 16 : 20} /></Box>
                : params.InputProps.endAdornment
            }
            {...fieldToInputBase(otherProps)}
          />
        </Box>
      )}
    />
  );
});

export default SearchBar;
